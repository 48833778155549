import React from 'react';
import TitleBar from "../components/titleBar";
import Profile from "../components/profile";
import BottomNav from "../components/bottomNav";
import { FullContainer, InnerContainer } from "../components/styled/containers"
import { useAuthSession } from '../components/supastore';

const MainEntry = () => {
  // user_id should come from auth provider
  const {user_id} = useAuthSession();

  return <FullContainer>
    <TitleBar />
    <InnerContainer>
      <Profile userId={user_id} />
    </InnerContainer>
    <BottomNav selectedIndex={2} />
  </FullContainer>
}

export default MainEntry