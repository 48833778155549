import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import InfiniteImageList from "../styled/infiniteImageList";
import { useAuthSession, getSessionProxy, getBaseUrl, getUserFeedStore, getUserFeed, getUserProfile } from "../supastore"
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { useSnapshot } from 'valtio';
import Paper from '@mui/material/Paper';


const settings_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user`,
    headers: {
        // 'apikey': process.env.REACT_APP_SUPABASE_ANON_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

const ProfilePictureDrawer = ({ open = false, onClose = null, anchor = "bottom" }) => {
    const { user_id, auth } = useAuthSession();
    const usersession = getSessionProxy()

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const { enqueueSnackbar } = useSnackbar();


    const handleSubmit = () => {
        const update = { avatar_url: selectedPhoto.url }
        const payload = { update, access_token: auth.access_token }

        settings_api.post('/profile', payload)
            .then((data) => {
                const no_cache = true
                console.log("Avatar update res: ", data)
                return getUserProfile(user_id, no_cache);
            }).then((new_profile) => {
                console.log("Avatar update prof ", new_profile)
                usersession.profile = new_profile
                enqueueSnackbar(`Update successful!`, { variant: 'success' });
                onClose();
            })
            .catch((error) => {
                console.log("Avatar error:", error);
                enqueueSnackbar(`Avatar update failed, try again`, { variant: 'error' });
            })
    }

    const feedStore = getUserFeedStore('public_' + user_id)
    const { images } = useSnapshot(feedStore)

    const [hasMore, setHasMore] = useState(true);

    const fetchMore = async () => {
        const fetchSize = 36
        const min_range = images.length
        const max_range = min_range + fetchSize
        const { data, error } = await getUserFeed({ user_id, min_range, max_range })
        if (error) {
            console.log(error)
        }
        if (data.length == 0 || data.length % fetchSize !== 0) {
            // ignore for now
            setHasMore(false)
        }

        const img_promises = _.map(data, (d) => {
            const { id: user_id, session_id, image_id, ...img_props } = d
            const img_format = 'image.jpg'
            return {
                src: `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/${img_format}`,
                user_id,
                session_id, image_id,
                ...img_props,
            }
        })

        let img_data = await Promise.all(img_promises)
        console.log("Setting pub images: ", img_data)
        feedStore.images = [...images, ...img_data]
    };

    return (
        <Drawer anchor={anchor} onClose={onClose} open={open} sx={{ overflow: "contain" }}>
            <Paper sx={{ position: 'static', width: "100%", px: 2 }} elevation={3}>
                <Grid container sx={{ py: 2, alignItems: "center", justifyContent: "center" }}>
                    <Grid item xs={2}>
                        <Button size='small' sx={{textTransform: "none"}} variant="contained" disabled={selectedPhoto == null} onClick={() => handleSubmit()}>Save</Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h6" textAlign="center">Choose a pic</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton variant='contained' onClick={onClose} sx={{ float: "right" }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                </Paper>
            <Box id="profileSelectBox"
                sx={{
                width: "100%",
                p: 1,
                overflowY: "scroll",
                height: "100vh",
            }}>
                <InfiniteImageList
                    images={images}
                    scrollableTarget="profileSelectBox"
                    imageData={feedStore.images}
                    onImageClick={(im_data) => { console.log(im_data.data.image_id); setSelectedPhoto(im_data); }}
                    fetchMore={fetchMore}
                    hasMore={hasMore}
                    showActions={false}
                    showUsers={false}
                    isActive={open}
                    compact={true}
                    showSelection={true}
                    selectedImageId={selectedPhoto ? selectedPhoto.data.image_id : null}
                />
            </Box>
        </Drawer>
    )
}

export default ProfilePictureDrawer;