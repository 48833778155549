import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ExpositionText, AccentExpositionText, UploadBox } from "../create/views_new/styled"
import { useNavigate, Navigate } from 'react-router-dom';
import OnboardingLayout from '../create/views_new/layout';
import { useSnackbar } from 'notistack';
import { getKeyedStore, getBaseUrl, useAuthSession } from '../../supastore';
import { useSnapshot } from "valtio"

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const session_api = axios.create({
    baseURL: `${getBaseUrl()}/api/dream`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// TODO: Move success to /dream/complete -- and call our backend at /api/dream/${session_id}/verify
// That will return {email_status: "verified", user_id: "{uuid}"} if the session is verified, and {email_status: "unverified"} if not
// and it will return {payment_status: "complete"} if the payment is completed, and {payment_status: "incomplete", checkout_url: "stripe url"} if not
// finally, success will display two buttons if not verified and incomplete, one for each call to fix it 
// (verify email via magic link), (complete payment via stripe redirect to same checkout url)
// and potentially 1 button for contacting support for common issues
// if everything is verified, success has a button, that button starts the training process by making a post call
// to /api/dream/${session_id}/${user_id}/train -- this will initiate the model training process. 

const wrapFooter = ({ enqueueSnackbar, navigate, disabled }) => {
    const getFooter = () => {
        return <Button
            sx={{ width: "90vw", maxWidth: "1000px", textTransform: "none" }}
            variant="contained"
            size="large"
            disabled={disabled}
            onClick={() => {
                enqueueSnackbar("Let's go! Make sure you verify your email to get cheesin'!", { variant: "success" });
                navigate("/");
            }}
        >
            TO THE FEED!
        </Button>
    }

    return getFooter;
}

const Success = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const getFooter = wrapFooter({ enqueueSnackbar, navigate });

    return (
        <OnboardingLayout footerComponent={getFooter()} title="SUCCESS!!">
            <ExpositionText>
                Now we mature your Cheese!
            </ExpositionText>
            <ExpositionText>
                This normally takes between 60-90 minutes.
            </ExpositionText>
            <ExpositionText>
                You'll get an email when your Cheese is ready and it's time to get at it!
            </ExpositionText>
            <ExpositionText>
                Meanwhile, Cheese the feed and get ready
            </ExpositionText>
            <ExpositionText>
                ...
            </ExpositionText>
            <Box sx={{ width: "100%", height: "auto", overflow: "hidden" }}>
                <img src="/cheese_social_preview_tall.jpg" alt="preview"
                    style={{ width: "100%", objectFit: "fill" }} />
            </Box>
        </OnboardingLayout>
    );
}

const VerifyCallToAction = ({ userVerifiedState }) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { verified, checkingVerify, email_state, payment_state, session_id } = useSnapshot(userVerifiedState);
    const getFooter = wrapFooter({ enqueueSnackbar, navigate, disabled: !verified });


    const { verified: email_verified } = email_state;
    const { verified: payment_verified, purchase_missing, checkout_url } = payment_state;

    React.useEffect(() => {
        // check verified status plz

    }, [])


    if (purchase_missing) {
        // how did you even get here? go back to /dream/create
        navigate(`/dream/create/${session_id}`);
    }
    const titleText = "Almost there!"

    // if (checkingVerify) {
    //     return <OnboardingLayout footerComponent={getFooter()} title={titleText}>
    //         <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>

    //         </Box>
    //     </OnboardingLayout>
    // }

    const EmailCTA = () => {
        return <Button
            sx={{ width: "90%", maxWidth: "1000px", textTransform: "none", m: 1.5 }}
            variant="contained"
            size="large"
            onClick={() => {
                // let's call the backend to send us a magic email
                session_api.get(`/${session_id}/verify?type=email`).then(({ data }) => {
                    enqueueSnackbar("Email sent your way, follow to verify and finish", { variant: "info" });
                })
                // enqueueSnackbar("Email verification sent!", { variant: "success" });
                // navigate("/");
            }}
        >
            VERIFY EMAIL
        </Button>
    }
    const PaymentCTA = ({ checkoutUrl }) => {
        return <Button
            sx={{ width: "90%", maxWidth: "1000px", textTransform: "none", m: 1.5 }}
            variant="contained"
            size="large"
            onClick={() => {
                window.location.href = checkoutUrl
                // enqueueSnackbar("Nothing sent!", { variant: "info" });
                // enqueueSnackbar("Payment verification sent!", { variant: "success" });
                // navigate("/");
            }}
        >
            FINISH CHECKOUT
        </Button>
    }


    return (
        <OnboardingLayout footerComponent={getFooter()} title={titleText}>
            {checkingVerify && <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                <ExpositionText sx={{ display: "flex", alignItems: "center", m: 1 }}>
                    <CircularProgress sx={{ m: 2 }} />
                    We're verifying your email and payment status...
                </ExpositionText></Box>
            }
            {!checkingVerify && !email_verified &&
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                    <ExpositionText sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: 1 }}>
                        You need to verify your email to get 🧀-ing!
                        <EmailCTA />
                    </ExpositionText>
                </Box>
            }
            {!checkingVerify && !payment_verified &&
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start" }}>
                    <ExpositionText sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: 1 }}>
                        You need to verify your payment to get 🧀-ing!
                        <PaymentCTA checkoutUrl={checkout_url} />
                    </ExpositionText>
                </Box>
            }
            {!checkingVerify && (<>
                {!verified && <ExpositionText sx={{ display: "flex", flexDirection: "column", alignItems: "center", m: 1 }}>Soon you'll 🧀 with the best of 'em, like this:</ExpositionText>}
                <Box sx={{ width: "100%", height: "auto", overflow: "hidden" }}>
                    <img src="/cheese_social_preview_tall.jpg" alt="preview"
                        style={{ width: "100%", maxWidth: "md", objectFit: "fill" }} />
                </Box>
            </>)
            }
        </OnboardingLayout >
    );


}

const VerifyAndSuccess = () => {

    const userVerifiedState = getKeyedStore("user_verified_state", {
        email_state: {},
        payment_state: {},
        checkout_url: null,
        user_id: null,
        verified: false,
        checkingVerify: false,
        session_id: null,
    });

    // might not be originally authorized, but eventually user will be!
    const { user_id, auth, profile } = useAuthSession();

    // grab the code from search params please 
    const { enqueueSnackbar } = useSnackbar();
    const { verified, checkingVerify } = useSnapshot(userVerifiedState);

    const searchParams = new URLSearchParams(window.location.search);
    const session_id = searchParams.get("session_id");
    userVerifiedState.session_id = session_id;

    React.useEffect(() => {
        if (session_id) {
            // check verified status plz
            userVerifiedState.checkingVerify = true;
            session_api.post(`/${session_id}/verify`, {
                access_token: (auth || {}).access_token
            }).then(({ data }) => {
                console.log("verify response", data);
                userVerifiedState.checkingVerify = false;
                userVerifiedState.verified = data.verified;
                userVerifiedState.email_state = data.email_state;
                userVerifiedState.payment_state = data.payment_state;

            }).catch((err) => {
                console.error("verify error", err);
            })
        }
    }, [])

    if (session_id == null) {
        // you're outta here, session id or bust
        return <Navigate to={"/signup"} />
    }

    return (verified ? <Success /> : <VerifyCallToAction
        userVerifiedState={userVerifiedState}
    />)
}


export default VerifyAndSuccess;