import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TitleBar from "../components/titleBar"
import LexicaSearch from "../components/lexicaSearch"
import { FullContainer } from "../components/styled/containers"

const MainEntry = ({ }) => {
  // let params = useParams();
  // we'll need to get auth data 
  const [searchParams] = useSearchParams();
  const query = searchParams.get("q");
  return <FullContainer>
    <TitleBar initialQuery={query} />
    <LexicaSearch />
  </FullContainer>
}

export default MainEntry