import React, {useState, useEffect, useRef} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { ExpositionText, AccentExpositionText } from "./styled"
import OnboardingLayout from './layout';
import LinearProgress from '@mui/material/LinearProgress';
import { asyncConvertFileToBase64, dataURLtoFile } from './utils';
import { getImageData } from "../store";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnapshot } from "valtio"
import { UPLOADER_EVENTS } from "@rpldy/uploader";
import {useSnackbar} from 'notistack';
import { TextLoop } from "../../../styled/motion"
import _ from "lodash";
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

function LinearProgressWithLabel({value=0}) {
    const theme = useTheme();
    const progressColor = value < 5 ? theme.palette.error.main :
                          value < 10 ? theme.palette.warning.main :
                          value < 15 ? theme.palette.cheese.main :
                          theme.palette.success.main;
    const bgColor = "#FFFFFF";

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={Math.min(100*value/20, 100)}
            sx={{
                backgroundColor: bgColor,
                borderRadius: 5,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: progressColor
                }
              }}
          />
        </Box>
        <Box sx={{ minWidth: 50 }}>
          <Typography variant="body2">{`${value}/20`}</Typography>
          <Typography color={progressColor} variant="body2">
            { value == 0 ? "" : value <= 5 ? "Good start!" : value <= 10 ? "More!" : value < 15 ? "Keep going!" : "Great!"}
        </Typography>
        </Box>
      </Box>
    );
  }

const ImageUploadInput = ({ addFiles, libraryRef, ...props }) => {
    return <input ref={libraryRef} hidden accept=".png, .jpg, .jpeg" type="file"
        {...props}
        onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
                let tooMany = false
                addFiles(e.target.files)
            }
        }} />
}
const ImageUpload = ({ uploader, file, image, image_proxy, crop, crop_proxy, replaceFileAt }) => {

    useEffect(() => {
        if (image.src) {
            const upload_obj = uploader.upload
            const destination_url = uploader.destination_url
            const upload_info = { file: dataURLtoFile(image.src, "image_" + image.index + ".png"), index: image.index }
            const index_destination_url = destination_url + "/auto_crop/upload?index=" + upload_info.index
            console.log("ImageUpload destination_url: ", index_destination_url)
            upload_obj.add([upload_info.file], { autoUpload: true, destination: { url: index_destination_url } })
        }
    }, [image.src])

    // now display our image data plz 
    return <Box sx={{
        width: "100%", position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }}>
        <ImageCard
            image={image}
            replaceFileAt={replaceFileAt}
            crop={crop}
            crop_proxy={crop_proxy}
        />
    </Box>
}
const ImageCard = ({ image, replaceFileAt }) => {
    const libraryRef = useRef(null)
    const theme = useTheme();

    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            borderColor: theme.palette.secondary.main,
            borderRadius: '16px',
            borderStyle: "dashed",
            overflow: "hidden",
            borderWidth: "2px" }}
        >
            {image.src && image.uploaded ?
                <>
                    <img src={image.src} className="fleximg" onClick={() => {
                        // we need to replace this specific object for our files
                        if (libraryRef.current) {
                            libraryRef.current.click()
                        }
                    }} />
                    <ImageUploadInput libraryRef={libraryRef} addFiles={(files) => {
                        replaceFileAt(files[0], image.index)
                    }} />
                </>
                :
                <Stack spacing={1} sx={{
                    minHeight: "126px", 
                    justifyContent: "center",
                    color: theme.palette.grey.contrastText,
                    background: theme.palette.grey.main,
                    textAlign: "center",
                    // display: "flex",
                    alignItems: "center",
                }}>
                    <CircularProgress sx={{ color: theme.palette.grey.contrastText}} size={24} />
                    <Typography variant="h6" sx={{color: theme.palette.grey.contrastText}}>Uploading...</Typography>
                </Stack>
            }
        </Box>
    );
}

const Upload = ({ minimum_images=10, data_state, uploader, meta_uploader, requireCount, handleNext }) => {
    const img_data = getImageData("auto_crop");
    const snap_data = useSnapshot(img_data);
    const libraryRef = useRef(null);
    const [metadataUploading, setMetadataUploading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();

    const uploadMetadata = () => {
        // handleNext()
        const json_mdata = { auto_crop: img_data.crops }
        // _.mapValues(img_data, (val, key) => {
        //     return val.crops
        // })

        console.log("Metadata to send: ", json_mdata, img_data)
        if (_.size(json_mdata) == 0) {
            // throw new Error("No metadata to send")
            enqueueSnackbar("No metadata to send", {variant: "error"});
            return
        }
        // add metadata information regarding instance token and class
        // to be passed to training -- potentially
        json_mdata.metadata = {
            "instance_token": "yegdfn",
            "instance_class": "person"
        }

        setMetadataUploading(true)
        meta_uploader.post('/', json_mdata).then((res) => {
            if (res.status != 200) {
                enqueueSnackbar("Error uploading metadata: " + res.status, {variant: "error"});
                setMetadataUploading(false)
            }
            console.log("Metadata upload response: ", res)
            // make a call to server with the crop metadata
            setMetadataUploading(false)
            data_state.completed = true;
            handleNext()
        }).catch((err) => {
            setMetadataUploading(false)
            console.log("Error: ", err)
            enqueueSnackbar("Error uploading images. Try again shortly", {variant: "error"});
        })
    }

    const allUploaded = snap_data.uploaded === snap_data.files.length;
    const showNextButton = snap_data.files.length >= minimum_images;

    const getFooter = () => {
        return (<Stack>
            <Box sx={{width: "90vw", maxWidth: "1000px"}}>
                <LinearProgressWithLabel value={snap_data.files.length} />
            </Box>
            {showNextButton ?  
            <Button
                sx={{width: "90vw", maxWidth: "1000px", textTransform: "none"}}
                variant="contained"
                size="large"
                disabled={(metadataUploading || !allUploaded || !showNextButton)}
                onClick={() => uploadMetadata()}
                endIcon={<ArrowForwardIcon />} 
            >
                {(metadataUploading || !allUploaded || !showNextButton ) ?
                 "One moment - uploading... ": "Next - Crop my Pix"}
            </Button>
            : null }
        </Stack>);
    }
    
    // image handling
    const resetFileAt = (file, img_ix) => {
        img_data.images[img_ix] = {
            index: img_ix,
            uploaded: false,
            src: typeof file === "string" ? file : null
        }
        img_data.crops[img_ix] = {
            crop: {
                unit: '%', // Can be 'px' or '%'
                x: 0,
                y: 0,
                width: 100,
                height: 100
            }
        }
        if (img_data.files.length > img_ix) {
            img_data.files[img_ix] = file
        }
        else
            img_data.files.push(file)

    }
    const processFileAt = (file, img_ix) => {
        console.log("Processing: ", img_ix, " file: ", file)
        asyncConvertFileToBase64({ file }).then((data) => {
            img_data.images[img_ix].src = data
        })
    }

    const replaceFileAt = (file, img_ix) => {
        resetFileAt(file, img_ix)
        processFileAt(file, img_ix)
    }

    const deleteFileAt = (img_ix) => {
        // total pain in the butt, need to reindex everything
        img_data.images.splice(img_ix, 1);
        img_data.crops.splice(img_ix, 1);
        img_data.files.splice(img_ix, 1);
        for (var i=img_ix; i<img_data.images.length; i++) {
            img_data.images[i].index = i;
        }
    }

    const addFiles = (files) => {
        // append the files plz
        const newFiles = []
        _.forEach(files, (file, fix) => {
            const img_ix = img_data.files.length
            // skip too many!
            // if (img_ix >= max_img_count) {
            //     setWarningMsg("You can only upload " + max_img_count + " images max.")
            //     return false
            // }
            resetFileAt(file, img_ix)
            if (!img_data.images[img_ix].src) {
                newFiles.push({ file, img_ix })
            }
        })
        _.forEach(newFiles, (hold) => {
            processFileAt(hold.file, hold.img_ix)
        })
    }

    useEffect(() => {
        const upload_obj = uploader.upload
        upload_obj.on(UPLOADER_EVENTS.ITEM_START, (item) => {
            console.log(`item ${item.id} started uploading`);
        });

        upload_obj.on(UPLOADER_EVENTS.ITEM_ERROR, (err) => {
            console.error(`err ${err} on upload`, err);
        });

        upload_obj.on(UPLOADER_EVENTS.ITEM_FINISH, (item) => {
            console.log(`item ${item} finished!`, item);
            const ix = parseInt(item.file.name.split("_")[1].split(".")[0])
            img_data.images[ix].uploaded = true
            img_data.uploaded = _.filter(_.map(img_data.images, 'uploaded')).length
        });
        upload_obj.on(UPLOADER_EVENTS.ITEM_PROGRESS, (item) => {
            console.log(`item ${item} progress!`, item);
        });
    }, [])

    return (
        <OnboardingLayout
            footerComponent={getFooter()}
            title="Upload Some Pix!"
            stageText='Step 1 of 4'
            footerHeight={showNextButton ? 100 : 50}
        >
            <ExpositionText>
            Don't forget - you and you alone!<br/>
            You'll be able to crop in the next step.
            </ExpositionText>
            <AccentExpositionText>
            
            <AccentExpositionText>
                <TextLoop
                    prefix="Upload pics of you"
                    spanSx={{textDecoration: 'underline'}}
                    color={theme.palette.secondary.main}
                    leadMargin={6}
                    texts={[
                        "smiling",
                        "angry",
                        "looking away",
                        "from afar",
                        "underwater",
                        "with makeup",
                        "without makeup",
                        "inside",
                        "making faces",
                        "outside",
                        "sleeping",
                        "eating",
                        "wearing sunglasses",
                        "sneezing",
                        "sunburned"
                    ]}
                />
            </AccentExpositionText>
            </AccentExpositionText>
            <Button
                sx={{width: "100%", textTransform: "none"}}
                variant="contained"
                size="large"
                onClick={() => {
                    if (libraryRef.current) libraryRef.current.click()
                }}
            >
                Pick my pics
            </Button>
            <ImageUploadInput libraryRef={libraryRef} multiple addFiles={addFiles} />
            <Box flexGrow={1}>
                <Grid container spacing={2}>
                {_.map(snap_data.files, (file, index) => {
                    // const cropSnap = useSnapshot(img_data.crops[index])
                    return (<Grid key={`image_upload_${index}`} item xs={6} md={4} lg={3} xl={2}>
                        <ImageUpload 
                            // file={img_data.files[index]}
                            uploader={uploader}
                            replaceFileAt={replaceFileAt}
                            image={snap_data.images[index]}
                            image_proxy={img_data.images[index]}
                            crop={snap_data.crops[index]}
                            crop_proxy={img_data.crops[index]} />
                        {/* <IconButton onClick={()=>{deleteFileAt(index)}}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton> */}
                        </Grid>)
                })}
                </Grid>
            </Box>
        </OnboardingLayout>
    );
}

export default Upload;