import React, {useState} from 'react';
import { useParams } from 'react-router-dom';
import Landing from '../dream/create/views_new/Landing';
import Guidelines from '../dream/create/views_new/Guidelines';
import Upload from '../dream/create/views_new/Upload';
import Crop from '../dream/create/views_new/Crop';
import Classification from '../dream/create/views_new/Classification';
import Success from '../dream/create/views_new/Success';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from "../dream/create/views/utils"
import { getPageDataProxy, getSessionData, getMetaUploader, getImageUploader, useAuthSession, getLoginDetails } from "../dream/create/store"
import { useSBAuthSession } from "../supastore";

const getViewContent = ({ session_id }) => {
    return [
        {
            name: "landing",
            content: Landing,
            label: "Landing Page",
            completed: true,
            props: {
            },
        },
        {
            name: "guide",
            content: Guidelines,
            label: "Guide",
            completed: true,
            props: {
            },
        },
        {
            name: "upload",
            content: Upload,
            label: "Upload",
            is_active_step: true,
            props: {
                minimum_images: 10,
                uploader: getImageUploader(getBaseUrl() + `/api/dream/${session_id}`, { method: "POST" }),
                meta_uploader: getMetaUploader(getBaseUrl() + `/api/dream/${session_id}/metadata`, { 'method': 'POST' }),
            },
        },
        {
            name: "crop",
            content: Crop,
            label: "Crop",
            is_active_step: true,
            props: {
                minimum_images: 10,
                uploader: getImageUploader(getBaseUrl() + `/api/dream/${session_id}`, { method: "POST" }),
                meta_uploader: getMetaUploader(getBaseUrl() + `/api/dream/${session_id}/metadata`, { 'method': 'POST' }),
            },
        },
        {
            name: "classification",
            content: Classification,
            label: "Classification",
            is_active_step: true,
            props: {
            },
        },
        {
            name: "success",
            content: Success,
            label: "Success",
            props: {

            },
        },
    ]
}

const Onboarding = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [currentStep, setCurrentStep] = useState(0);

    const sb_session = useAuthSession()

    const { session_id } = useParams()

    if (session_id == null) {
        window.location.href = "/dream/create"
    }

    const { auth: sb_auth } = useSBAuthSession()
    const auth = getSessionData({ session_id })
    console.log("User Auth Data:", auth)

    const views = getViewContent({ session_id })
    const stepsCompleted = getPageDataProxy(views)

    
    const handleNext = () => {
        const cur_view = views[currentStep];
        const view_data = stepsCompleted[cur_view.name];
        if (view_data.completed) {
            setCurrentStep(Math.min(currentStep+1, views.length-1));
        } else {
            enqueueSnackbar("Please complete this step before moving on", {variant: "error"});
        }
    }
    const handleBack = () => {
        setCurrentStep(Math.max(currentStep-1, 0));
    }

    // we have our proxy of all the steps and whether they're complete or not
    // we can use this information to keep all our items in line


    const loginDetails = getLoginDetails()
    loginDetails.metadata = { fsession: auth.session }

    const signUploader = getMetaUploader(getBaseUrl() + `/api/signup/${session_id}`, { 'method': 'POST' })
    const handleLogin = async (e) => {
        if (e)
            e.preventDefault()

        loginDetails.signUpLoading = true
        return signUploader.post('/', {
            ...loginDetails,
        })
    }

    const current_state = stepsCompleted[views[currentStep].name]

    if (sb_auth && sb_auth.user) {
        // redirect -- no such thing as doing a new model while signed up already! 
        window.location.href = "/me"
    }

    const getCurrentView = () => {
        const ViewContent = views[currentStep];
        return <ViewContent.content 
                    key={`view_${currentStep}`}
                    sb_session={sb_session}
                    handleLogin={handleLogin}
                    loginDetails={loginDetails}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    data_state={stepsCompleted[ViewContent.name]}
                    content_name={ViewContent.name}
                    {...ViewContent.props}
                />
    }
    
    
    return getCurrentView();
}

export default Onboarding;