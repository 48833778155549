import React from 'react';
import TitleBar from "../components/titleBar"
import Box from '@mui/material/Box';
import MuiMarkdown from 'mui-markdown';
import Typography from '@mui/material/Typography';
import { FullContainer } from "../components/styled/containers";

const policy_markdown = `
#### Introduction
 
Thank you for using Cheese. Our goal is to make AI easy and fun to use. As part of that goal, we believe it is important to be open and clear with our users on what personal information we collect and how we use it.
 
To be clear, we do not sell any user’s personal information, and the images you upload to Cheese will be used only for the purposes of training and refining the AI models we use to provide you the Service We may share your personal information only to maintain and improve the Service for you and for marketing purposes.
 
This Privacy Policy describes how your personal information is collected, used, and shared when you use our website ([cheese.baby](https://cheese.baby)) and our software applications (browser extensions and mobile apps) (collectively the “Service” or “Services”).
 
#### Personal Information We Collect
 
When you use our Services, we automatically collect the following information about you (collectively referred to as “Personal Information”):
 
* Contact Information:
    - Your email address
      
* Identifiers:
    - User ID: Such as screen name, handle, account ID, or other user- or account-level ID that can be used to identify a particular user or account. This information could be provided via your Cheese account, Apple ID, Google Account, or other accounts you may use on the Services. User ID also includes your account password, other credentials, security questions, and confirmation codes.
    - Device ID: Your device information which includes, but is not limited to, information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.
 
* Usage Data:
    - Product Interaction: How you interact with our Services and what features you use within the Services, including Cheese’s sort bar, highlights, review grade, seller ratings, alternative sellers, settings and popups.
    - Other Usage Data: Individual web pages or products that you view, what websites or search terms referred you to the Service, and other information about how you interact with the Service.
 
* Browser Information: Information your internet browser provides when you access and use our Services.
 
* Application Search History: Information you provide when you perform searches in our Services.
 
* Purchase Information: Your purchase history or purchase tendencies which we may use to recommend better images and styles..
 
* Diagnostic Information: Any other data collected for the purposes of measuring technical diagnostics related to the app
 
#### Personal Information You Choose to Provide to Us
 
When you use our Services, you may choose to provide the following personal information to us:
 
* Your first and last name in an email inquiry or support request
* Your phone number in an email inquiry or support request
* Your home or work address in an email inquiry or support request
* The images you upload to Cheese.
* Any comments or other writings you make on the Cheese app platform.
 
We keep all of this information confidential and only use it for training your AI models, communicating directly with you, or to resolve a support request.
 
#### We collect Personal Information using the following technologies:
 
* “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
* “Log files” track actions occurring on the Service, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. These files include event data we collect using Google Analytics and our internal event system.
* “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Service.
 
#### How do we use your Personal Information?
 
Depending on the way you access our Services We may use your personal information in several ways:
 
* Maintain your user account and account information;
* Screen for potential risk and fraud (in particular, your IP address); and
* To improve and optimize our Service (for example, by generating analytics about how our customers browse and interact with the Service, and to assess the success of our marketing and advertising campaigns);
* Store your product analysis and search history on the Service;
* Serve you with advertising from our advertising partners only while using the Service and only within our Services and applications;
* Provide you with information or advertising relating to our products or services; and
* Market our products and services to others.
 
#### Data Linked to your Personal Identity
 
Depending on how you use Cheese, we may or may not link data we collect on Cheese to your personal identity (e.g. your name and location).
 
Cheese.baby: We collect the following data when you use [cheese.baby](https://cheese.baby), and may link it to your personal identity in order to effectively market our products and services to you and others:
 
* Contact Info
* Identifiers
* Usage Data
* Application Search History (e.g. not your Google/Bing/other search engine history)
* Purchases
* Diagnostics
 
#### Sharing your Personal Information
 
We may share your Personal Information with third parties to use it as described above. We will not share your personal information with third parties for marketing purposes. We use Google Analytics to help us understand how our customers use the Service. You can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
 
Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
 
As described above, we may use your Personal Information to provide you with targeted advertisements or marketing communications in-app that we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
 
You can opt-out of targeted advertising from the most popular internet advertising providers here:
 
Google - https://www.google.com/settings/ads/anonymous
 
Facebook - https://www.facebook.com/help/568137493302217
 
Twitter - https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads
 
Other internet advertising providers may have their own targeted advertising opt-outs and we encourage you to be aware of them. These providers include, but are not limited to, SnapChat, TikTok, and Reddit.
 
Additionally, you can opt-out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at http://optout.aboutads.info/.
 
#### Do not track
 
Please note that we do not alter our Service’s data collection and use practices when we see a Do Not Track signal from your browser.
 
#### CALIFORNIA AND CALIFORNIA CONSUMER PRIVACY ACT (“CCPA”) PRIVACY RIGHTS AND DISCLOSURES
 
This section addresses legal obligations and rights laid out in the California Consumer Privacy Act (“CCPA”). These obligations and rights apply to businesses doing business in California and to California residents and information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with California consumers or households ("California Information").
 
#### CALIFORNIA INFORMATION WE COLLECTED AND SHARED
 
This section provides the information California residents need to exercise their rights over their California Information. Here is information about the California Information we have collected from and shared about consumers over the twelve months prior to the Effective Date of this Privacy Policy.
 
#### California Information We Collected
 
* In the year before the date this policy was issued, on the Services we may have collected the following categories of California Information:
* Address and other identifiers -- such as name, postal address, zip code, email address, account name, payment card numbers, or other similar identifiers
* Unique and online identifiers -- such as IP address, device IDs, or other similar identifiers
* Characteristics of protected classifications -- such as race, ethnicity, or sexual orientation
* Commercial information -- such as products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies
* Internet, gaming or other electronic network activity information -- such as browsing history, search history and information regarding an individual's interaction with an internet website, application, or advertisement
* Professional or educational Information
* Biometric information
* Location information -- (e.g. if you access our Sites on your mobile device we may collect Information about your device's precise location.)
* Online viewing activities (e.g. pages viewed)
* Inferences drawn from California Information, such as individual profiles, preferences, characteristics, behaviors.
 
We may have collected these categories of California Information for the following purposes:
 
* Performing services on behalf of the Services, such as customer service, providing content recommendations, and processing payments
* Fraud and crime prevention
* Debugging errors in systems
* Marketing and advertising
* Internal research, analytics and development -- e.g., user-preference analytics
* Developing, maintaining, provisioning or upgrading networks, products, services, or devices.
 
We may have obtained California Information from a variety of sources, including:
* Directly from you, including technical and usage information when you use our Services
* Linked sites, such as Social Media Sites, and third-party platforms
* Our joint-ventures and promotional and strategic partners
* Information suppliers
* Distributors and other vendors
* Marketing mailing lists
* Other users submitting California Information about you, such as to invite you to participate in an offering, make recommendations, or share content
* Publicly available sources.
 
California Information We Shared
 
In the year before the date this policy was issued, for some Sites we may have shared these categories of California Information for operational purposes with providers who provide services for us such as our hosting providers:
 
* Address and other identifiers - such as name, postal address, zip code, email address, account name, payment card numbers, or other similar identifiers
* Unique and online identifiers -- such as IP address, device IDs, or other similar identifiers
* Characteristics of protected classifications -- such as race, ethnicity, or sexual orientation
* Commercial information -- such as products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies
* Internet, gaming or other electronic network activity information -- such as browsing history, search history and information regarding an individual's interaction with an internet website, application, or advertisement
* Professional or educational Information
* Location information -- (e.g. if you access our Sites on your mobile device we may collect Information about your device's precise location.)
* Online viewing activities (e.g. pages viewed)
* Inferences drawn from California Information, such as individual profiles, preferences, characteristics, behaviors.
 
**The California Consumer Privacy Act defines 'sale' very broadly. It includes the sharing of California Information in exchange for anything of value. According to this broad definition, in the year before the date this policy was issued, on some Sites we may have sold the following categories of California Information:**
 
* Commercial information -- such as records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies
* Internet or other electronic network activity information -- such as browsing history, search history, and information regarding an individual's interaction with an internet website, application, or advertisement
* Location information - (e.g. if you access our Sites on your mobile device we may collect Information about your device's precise location.)
* Inferences drawn from California Information, such as individual profiles, preferences, characteristics, behaviors.
 
#### YOUR CALIFORNIA PRIVACY RIGHTS TO REQUEST DISCLOSURE OF INFORMATION WE COLLECT AND SHARE ABOUT YOU
 
If you are a California resident, California Civil Code Section 1798.83 permits you to request information about our practices related to the disclosure of your personal information by Cheese Inc. to certain third parties for their direct marketing purposes. You may be able to opt-out of our sharing of your personal information with unaffiliated third parties for the third parties' direct marketing purposes in certain circumstances. Please send your request (along with your full name, email address, postal address, and the subject line labeled "Your California Privacy Rights") by email at [support@cheese.baby](mailto:support@cheese.baby). 
 
In addition, if you are a California resident, the CCPA grants you the right to request certain information about our practices with respect to California Information. In particular, you can request the following:
 
* The categories and specific pieces of your California Information that we've collected
* The categories of sources from which we collected California Information
* The business or commercial purposes for which we collected or sold California Information
* The categories of third parties with which we shared California Information.
 
You can submit a request to us for the following additional information:
 
* The categories of third parties to which we've sold California Information, and the category or categories of California Information sold to each.
* The categories of California Information that we've shared with service providers who provide services for us, like processing your bill.
 
To exercise your CCPA rights with respect to this information, please email us at [support@cheese.baby](mailto:support@cheese.baby). These requests for disclosure are generally free.
 
#### YOUR RIGHT TO REQUEST THE DELETION OF CALIFORNIA INFORMATION
 
Upon your request, we will delete the California Information we have collected about you, except for situations when that information is necessary for us to: provide you with a product or service that you requested; perform a contract we entered into with you; maintain the functionality or security of our systems; comply with or exercise rights provided by the law; or use the information internally in ways that are compatible with the context in which you provided the information to us, or that are reasonably aligned with your expectations based on your relationship with us.
 
To exercise your right to request the deletion of your California Information, please email us at [support@cheese.baby](mailto:support@cheese.baby). These requests are generally free.
 
#### YOUR RIGHT TO ASK US NOT TO SELL YOUR CALIFORNIA INFORMATION
 
You can always tell us not to sell your California Information by emailing us at [support@cheese.baby](mailto:support@cheese.baby).
 
Once we receive and process your request, we will not sell your California Information unless you later allow us to do so.
 
#### DO NOT TRACK NOTICE
 
We do not currently take actions to respond to Do Not Track signals and similar signals because a uniform technological standard has not yet been developed. We continue to review new technologies and may adopt a standard once one is created.
 
See the Your Choices section to learn how to control data collection for certain purposes.
 
#### OUR SUPPORT FOR THE EXERCISE YOUR DATA RIGHTS
 
We are committed to providing you control over your California Information. If you exercise any of these rights explained in this section of the Privacy Policy, we will not disadvantage you. You will not be denied or charged different prices or rates for goods or services or provided a different level or quality of goods or services.
 
#### CONSUMERS UNDER 16 YEARS OLD
 
CCPA has specific rules regarding the use of California Information from consumers under 16 years of age. In particular, consistent with the CCPA, if we knowingly collect the California Information of a consumer under the age of 16, we will not sell the information unless we receive affirmative permission to do so.
 
If the consumer is between the ages of 13 and 16 years of age, the consumer may provide that permission; if the consumer is under the age of 13, the consumer's parent or guardian must provide the permission.
 
If you would like further information on how we handle California Information from consumers under the age of 16 years of age, or if you have questions about these information practices, you may contact us at: [support@cheese.baby](mailto:support@cheese.baby).
 
#### Your rights under the European Union General Data Protection Regulation (“GDPR”)
 
If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please email us at [support@cheese.baby](mailto:support@cheese.baby) with the subject “GDPR Request” and please provide your specific request within the body of the email so we can efficiently accommodate your request.
 
Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to the United States.
 
#### Data Retention
 
When you use the Service, we will retain your Personal Information and use it as described above unless and until you ask us to delete this information.
 
#### Minors
 
You must be at least 13 years old to use the Service. The Service complies with the rules of the Children’s Online Privacy Protect Act (“COPPA”). COPPA applies to the online collection of personal information from children under 13. Accordingly, the Service does not permit anyone under the age of 13 to use the Service.  In addition, the Service does not knowingly collect or solicit any information from anyone under the age of 13. If the Service unknowingly collects personal information from a child under the age of 13 without parental consent, we will delete that information as soon as possible. Please contact us at [support@cheese.baby](mailto:support@cheese.baby) with the subject “COPPA” if you believe we might have any information from or about a child under 13.
 
#### Image Permissions
 
By using the Service, you agree to only upload images to which you have permission. Any images you upload of others must only be used with the express permission of the subjects of those images. 
 
#### Links to Other Websites
 
As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Service. Therefore, this Privacy Policy does not apply to your use of a third-party website or application accessed by selecting a link provided on the Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites or applications before proceeding to use them.
 
#### Changes
 
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
 
#### Contact Us
 
For more information about our privacy policy or if you have any questions please contact us by email at [support@cheese.baby](mailto:support@cheese.baby) with the subject “Privacy Policy Question”.
`;

const MainEntry = ({ }) => {
    // let params = useParams();
    // we'll need to get auth data 
    return <FullContainer>
        <TitleBar />
        <Box sx={{p: 5}}>
            <Typography variant="h4" textAlign="center">Privacy Policy</Typography>
            <Typography variant="subtitle1" textAlign="center">Effective Date: 04/26/22</Typography>
            <MuiMarkdown>{policy_markdown}</MuiMarkdown>
        </Box>
    </FullContainer>
}

export default MainEntry