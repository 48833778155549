import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useAuthSession } from '../supastore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';

const ReportDrawer = ({post, open=false, onClose=null, anchor="bottom"}) => {
    const {user_id, profile} = useAuthSession();
    const [selectedReason, setSelectedReason] = useState(null);
    const [reasonDetails, setReasonDetails] = useState("");
    const {enqueueSnackbar} = useSnackbar();

    const reportReasons = [
        {
            key: "nsfw",
            main: "It's NSFW",
            detailBoxHint: null,
            showDetailBox: false,
        },
        {
            key: "racist/hateful",
            main: "It's racist or hateful",
            detailBoxHint: null,
            showDetailBox: false,
        },
        {
            key: "violent",
            main: "It's violent",
            detailBoxHint: null,
            showDetailBox: false,
        },
        {
            key: "misinformation",
            main: "It's misleading or misinformation",
            detailBoxHint: null,
            showDetailBox: false,
        },
        {
            key: "impersonation",
            main: "It's impersonating someone without their permission",
            detailBoxHint: "Tell us who this post is impersonating (e.g. me)...",
            showDetailBox: true,
        },
        {
            key: "other",
            main: "Other",
            detailBoxHint: "Tell us what about this post is objectionable...",
            showDetailBox: true,
        },
    ];

    const handleSelect = (reason) => {
        console.log("Select: ", reason);
        setSelectedReason(reason);
        if (!reason.showDetailBox) {
            handleSubmit(reason);
        }
    }

    const handleSubmit = (reason) => {
        // send API request to submit report
        console.log("Submit: ", reason.key, reasonDetails);
        setReasonDetails(null);
        setSelectedReason(null);
        enqueueSnackbar("Thanks! Our team will take a look. We appreciate your help keeping Cheese safe.", {variant: "success"});
        onClose();
    }

    return (
        <Drawer anchor={anchor} onClose={onClose} open={open}>
        <Box sx={{
            width: "100%",
            p: 1
        }}>
            <IconButton variant='contained' onClick={onClose}  sx={{float: "right"}}>
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" textAlign="center">Report</Typography>
            <Stack spacing={2} sx={{width: '100%'}}>
            {selectedReason && selectedReason.showDetailBox ? (
                <>
                    <Typography variant="h6">{selectedReason.main}</Typography>
                    <Divider/>
                    <TextField
                        id="reason-details"
                        sx={{width: '100%'}}
                        placeholder={selectedReason.detailBoxHint}
                        onChange={(e) => setReasonDetails(e.target.value)}
                        value={reasonDetails}
                        minRows={3}
                        multiline
                        variant="filled"
                    />
                    <Button variant="contained" onClick={()=>{handleSubmit(selectedReason)}}>Submit</Button>
                </>
            ):(
                <>
                    <Typography variant="h6">Why are you reporting this post?</Typography>
                    <List>
                    {reportReasons.map((r, idx)=>{
                        return <ListItem
                            key={`reason_${idx}`}
                            secondaryAction={<KeyboardArrowRightIcon />}
                            divider={true}
                            onClick={()=>handleSelect(r)}
                            sx={{'&:hover': {backgroundColor: "#FFFFFF33"}}}
                        >
                            <ListItemText primary={r.main}/>
                        </ListItem>
                    })}
                    </List>
                </>
            )}
            </Stack>
        </Box>
        </Drawer>
    )
}


export default ReportDrawer;