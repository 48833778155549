import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { HomeBox } from "../styled/containers"
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import AllImagesList from './tabs/allImages';
import PublicImagesList from './tabs/publicImages';
import QueueJobsList from './tabs/queueJobs';
import { useSnapshot } from 'valtio'
import { getUserProfile, getUserJobs, useAuthSession, getKeyedStore } from '../supastore';
import _ from 'lodash';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ProfilePictureDrawer from './profilePictureDrawer';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -7,
    top: -4,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '4px 4px',
  },
}));

// interface UserData {
//   avatar_url: string;
//   username: string;
//   id: string;
//   followerCount: number;
//   followers: string[];
// }


// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
          width: "100%",
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Profile = ({ userId }) => {
  const { user_id, auth, profile: uprofile } = useAuthSession();

  const bottomNavStore = getKeyedStore("bottomNavStore")
  // const { tabName } = useSnapshot(bottomNavStore)
  // console.log("tabName", tabName, bottomNavStore)
  const isAuthUser = user_id === userId;
  // console.log(user_id, userId);
  const theme = useTheme()
  const [showProfilePictureDrawer, setShowProfilePictureDrawer] = useState(false);

  const [jobCount, setJobCount] = useState(0);
  // tab controls
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // data handling
  const [userData, setUserData] = useState({
    avatar_url: "",
    displayName: "",
    id: "",
    followerCount: 0,
  });
  // console.log("Profile :", uprofile, " is auth:", isAuthUser, userData)

  const fetchUserData = async () => {
    const profile = await getUserProfile(userId);
    setUserData({ ...profile })

    const { data: jobs_data } = await getUserJobs(userId);
    // count the number of open inference jobs 
    // console.log("Jobs data back from fed: ", jobs_data)
    setJobCount(_.filter(jobs_data, (job) => job.queue_job_type.includes("inference") && job.queue_progress != 100).length)
    // setUserData({
    //   avatar_url: "",
    //   displayName: "Test User",
    //   id: userId,
    //   followerCount: 0,
    // });
  };

  useEffect(() => {
    async function fetchData() {
      await fetchUserData();
    }
    fetchData();
  }, []);

  return (
    <Box sx={{
      overflow: "unset",
      // width: "100%",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "center",
      // justifyContent: "flex-start",
    }}>
      <Stack justifyContent="center" display="flex" textAlign="center">
        <Avatar
          src={isAuthUser ? uprofile.avatar_url : userData.avatar_url}
          style={{ border: '4px solid #fff' }}
          sx={{ width: 96, height: 96, margin: "auto" }}
          onClick={isAuthUser ? () => { setShowProfilePictureDrawer(true) } : null}
        />
        <Typography variant="h5" sx={{ fontWeight: 600 }}>{userData.name || userData.username}</Typography>
      </Stack>
      {isAuthUser ? (
        <>
          <Box
            className="profile-tabs"
            sx={{
              borderBottom: 1,
              borderColor: theme.palette.background.divider//'#697586'
            }}>
            <Tabs
              variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs" centered>
              <Tab label="All Images" {...a11yProps(0)} />
              <Tab label="Public" {...a11yProps(1)} />
              <Tab  {...a11yProps(2)}
                label={<StyledBadge color="primary" badgeContent={jobCount}
                // anchorOrigin={{
                //   vertical: 'bottom',
                //   horizontal: 'right',
                // }}
                >
                  Queue
                </StyledBadge>}

              >


              </Tab>
              {/* <Tab label="Saved" {...a11yProps(3)} /> */}
            </Tabs>
          </Box>
          <HomeBox sx={{ pt: 5 }}>
            <TabPanel value={value} index={0}>
              <AllImagesList userId={userId} isActive={value === 0} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PublicImagesList userId={userId} isActive={value === 1} />

            </TabPanel>
            <TabPanel value={value} index={2}>
              <QueueJobsList userId={userId} isActive={value === 2} />
            </TabPanel>
          </HomeBox>
          {/* <TabPanel value={value} index={3}>
            <AllImagesList userId={userId} />
          </TabPanel> */}
          <ProfilePictureDrawer onClose={() => { setShowProfilePictureDrawer(false);  }} open={showProfilePictureDrawer} />
        </>
      ) : (
        <>
          <Box sx={{
            borderTop: 1,
            borderColor: 'divider',
            marginTop: 5,
            // marginBottom: 5

          }} />
          <HomeBox sx={{ pt: 5 }}>
            <PublicImagesList userId={userId} isActive={true} />
          </HomeBox>
        </>
      )
      }
    </Box>)

}


export default Profile