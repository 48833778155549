import { getBaseUrl, useAuthSession, getKeyedStore, supabase, getIdToProfiles } from "../../supastore";
import axios from 'axios';

const admin_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user/admin_actions`,
})
const userStore = getKeyedStore('list_users_admin', { users: [], hasMore: true, userCount: 0, })

const fetchMoreUsers = async ({ snap_users, userStore, access_token, enqueueSnackbar }) => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 
    if (!userStore.hasMore) {
        return
    }
    const fetchSize = 1000
    const min_range = snap_users.length
    const max_range = min_range + fetchSize
    const { data, error, count } = await admin_api.post('/', { access_token, action: 'list_users', action_args: { min_range, max_range } })
    if (error) {
        console.log("fetch user error", error)
        enqueueSnackbar("Failed List Users", { variant: 'error' })
        return
    }
    console.log("Users:", data, " user count: ", count)
    if (data.length == 0 || data.length % fetchSize !== 0) {
        // ignore for now
        // setHasMore(false)
        userStore.hasMore = false
    }
    // console.log("Queried User Images: ", data)
    const { users } = data
    console.log("Queried User Images: ", data)
    // get all img data
    userStore.users = [...userStore.users, ...users]
};



export {
    admin_api,
    userStore,
    fetchMoreUsers
}