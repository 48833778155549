import React, { useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from '@mui/material/Grid';
import UserListCard from './userListCard';
import { getKeyedStore, getUserModels, useAuthSession } from '../supastore';
import { useSnapshot } from 'valtio';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { admin_api } from "./actions/user"
import { useSnackbar } from 'notistack'

// import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import _ from "lodash"


const columns = [
  {
    field: 'username', flex: 1,
    headerName: 'Username',
    editable: false,
    sortable: true,
    valueGetter: (params) => { return params.row.username || "NULL" }
  },
  {
    field: 'email', flex: 2,
    headerName: 'Email',
    sortable: true,
    editable: false,
  },
  {
    field: 'id', flex: 3,
    headerName: 'User ID',
    // width: 100,
    editable: false,
  },

  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (params) =>
  //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  // },
];

const UserTableHeader = ({ }) => {
  return <TableHead>
    <TableRow>
      <TableCell>Username</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>User ID</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>
}

const adminRetrainModel = async ({ user_id, access_token, session_id, enqueueSnackbar, num_steps = 3500, seed = 4242 }) => {

  // if (session_id == null) {
  //   // get the user's models
  //   const models = await getUserModels(user_id, 0, 100)
  //   // get the latest model
  //   console.log("user models:", user_id, models)
  //   session_id = models[0].queue_id
  // }
  if (!session_id || !access_token || !user_id) {
    console.error("No session_id found for user", user_id)
    return
  }

  // now we're ready to contact admin server
  const { data, error: invite_error } = await admin_api.post('/', {
    access_token,
    action: 'retrain_model',
    action_args: { session_id, user_id, num_steps, seed }
  })
  if (invite_error) {
    console.error("Error retraining model", invite_error)
    if (enqueueSnackbar) {
      enqueueSnackbar("Error retraining model: " + invite_error, { variant: "error" })
    }
    return
  }
  console.log("Retrained model", data)
  if (enqueueSnackbar)
    enqueueSnackbar("Retraining user model!", { variant: "success" })
  return data

}

const UserTableBody = ({ users }) => {

  const { auth } = useAuthSession()
  const { enqueueSnackbar } = useSnackbar()
  const adminData = getKeyedStore("admin_user_list")
  const { showModal, modalDetails } = useSnapshot(adminData)

  const sorted_users = _.sortBy(users, (user) => { return user.username.toLowerCase() })

  const onConfirm = async (user_id, session_id, user) => {
    console.log("onConfirm Retrain", user_id, session_id, user)
    const success = await adminRetrainModel({
      user_id,
      session_id,
      access_token: auth.access_token,
      enqueueSnackbar
    })

    adminData.showModal = false
  }


  return <TableBody>
    {_.map(sorted_users, (user) => {
      const user_id = user.id
      const session_id = (user.models && user.models.length > 0) ? user.models[0].queue_id : null

      return <TableRow key={user.id}>
        <TableCell>{user.username}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.id}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button variant="contained" color="primary" size="small"
              onClick={() => {
                const modalDetails = {}
                modalDetails.onClose = () => {
                  adminData.showModal = false
                }
                modalDetails.title = "Retrain Model Confirmation"
                modalDetails.bodyText = `If you confirm, you are retraining ${user.username}'s model IN PLACE and it will overwrite their current model. \nContinue? (DEBUG INFO: ${user_id}::${session_id})`
                modalDetails.buttons = [
                  {
                    onClick: () => {
                      adminData.showModal = false
                    }, text: "Cancel", props: {}
                  },
                  { onClick: () => { return onConfirm(user_id, session_id, user) }, text: "Confirm", props: { color: "primary", variant: "contained" } },
                ]
                adminData.modalDetails = modalDetails
                adminData.showModal = true

              }}>Retrain Model</Button>
          </Box>
        </TableCell>
      </TableRow>
    }
    )}
  </TableBody>
}
const UserTableComponent = ({ users }) => {
  return <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
    <Table aria-label="simple table">
      <UserTableHeader />
      <UserTableBody users={users} />
    </Table>
  </TableContainer>
}

const InfiniteUserList = ({ users, userData, fetchMore, hasMore, isActive = true }) => {

  const adminData = getKeyedStore("admin_user_list", {
    showModal: false, modalDetails: {
      onClose: () => { }, title: "", bodyText: "", buttons: []
    }
  })
  const { showModal, modalDetails } = useSnapshot(adminData)
  const { onClose, title, bodyText, buttons } = modalDetails


  useEffect(() => {
    fetchMore();
  }, [])

  const rows = users.map((user) => { return { ...user } })
  // console.log("New Queue Inf Data;", users, " udata:", rows)
  console.log("Show Modal: ", showModal, " adtaa", adminData)
  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      height: 'calc(100vh - 200px)',
    }}>
      <Dialog
        open={showModal}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {_.map(buttons, (button, idx) => {
            if (button.component)
              return button.component
            return <Button key={"b_" + idx} onClick={button.onClick} {...button.props}>{button.text}</Button>
          })}
        </DialogActions>
      </Dialog>
      <Box sx={{ flexGrow: 1 }} >
        <UserTableComponent users={users} />
        {/* <DataGrid
          // sx={{
          //   width: '100%',
          //   height: "calc(100vh - 200px)",
          // }}
          initialState={{
            pagination: {
              pageSize: 25,
            },
          }}
          maxColumns={columns.length}
          rows={rows}
          columns={columns}
        // checkboxSelection
        // disableSelectionOnClick
        /> */}
      </Box>
    </Box>
    // <InfiniteScroll
    //   dataLength={users.length}
    //   next={() => { if (isActive) { fetchMore() } }}
    //   hasMore={hasMore}
    //   loader={<h4>Loading...</h4>}
    //   useWindow={false}
    // >
    //   <Grid container width="100%">
    //     {users.map((dd, idx) => {
    //       return (
    //         <Grid item key={`user_${idx}`} xs={12}>
    //           <UserListCard userData={dd} />
    //         </Grid>
    //       )
    //     })}
    //   </Grid>
    // </InfiniteScroll>
  );
};

export default InfiniteUserList;
