import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Landing from './views_new/Landing';
import Guidelines from './views_new/Guidelines';
import Crop from './views_new/Crop';
import Classification from './views_new/Classification';
import Payments from './views_new/Payments';
import Success from './views_new/Success';
import Signup from "./views_new/Signup";
import { useSnackbar } from 'notistack';
import { getBaseUrl } from "./views/utils";
import Upload from './views_new/Upload';
import { getPageDataProxy, getDefaultPageData, getSessionData, getMetaUploader, getImageUploader, useAuthSession, getLoginDetails } from "./store"
import { useSBAuthSession } from "../../supastore";
import axios from 'axios';

const beta_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user`,
    headers: {
        'Content-Type': 'application/json',
    },
});

const session_api = axios.create({
    baseURL: `${getBaseUrl()}/api/dream`,
    headers: {
        'Content-Type': 'application/json',
    },
});


const getViewContent = ({ isLoggedIn, session_id }) => {

    // 
    // if (!isLoggedIn && session_id == null) {
    //     return [{
    //         name: "signup",
    //         content: Signup,
    //         label: "Signup Page",
    //         is_active_step: true,
    //         props: {

    //         },
    //     }]
    // }
    // if you're logged in, you're here or session_id != null 
    // might want to handle either...

    // let views = []
    // let views = !isLoggedIn ? [
    //     {
    //         name: "signup",
    //         content: Signup,
    //         label: "Signup Page",
    //         is_active_step: true,
    //         props: {

    //         },
    //     }
    // ] : [];
    return [
        // {
        //     name: "signup",
        //     content: Signup,
        //     label: "Signup Page",
        //     is_active_step: false,
        //     props: {

        //     },
        // },
        {
            name: "landing",
            content: Landing,
            label: "Landing Page",
            completed: true,
            props: {
            },
        },
        {
            name: "guide",
            content: Guidelines,
            label: "Guide",
            completed: true,
            props: {
            },
        },
        {
            name: "upload",
            content: Upload,
            label: "Upload",
            is_active_step: true,
            props: {
                minimum_images: 10,
                uploader: getImageUploader(getBaseUrl() + `/api/dream/${session_id}`, { method: "POST" }),
                meta_uploader: getMetaUploader(getBaseUrl() + `/api/dream/${session_id}/metadata`, { 'method': 'POST' }),
            },
        },
        {
            name: "crop",
            content: Crop,
            label: "Crop",
            is_active_step: true,
            props: {
                minimum_images: 10,
                uploader: getImageUploader(getBaseUrl() + `/api/dream/${session_id}`, { method: "POST" }),
                meta_uploader: getMetaUploader(getBaseUrl() + `/api/dream/${session_id}/metadata`, { 'method': 'POST' }),
            },
        },
        {
            name: "classification",
            content: Classification,
            label: "Classification",
            is_active_step: true,
            props: {
                meta_uploader: getMetaUploader(getBaseUrl() + `/api/dream/${session_id}/metadata`, { 'method': 'POST' }),
            },
        },
        {
            name: "payments",
            content: Payments,
            label: "payments",
            is_active_step: true,
            props: {
            },
        },
        // {
        //     name: "success",
        //     content: Success,
        //     label: "Success",
        //     props: {

        //     },
        // },
    ]
}

const Onboarding = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    const sb_session = useAuthSession()
    const [sessionCheck, setSessionCheck] = useState(false)

    const { session_id } = useParams()
    console.log("Session ID:", session_id)
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')
    // redirect to session via url search params if it exists
    if (session_id == null) {
        const session_code = searchParams.get('session_id')
        if (session_code != null) {
            navigate(`/dream/create/${session_code}?code=${code}`, { replace: true })
            // return
        }
        //     window.location.href = "/dream/create"
        else {

            if (session_id == null && code == null && currentStep == 0) {
                // redirect to login page
                // console.log("IM HERE YOU DOPE", session_id, code)
                navigate(`/signup`, { replace: true })
            }
        }
    }

    const { anyOpenSessions, auth: sb_auth } = useSBAuthSession()
    const auth = getSessionData({ session_id })
    console.log("User Auth Data:", auth)
    console.log(sb_auth !== undefined);
    const isLoggedIn = sb_auth !== undefined;

    const views = getViewContent({ isLoggedIn, session_id })
    console.log(views);
    const stepsCompleted = getPageDataProxy(views)
    const signup_data = getDefaultPageData({
        name: "signup",
        label: "Signup Page"
    })

    const handleNext = () => {
        const next_step = Math.min(currentStep + 1, views.length - 1)
        const cur_view = views[currentStep];
        const next_view = views[next_step];

        const view_data = stepsCompleted[cur_view.name];

        console.log("Updating session data then moving to next step", next_step, next_view)

        session_api.post(`/${session_id}/update`, {
            session_state: next_view.name
        }).then((res) => {
            console.log("Updated session state", res.data)
            if (view_data.completed) {
                setCurrentStep(next_step);
            } else {
                enqueueSnackbar("Please complete this step before moving on", { variant: "error" });
            }
        }).catch((err) => {
            console.log("Error updating session state", err);
            enqueueSnackbar("Error updating session, try again!", { variant: "error" });
        })
    }
    const handleBack = () => {
        setCurrentStep(Math.max(currentStep - 1, 0));
    }

    // we have our proxy of all the steps and whether they're complete or not
    // we can use this information to keep all our items in line


    const loginDetails = getLoginDetails()
    loginDetails.metadata = { fsession: auth ? auth.session : null }
    const signUploader = getMetaUploader(getBaseUrl() + `/api/signup/${session_id}`, { 'method': 'POST' })

    const handleLogin = async (e) => {
        if (e)
            e.preventDefault()

        loginDetails.signUpLoading = true
        console.log("Attempting login", loginDetails)
        console.log("Beta code:", code)
        // TODO: post to beta api  
        return beta_api.post(`/beta`, { code_id: code, ...loginDetails }).then(({ data }) => {
            // there should be a session_id
            const { session_id } = data || {}
            console.log("Beta Exchange success: ", data)
            navigate(`/dream/create/${session_id}`, { replace: true })
        })
        // .catch(err => {
        //     console.log("error during beta signup", err)
        //     enqueueSnackbar(`There was an error signing up: ${err.response.data.error}`, { variant: "error" });
        // })
        // return signUploader.post('/', {
        //     ...loginDetails,
        // })
    }

    const current_state = stepsCompleted[views[currentStep].name]

    // all complete? get outta here!
    // if (!anyOpenSessions && sb_auth && sb_auth.user) {
    //     window.location.href = "/me"
    // }

    // if (sb_auth && sb_auth.user) {
    //     // redirect -- no such thing as doing a new model while signed up already! 
    //     window.location.href = "/me"
    // }

    React.useEffect(() => {
        // if session_id changes, then we need to make a query to get state
        if (session_id) {
            session_api.get(`/${session_id}/update`).then(({ data }) => {
                let { current_state } = data.session || {}
                console.log("Current session info:", data)
                if (current_state == 'started') {
                    setSessionCheck(true)
                    setCurrentStep(0)
                    return
                }
                if (current_state == "crop") {
                    // go back to uploads for now, they get erased
                    current_state = "upload"
                }
                if (current_state == "started_payment") {
                    current_state = "payments"
                }
                if (current_state == "payment_complete") {
                    // oops, we should be on /dream/complete?session_id=...
                    // current_state = "payments"
                    // THE CORRECT APPROACH HERE, commentted for dev
                    // console.log("PAYMENT COMPLETE DEV MODE")
                    window.location.href = `/dream/complete?session_id=${session_id}`
                    return
                }
                if (current_state == "training_job_started" || current_state == "complete") {
                    // you shouldn't be here!
                    window.location.href = `/`
                    return
                }

                const step_index = views.findIndex((view) => view.name == current_state)
                if (step_index > -1) {
                    setCurrentStep(step_index)
                }
                setSessionCheck(true)
            })
        }
    }, [session_id])

    const getCurrentView = () => {
        const ViewContent = views[currentStep];
        return <ViewContent.content
            key={`view_${currentStep}`}
            sb_session={sb_session}
            handleLogin={handleLogin}
            loginDetails={loginDetails}
            handleNext={handleNext}
            handleBack={handleBack}
            data_state={stepsCompleted[ViewContent.name]}
            content_name={ViewContent.name}
            {...ViewContent.props}
        />
    }

    if (session_id != null && !sessionCheck) {
        return null
    }

    return (!isLoggedIn && session_id == null ? <Signup
        sb_session={sb_session}
        handleLogin={handleLogin}
        loginDetails={loginDetails}
        handleNext={handleNext}
        handleBack={handleBack}
        data_state={signup_data}
    /> : getCurrentView())
}

export default Onboarding;