import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { getBaseUrl } from '../supastore';


const signup_api = axios.create({ baseURL: `${getBaseUrl()}/api/signup` })


export default function Waitlist({ sx = {} }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const [textEmail, setTextEmail] = useState("");
  const [textReason, setTextReason] = useState("");
  const [textURLs, setTextURLs] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(event.currentTarget);
    const form = event.currentTarget;
    // get email 
    const email = data.get('email');
    const reason = data.get('reason');
    const websites = data.get('websites');

    // post to waitlist 
    signup_api.post('/waitlist', { email, reason, websites }).then(() => {
      console.log("Success, joined the waitlist with email: ", email);
      enqueueSnackbar(`Joined the waitlist with: ${email}!`, { variant: "success" });
      // clear the text plz 
      // setTextEmail("")
      // console.log("cur target:", form);
      form.reset();

    }).catch((error) => {
      // console.log("Error joining the waitlist: ", error);
      if (error.response.status == 409) {
        const msg_error = error.response.data.error
        enqueueSnackbar(`${email} error: ${msg_error}!`, { variant: "error" });
      }
      else
        enqueueSnackbar(`Error joining the waitlist: ${error.response.data.error}`, { variant: "error" });
    })

    // if (!data.get('agreedToTerms')) {
    //   enqueueSnackbar("You must agree to our terms and privacy policy to create an account", { variant: "error" });
    //   return;
    // }
    // setIsLoggingIn(true);
    // const finishSignUp = finishSignUpFct || ((data, setIsLoggingIn) => {
    //   setIsLoggingIn(false)
    //   if (data.user && data.session) {
    //     navigate("/");
    //   } else {
    //     enqueueSnackbar("Trouble logging in...", { variant: "error" });
    //     // setErrorMessage("Trouble logging in...");
    //   }
    // })
    // const failSignUp = failSignUpFct || ((error, setIsLoggingIn) => {
    //   setIsLoggingIn(false);
    //   enqueueSnackbar("Error: " + error.message, { variant: "error" });
    // })
    // const signUp = signUpFct || signupAsUser
    // signUp(data.get('username'), data.get('email'), data.get('password'), data.get('agreedToTerms')).then(
    //   (data) => {
    //     finishSignUp(data, setIsLoggingIn)
    //   }).catch(err => {
    //     failSignUp(err, setIsLoggingIn)
    //     // setErrorMessage(err.message);
    //   })
  };

  return (
    <Container maxWidth="xs" sx={{alignItems: "center"}}>
      <Stack spacing={1}>
        <Box
          sx={{ margin: '0 auto' }}
          onClick={() => { navigate("/") }}
        >
          <img style={{objectFit: "cover", width: "120px", height: "120px"}} alt="logo" src="/cheese_logo_shadow.png" />
        </Box>
        <Typography component="h4" variant="h4">
          Cheese is in invite-only beta
        </Typography>
        <Typography component="p" variant="body1">
          Join the waiting list.
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Email
              </Typography>
              <TextField
                sx={{'.MuiInputBase-input': {pt: 1}}}
                required
                fullWidth
                defaultValue={textEmail}
                onChange={(e) => setTextEmail(e.target.value)}
                id="email"
                name="email"
                autoComplete="email"
                placeholder='bigcheese@gouda.biz'
                variant="filled"
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Why do you want to Cheese?
              </Typography>
              <TextField
                sx={{'.MuiInputBase-root': {pt: 1}}}
                required
                fullWidth
                defaultValue={textReason}
                onChange={(e) => setTextReason(e.target.value)}
                id="reason"
                placeholder='Tell us more about how you want to use Cheese'
                name="reason"
                multiline
                rows={2}
                variant="filled"
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                What are your URLs (social, portfolio, etc.)?
              </Typography>
              <TextField
                sx={{'.MuiInputBase-root': {pt: 1}}}
                required
                fullWidth
                defaultValue={textURLs}
                onChange={(e) => setTextURLs(e.target.value)}
                id="websites"
                placeholder={"https://importantartist.org, www.twitter.com/famousphotos4u"}
                name="websites"
                variant="filled"
                multiline
                rows={2}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: theme.palette.cheese.main,
                  color: theme.palette.cheese.color,
                  '&:hover': {
                    backgroundColor: theme.palette.cheese.hover,
                  },
                }}
                disabled={isSubmitting}
              >
                Get Cheesy
              </Button>
            </Grid>
          
            <Grid item xs={12}>
              <Button onClick={() => navigate('/login')} variant="body2" sx={{ textTransform: "none", display: "float", float: "right" }}>
                I have an account!
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      </Container>
  );
}