import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Paper from '@mui/material/Paper';
import { useAdminAuthSession, getAdminUIStateStore, getKeyedStore } from "../supastore"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { SquishIcon } from '../styled/motion';
import _ from 'lodash';
import { styled, useTheme } from "@mui/material/styles";

const indexPath = {
  0: { pathname: '/', tabName: "home", index: 0 },
  1: { pathname: '/create', tabName: "create", index: 1 },
  2: { pathname: '/me', tabName: "me", index: 2 },
  // 3: { pathname: '/admin' },
}
const bottomNavStore = getKeyedStore("bottomNavStore", { selectedIndex: -1, tabName: "none" })

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  margin: 8,
  alignItems: "center",
  justifyContent: "center",
  "&.MuiBottomNavigationAction-iconOnly": {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    paddingBottom: 12,
    ".MuiBottomNavigationAction-label": {
      width: 0,
    }
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    borderRadius: 8,
    backgroundColor: 'white',
    ".MuiBottomNavigationAction-label": {
      marginLeft: "5px",
      fontWeight: 600,
    }
  },
}));

const BottomNav = ({ selectedIndex = 0 }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(selectedIndex);
  const theme = useTheme();

  const location = useLocation();
  // console.log("Location:", location)
  // get selected index from location
  const currentPath = _.first(_.filter(indexPath, (path) => { return path.pathname == location.pathname }))
  // console.log("Current index:", currentPath)

  // we are somewhere on the tab we recognize? but we don't have correct selection, fix it
  if (currentPath && bottomNavStore.selectedIndex != currentPath.index) {
    bottomNavStore.selectedIndex = currentPath.index
    bottomNavStore.tabName = currentPath.tabName
  }

  // const { is_admin } = useAdminAuthSession()
  // const admin_ui = getAdminUIStateStore()
  // if (value == 3) {
  //   admin_ui.admin_tab = true
  // }
  const handleChange = (event, newValue) => {
    // skip if the same plz
    // if (newValue == value) {
    //   return
    // }
    // console.log("Admin tab change: ", newValue)
    const shouldReplace = newValue === bottomNavStore.selectedIndex;
    setValue(newValue);
    bottomNavStore.selectedIndex = newValue
    bottomNavStore.tabName = indexPath[newValue].tabName
    console.log("Bottom nav store:", bottomNavStore, shouldReplace)
    if (shouldReplace) {
      navigate(0);
    } else {
      navigate(indexPath[newValue].pathname)
    }
    // switch (newValue) {
    //   case 0:
    //     bottomNavStore.tabName = "home"
    //     navigate({ pathname: '/' });
    //     // admin_ui.admin_tab = false
    //     break;
    //   case 1:
    //     bottomNavStore.tabName = "create"
    //     navigate({ pathname: '/create' });
    //     // admin_ui.admin_tab = false
    //     break;
    //   case 2:
    //     bottomNavStore.tabName = "me"
    //     navigate({ pathname: '/me' });
    //     // admin_ui.admin_tab = false
    //     break;
    //   // case 3:
    //   //   navigate({ pathname: '/admin' });
    //   //   admin_ui.admin_tab = true
    //   //   break;
    //   default:
    //     break;
    // }
  };
  // console.log("Admin: ", is_admin)
  return (
    <Paper sx={{ position: 'fixed', width: "100%", bottom: 0, left: 0, right: 0, px: 0.5, pb: 0.5, background: theme.palette.primary.main,  }} elevation={3} component="footer">
      <BottomNavigation value={value} onChange={handleChange}>
        <StyledBottomNavigationAction
          label="Home"
          value={0}
          icon={value != 0 ? <SquishIcon icon={<HomeOutlinedIcon />} /> : <HomeOutlinedIcon />}
        />
        <StyledBottomNavigationAction
          label="Create"
          value={1}
          icon={value != 1 ? <SquishIcon icon={<AddPhotoAlternateOutlinedIcon />} /> : <AddPhotoAlternateOutlinedIcon />}
        />
        <StyledBottomNavigationAction
          label="My cheese"
          value={2}
          icon={value != 2 ? <SquishIcon icon={<AccountCircleOutlinedIcon />} /> : <AccountCircleOutlinedIcon />}
        />
        {/* {is_admin && <BottomNavigationAction
          label="Admin"
          value={3}
          icon={<AdminPanelSettingsIcon />}
        />} */}
        {/* <BottomNavigationAction
          label="Notification"
          value={3}
          icon={<NotificationsOutlinedIcon />}
        /> */}
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;