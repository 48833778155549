import React, { useState } from 'react';
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import { getAuthData } from '../store'
import { useSnackbar } from 'notistack';
import OnboardingLayout from './layout';
import Signup from "../../../login/signup"
import Landing from "./Landing";

const SignupPage = ({ children, handleNext, handleBack, stepIndex, maxSteps,
    loginDetails, handleLogin,
    data_state, sb_session, setSuccessMsg, setErrorMsg }) => {
    const auth = getAuthData()
    console.log(sb_session, loginDetails);

    const isLoggedIn = (sb_session && !(sb_session.expires_at < new Date() / 1000)) || false

    const [isFinished, setIsFinished] = useState(false);
    data_state.completed = isFinished || isLoggedIn;
    const { enqueueSnackbar } = useSnackbar();

    return <OnboardingLayout>
        <Signup
            sx={{ marginTop: 2 }}
            // causes skip ahead to the text field, that's a no no! 
            autoFocus={false}
            finishSignUpFct={async (data, setIsLoggingIn) => {
                loginDetails.signUpLoading = false
                loginDetails.successfulSubmit = true
                data_state.completed = true
                setIsLoggingIn(false)
                enqueueSnackbar("Account created, please check your email to verify your account!", { variant: "success" });

                // let's move on!
                setIsFinished(true)
                // handleNext()
            }}
            failSignUpFct={async (error, setIsLoggingIn) => {
                loginDetails.signUpLoading = false
                setIsLoggingIn(false)
                console.log("Error response: ", error)
                const msg = error && error.response && error.response.data && error.response.data.error ? error.response.data.error.message : "Unkwown"
                enqueueSnackbar(`Signup error: ${msg}`, { variant: "error" });
            }}
            signUpFct={async (username, email, password, acceptedTerms) => {
                // set the login details from data
                loginDetails.username = username
                loginDetails.email = email
                loginDetails.password = password
                loginDetails.acceptedTerms = acceptedTerms
                loginDetails.signUpLoading = true
                return handleLogin()
            }}
            disableSignUpButton={true}
        />
    </OnboardingLayout>
}

export default SignupPage