import React, { useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from '@mui/material/Grid';
import QueueCard from './queueCard';

const InfiniteQueueList = ({ scrollableTarget = null, data, fetchMore, hasMore, isActive = true }) => {

  useEffect(() => {
    fetchMore();
  }, [])

  console.log("New Queue Inf Data;", data)
  return (
    <InfiniteScroll
      scrollableTarget={scrollableTarget}

      dataLength={data.length}
      next={() => { if (isActive) { fetchMore() } }}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      useWindow={!scrollableTarget}
    >
      <Grid container width="100%" spacing={2}>
        {data.map((dd, idx) => {
          // console.log("DD:", dd)
          return (
            <Grid item key={`queue_${idx}`} xs={12}>
              <QueueCard queueData={dd} />
            </Grid>
          )
        })}
      </Grid>
    </InfiniteScroll>
  );
};

export default InfiniteQueueList;
