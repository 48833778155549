import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { signinWithUser, useAuthSession, getBaseUrl, supabase } from "../supastore"
import { useSnackbar } from "notistack";


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cheese.baby/">
        AI Funhouse
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function Login() {
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const auth = useAuthSession()
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = React.useState("");
  const emailRef = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsLoggingIn(true);
    signinWithUser(data.get('email'), data.get('password')).then(
      (data) => {
        setIsLoggingIn(false);
        if (data.user && data.session) {
          navigate("/");
        } else {
          enqueueSnackbar("Trouble logging in...", { variant: "error" });
        }
      }).catch(err => {
        setIsLoggingIn(false);
        enqueueSnackbar("Error: " + err.message, { variant: "error" });
      })
  };

  // console.log("Auth data:", auth)

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img className='logo' alt="logo" src="/cheese_logo_shadow.png" />
        <Typography component="h1" variant="h5">
          Sign in ?
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type={"email"}
            inputRef={emailRef}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="email"
            inputProps={{ type: "email" }}
            autoFocus
            disabled={isLoggingIn}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ type: "password" }}
            disabled={isLoggingIn}
          />
          <Button
            type="submit"
            fullWidth
            disabled={isLoggingIn}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Button onClick={() => {
                // send reset password email plz
                if (email == "" || email == null) {
                  enqueueSnackbar("Please enter your email address to reset password", { variant: "error" });
                  return
                }
                supabase.auth.resetPasswordForEmail(email, {
                  redirectTo: `${getBaseUrl()}/settings`,
                }).then(({ data, error }) => {
                  console.log("Resst password data:", data, error)
                  if (error) {
                    enqueueSnackbar("Error: " + error.message, { variant: "error" });
                  }
                  else {
                    console.log("Reset password email sent!")
                    enqueueSnackbar(`Reset password email sent to ${email}!`, { variant: "success" });
                  }
                })

              }} variant="body2" sx={{ textTransform: "none" }}>
                Forgot password?
              </Button>
            </Grid>
            <Grid item xs>
              {/* DO NOT CHANGE ME TO navigate() instead of window.location.href currently unless there is a fix for cloudflare pages auto-creating session_id */}
              <Button onClick={() => { navigate('/signup') }} variant="body2" sx={{ textTransform: "none" }}>
                First time here? Sign Up
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ paddingTop: 2, alignItems: "center", textAlign: "center" }}>
          <Typography variant="subtitle1">
            <Link href="/terms" style={{ textDecoration: 'none' }}>Terms of Service</Link>
            {" • "}
            <Link href="/privacy" style={{ textDecoration: 'none' }}>Privacy Policy</Link>
          </Typography>
        </Box>
      </Box>
      <Copyright sx={{ my: 4 }} />
    </Container>
  );
}