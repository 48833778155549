import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { ExpositionText, AccentExpositionText, UploadBox } from "./styled"
import { useNavigate } from 'react-router-dom';
import OnboardingLayout from './layout';
import { useSnackbar } from 'notistack';

// TODO: Move success to /dream/complete -- and call our backend at /api/dream/${session_id}/verify
// That will return {email_status: "verified", user_id: "{uuid}"} if the session is verified, and {email_status: "unverified"} if not
// and it will return {payment_status: "complete"} if the payment is completed, and {payment_status: "incomplete", checkout_url: "stripe url"} if not
// finally, success will display two buttons if not verified and incomplete, one for each call to fix it 
// (verify email via magic link), (complete payment via stripe redirect to same checkout url)
// and potentially 1 button for contacting support for common issues
// if everything is verified, success has a button, that button starts the training process by making a post call
// to /api/dream/${session_id}/${user_id}/train -- this will initiate the model training process. 

const Success = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const getFooter = () => {
        return <Button
            sx={{ width: "90vw", maxWidth: "1000px", textTransform: "none" }}
            variant="contained"
            size="large"
            onClick={() => {
                enqueueSnackbar("Let's go! Make sure you verify your email to get cheesin'!", { variant: "success" });
                navigate("/");
            }}
        >
            TO THE FEED!
        </Button>
    }

    return (
        <OnboardingLayout footerComponent={getFooter()} title="SUCCESS!!">
            <ExpositionText>
                Now we mature your Cheese!
            </ExpositionText>
            <ExpositionText>
                This normally takes between 60-90 minutes.
            </ExpositionText>
            <ExpositionText>
                You'll get an email when your Cheese is ready and it's time to get at it!
            </ExpositionText>
            <ExpositionText>
                Meanwhile, Cheese the feed and get ready
            </ExpositionText>
            <ExpositionText>
                ...
            </ExpositionText>
            <Box sx={{ width: "100%", height: "auto", overflow: "hidden" }}>
                <img src="/cheese_social_preview_tall.jpg" alt="preview"
                    style={{ width: "100%", objectFit: "fill" }} />
            </Box>
        </OnboardingLayout>
    );
}

export default Success;