import React from 'react';
import Login from "../components/login/login"
import { FullContainer } from "../components/styled/containers"

const MainEntry = ({ }) => {
    // let params = useParams();
    // we'll need to get auth data 
    return <FullContainer>
        <Login />
    </FullContainer>
}

export default MainEntry