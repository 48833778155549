import React from 'react';
import { useLocation } from 'react-router-dom';
import TitleBar from "../components/titleBar";
import ResultDetailView from "../components/lexicaSearch/views/resultDetailView";
import { FullContainer } from "../components/styled/containers";

const MainEntry = ({ }) => {
  // we should use location for query BUT get details from a DB
  // need to be able to reload images without regenerating
  const loc = useLocation();
  const query = loc.state.query ?? "";
  const searchResult = loc.state.item;

  return <FullContainer>
    <TitleBar initialQuery={query} />
    <ResultDetailView resultItem={searchResult} />
  </FullContainer>
}

export default MainEntry