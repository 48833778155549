import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { signupAsUser, useAuthSession } from "../supastore";
import { useSnackbar } from 'notistack';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://cheese.baby/">
        cheese.baby
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function SignUp({ finishSignUpFct, failSignUpFct, signUpFct, signUpLabel, sx = {}, autoFocus = true, disableSignUpButton = false }) {
  const navigate = useNavigate();

  // check for a code, if it exists, off we go to 
  const searchParams = useSearchParams();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const auth = useAuthSession()
  const { enqueueSnackbar } = useSnackbar();
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  signUpLabel = "Sign Up" || signUpLabel
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get('agreedToTerms')) {
      enqueueSnackbar("You must agree to our terms and privacy policy to create an account", { variant: "error" });
      return;
    }
    setIsLoggingIn(true);
    const finishSignUp = finishSignUpFct || ((data, setIsLoggingIn) => {
      setIsLoggingIn(false)
      if (data.user && data.session) {
        navigate("/");
      } else {
        enqueueSnackbar("Trouble logging in...", { variant: "error" });
        // setErrorMessage("Trouble logging in...");
      }
    })
    const failSignUp = failSignUpFct || ((error, setIsLoggingIn) => {
      setIsLoggingIn(false);
      console.log("Error: ", error.message)
      enqueueSnackbar("Error: " + error.message, { variant: "error" });
    })
    const signUp = signUpFct || signupAsUser
    signUp(data.get('username'), data.get('email'), data.get('password'), data.get('agreedToTerms')).then(
      (data) => {
        finishSignUp(data, setIsLoggingIn)
      }).catch(err => {
        failSignUp(err, setIsLoggingIn)
        // setErrorMessage(err.message);
      })
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...sx,
        }}
      >
        <img className='logo' alt="logo" src="/cheese_logo_shadow.png" />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="username"
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus={autoFocus}
                disabled={isLoggingIn}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                disabled={isLoggingIn}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                disabled={isLoggingIn}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox id="agreedToTerms" name="agreedToTerms"
                    onChange={(val) => setAcceptedTerms(val.target.checked)}
                    required color="primary" />
                }
                label={
                  <div>
                    <span>I accept the </span>
                    <Link to={'/terms'} onClick={(e) => {
                      e.preventDefault();
                      navigate("/terms");
                    }}>Terms of Service</Link>
                    <span> and </span>
                    <Link to={'/privacy'} onClick={(e) => {
                      e.preventDefault();
                      navigate("/privacy");
                    }}>Privacy Policy</Link>
                  </div>
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoggingIn || !acceptedTerms}
          >
            {signUpLabel}
          </Button>
          {!disableSignUpButton && <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={() => navigate('/login')} variant="body2" sx={{ textTransform: "none" }}>
                Already have an account? Sign in
              </Button>
            </Grid>
          </Grid>
          }
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}