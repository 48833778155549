import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { copyCleanPromptForUI } from '../../helpers/inference'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


export default function QueueCard({ queueData }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log("Queue data:", queueData)
  const { queue_job_data } = queueData
  // convert from base64 string to json
  const queue_data = JSON.parse(atob(queue_job_data))
  // console.log("Queue data:", queue_data)
  const prompt = (queue_data && queue_data.prompts) ? copyCleanPromptForUI(queue_data.prompts[0]) : ""
  // console.log("Prompt:", queue_data, prompt)
  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant='h6' sx={{ flexGrow: 1, flexShrink: 0 }}>
                {queueData.queue_job_type === "inference" ? "Making Images" : "Getting Set Up"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LinearProgressWithLabel sx={{ flexGrow: 3 }} value={queueData.queue_progress} />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{
            width: "100%",
          }}>
            <Typography>
              {prompt}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}