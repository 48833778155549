import React from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SearchResultCard from './searchResultCard';
import InfiniteImageList from "../../styled/infiniteImageList"
import { SquishButton } from "../../styled/motion"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from "notistack"
import { useTheme } from '@mui/material/styles';

const convertTemplate = (template) => {
  let repl_temp = template.replace('<sks_person>', 'me');
  const temp_matches = repl_temp.match(/\b[Mm][Ee]\b/g)
  const has_at_least_one_me = temp_matches && temp_matches.length > 0
  if (!has_at_least_one_me)
    repl_temp = "me, " + repl_temp

  // remove empty spaces
  return repl_temp.trim()
}

const onCopyClick = ({ prompt, setIsFetching, enqueueSnackbar }) => {
  setIsFetching(true);
  // setCustomPrompt("");
  console.log("Prompt to be copied:", prompt)
  const url_encode_prompt = encodeURIComponent(prompt);
  const prompt_url = `https://prompttotemplateapi.eerac.repl.co/templatize?sd_prompt=${url_encode_prompt}`

  if (typeof window.ClipboardItem && navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    //   by a direct user interaction. You can't use it async in a promise.
    //   But! You can wrap the promise in a ClipboardItem, and give that to
    //   the clipboard API.
    //   Found this on https://developer.apple.com/forums/thread/691873
    console.log("URL: :", prompt_url)

    //  // console.log("Prompt:", prompt.trim())
    //  const text = new window.ClipboardItem({ "text/plain": prompt.trim() });

    //  navigator.clipboard.write([text]).then(() => {
    //      enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success" });
    //  })
    //      .catch((err) => {

    //          if (!copy(prompt.trim())) {
    //              enqueueSnackbar("Error: " + err, { variant: "error" });
    //          }
    //          else
    //              enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success" });

    //      });
    const text = new window.ClipboardItem({
      "text/plain": fetch(prompt_url)
        .then(response => response.json())
        .then(data => {
          console.log("DATA: ", data)
          setIsFetching(false);
          const r = convertTemplate(data.sd_template)
          // setCustomPrompt(r);
          console.log("R:", r)
          return new Blob([r], { type: "text/plain" })
        }).catch(err => {
          console.error("Error: ", err)
          setIsFetching(false);
          enqueueSnackbar("Error: " + err, { variant: "error" });
        })
    });

    navigator.clipboard.write([text]).then(() => {
      enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success", anchorOrigin: { horizontal: "left", vertical: "top" } });
    })
      .catch((err) => {
        enqueueSnackbar("Error: " + err, { variant: "error" });
      });
  }
  else {
    // console.log("NOT HERE")
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    //   Good news is that other than Safari, Firefox does not care about
    //   Clipboard API being used async in a Promise.
    fetch(prompt_url)
      .then(response => response.json())
      .then(data => {
        setIsFetching(false);
        const r = convertTemplate(data.sd_template)
        console.log("R:", r, "data", data)
        // setCustomPrompt(r);
        navigator.clipboard.writeText(r)
          .then(() => {
            enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success", anchorOrigin: { horizontal: "left", vertical: "top" } });
          })
          .catch((err) => {
            enqueueSnackbar("Error: " + err, { variant: "error" });
          });
      });
  }
}

const SearchResultList = ({ items, itemStore, onAction, fetchMore,
  hasMore, isActive }) => {
  const theme = useTheme()
  const setIsFetching = (fetch) => {
    console.log("Fetch?", fetch)
  }
  const { enqueueSnackbar } = useSnackbar()
  // console.log("Search Results:", items)
  // return <Box sx={{
  //     width: "100%",
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     paddingTop: "10px"
  //   }}>
  //     </Box>
  const actionClick = ({ action_name, img_data, ...props }) => {
    console.log("Click!", action_name)
    if (action_name == "copy") {
      // let's copy!
      onCopyClick({ prompt: img_data.data.prompt, setIsFetching, enqueueSnackbar })
    }

  }
  const getActions = (action) => {
    return [
      <SquishButton key="copy" size="small" color="white" startIcon={<ContentCopyIcon />}
        onClick={() => action({ action_name: "copy" })} >
        Copy for Me
      </SquishButton>
    ]
  }

  return (
    <Box sx={{
      pt: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

    }}>
      {/* {!items.length ?
        <Typography variant="h6">No Results</Typography>
        :
        <Typography variant="h6">{items.length} Results</Typography>
      } */}
      <Box sx={{
        py: 5,
      }}>
        <InfiniteImageList
          sx={{
          }}
          images={items}
          imageData={itemStore.items}
          actionClick={actionClick}
          getActions={getActions}
          showCopy={false}
          fetchMore={fetchMore}
          hasMore={hasMore}
          showActions={true}
          showUsers={false}
          isActive={isActive}
        />
      </Box>
    </Box>

  );

  // return (
  //   <Box sx={{
  //     width: "100%",
  //     display: "flex",
  //     flexDirection: "column",
  //     alignItems: "center",
  //     justifyContent: "center",
  //     paddingTop: "10px"
  //   }}>
  //     {!items.length ?
  //       <Typography variant="h6">No Results</Typography>
  //       :
  //       <Typography variant="h6">{items.length} Results</Typography>
  //     }
  //     <Grid container
  //       spacing={1}
  //       sx={{
  //         justifyContent: "center",
  //       }}
  //     >
  //       {items.map((item, idx) =>
  //         <Grid item key={idx} xs={6} md={4} lg={3} >
  //           <SearchResultCard item={item} />
  //         </Grid>
  //       )}
  //     </Grid>
  //   </Box>
  // )
}


export default SearchResultList;