import React from 'react';
import TitleBar from "../components/titleBar"
import Box from '@mui/material/Box';
import MuiMarkdown from 'mui-markdown';
import Typography from '@mui/material/Typography';
import { FullContainer } from "../components/styled/containers"

const terms_markdown = `
### About Cheese.baby

Cheese.baby is owned by Aifunhouse.com, a Delaware Corporation, is a service to identify and compare environmentally friendly product listings to those found on eCommerce sites.

#### Terms of Use

These Terms of Use (the “Terms of Use”) set forth the terms and conditions that apply to your access and use of Cheese.baby located at [Cheese.baby](https://Cheese.baby) its related applications and any other online services such as our mobile apps or browser extensions (collectively, the “Service” or “Services”) provide by Aifunhouse.com and Cheese.baby

By accessing or using our Services or accessing any information or material that is made available by AI Funhouse through any of the Services (the “Content”), you are entering into a binding contract with AI Funhouse. When used in these Terms of Use, the terms "we", “us” and "our" mean AI Funhouse.

Your contract with us includes these Terms of Use, along with any rules and policies posted on our website from time-to-time and our AI Funhouse Privacy Policy located at [Cheese.baby/privacy_policy.html](https://Cheese.baby/privacy_policy.html) (collectively, the “Agreements”).

The Service also includes services that may be offered by AI Funhouse or Cheese.baby in the future. AI Funhouse and Cheese.baby reserve the right to change, suspend and discontinue any aspect of the Services at any time including, but not limited to, the nature of the Services offered, databases, hours of availability, and software needed for access or use. The Services are available only to, and may only be used by, individuals and entities able to form legally binding contracts under applicable law. You must be 18 or older in order to use the Service and access the Content, (or 13 or older if you have the consent of your parent or guardian). If you are under the age of 13, you are not permitted to use the Services. If you are acting on behalf of any entity or organization, you warrant and represent that you have the authority to bind the entity to these Terms of Use. AI Funhouse reserves the right to permit or restrict any user’s access to the Services in its sole and absolute discretion.

PLEASE BE AWARE THAT THESE TERMS OF USE CONTAIN:

  1. AN ARBITRATION PROVISION;
  2. A WAIVER OF RIGHTS TO BRING A CLASS ACTION AGAINST US; AND
  3. A RELEASE BY YOU OF ALL CLAIMS FOR DAMAGE AGAINST US THAT MAY ARISE OUT OF YOUR USE OF THE SERVICE. BY USING THE SERVICE, YOU AFFIRM THAT YOU AGREE TO THESE PROVISIONS.

#### Privacy Policy
For more information about AI Funhouse’s Privacy Policy, please read AI Funhouse’s Privacy Policy found at [Cheese.baby/privacy_policy.html](Cheese.baby/privacy_policy.html)

#### AI Funhouse Intellectual Property

Cheese and AI Funhouse and third-party trademarks and service marks may or may not be designated as such from time-to-time through the SM, TM or ® symbols. You are not authorized to make any use of any names, logos or taglines, including without limitation “Cheese” or the the “Cheese” logo, “AI Funhouse”, the AI Funhouse Logo, “AI Funhouse for Chrome”, AI Funhouse for Chrome Logo, “AI Funhouse for Firefox”, and AI Funhouse for Firefox Logo whether or not designated by such symbols, including, but not limited to, as embedded search terms or metatags or in any other fashion without the express prior written permission of AI Funhouse.

Nothing herein grants you any right, title or interest in any AI Funhouse or Cheese names, trade names, trademarks, certification marks, service marks, taglines and logos, patents, patent applications, formulas, algorithms, methodologies, characters, technology or designs (whether or not the subject of a patent application) (together, “AI Funhouse IP”). At no time during or after the term of these Agreements will any user, either directly or through any third party or agent, (i) challenge or assist others to challenge the AI Funhouse IP or the registration thereof; (ii) attempt to register any designs, trademarks, marks, trade names, or trade dress confusingly similar to the AI Funhouse IP, or domain names incorporating any of the same; or (iii) incorporate any AI Funhouse IP into any third party designs, trademarks, product names, service marks, company names, domain names, or any other similar designations, without the prior written consent of the Company. If, at any time, you acquire any rights in, or domain name, trademark or service mark registrations or applications for, any AI Funhouse marks, names or designations by operation of law or otherwise, you will immediately upon request from AI Funhouse and at no expense to us assign such rights, registrations and applications to AI Funhouse.

#### Content

AI Funhouse and Cheese may present you with information, content and features, including but not limited to, graphic designs, photos, articles, commentary, statistics, data, venue information, advertisements, video and audio files, and other information; the Services may also include materials, software and features made available to you by virtue of a license, grant or some other form of agreement. AI Funhouse is not responsible for inaccurate information provided through the Services, makes no representations as to the quality, accuracy or completeness of Content and does not assume any liability for any loss that may result from the reliance by any person upon any Services.

#### Linking

Unless otherwise set forth in a written agreement between you and AI Funhouse, you may link to our Services, provided that you adhere to the following linking policy: (i) any link to the Services must be a link clearly marked “Cheese”; (ii) the link must point to the URL [Cheese.baby](https://Cheese.baby) or to the page of the Services on which the results of an analysis of a product, service or business performed through the Services are located; (iii) the appearance, position and other aspects of the link may not be such as to damage or dilute the goodwill associated with AI Funhouse's or Cheese’s name, trademarks, or certification marks; (iv) the appearance, position and other attributes of the link may not cor reate the false appearance that your organization or entity is sponsored by, affiliated with, or associated with AI Funhouse or Cheese; (v) when selected by a user, the link must display the Services on full-screen and not within a “frame” on the linking site; and (vi) AI Funhouse reserves the right to revoke its consent to the link at any time and in its sole discretion.

#### Prohibited Uses

Illegal and/or unauthorized uses of the Services, including without limitation unauthorized scraping of information provided through the Services, may be investigated and appropriate legal action will be taken, including without limitation, civil, criminal, and injunctive redress. You agree that you will not use the Services or Content to violate any law, statute, or regulation, including without limiting those governing export control, consumer protection, unfair competition, anti-discrimination, or false advertising. Your use of the Services is subject to our permission, which may be revoked at any time, for any reason, or for no reason, in our sole discretion.

#### Ownership and Use of Data

Except as otherwise set forth herein, as between you and AI Funhouse, all AI Funhouse Content and Cheese content is owned by AI Funhouse and is protected by worldwide copyright laws and treaty provisions, unless otherwise indicated. Nothing in these Terms of Use shall be interpreted as granting you any license or intellectual property rights in any Content.

AI Funhouse and Cheese do not assert ownership over any Profile Information; rather, as between us and you, subject to the rights granted to us under these Terms of Use, you retain full ownership of your Profile Information.

Any user generated data (“User Data”) will be stored on AI Funhouse or Cheese servers and will be owned by you during the duration of your use. However, you hereby grant AI Funhouse and Cheese a worldwide, royalty free, and perpetual license to use your User Data for our own internal purposes which includes, but is not limited to, AI Funhouse and Cheese improvements and security. Under no circumstances will AI Funhouse sell your User Data to a third-party.

#### Limitation of Use. You agree that you will neither post nor submit any User Generated Content that:
 
* is unlawful, threatening, obscene, vulgar, pornographic, profane or indecent, including any communication that constitutes (or encourages conduct that would constitute) a criminal offense, gives rise to civil liability or otherwise violates any local, state, national or international law;

* violates the copyright, trademark or other intellectual property rights of any other person or AI Funhouse or Cheese;

* is false, inaccurate or misleading or improperly assumes or claims the identity, characteristics or qualifications of another person;

* collects or solicits personal information from other users;

* imposes an unreasonable or disproportionately large load on any infrastructure supporting the Services;

* is for the purpose of spamming or promoting goods or services without our prior authorization;

* is fraudulent or involves the distribution or sale of illegal, counterfeit, or stolen items;

* contains any virus, Trojan horse, worm, time bomb, cancelbot, or other computer programming routines that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information, or other harmful component; or

* is libelous or impacts or invades the privacy or publicity rights of others, such as photographs, video clips, personally identifiable information, or other materials that reveal personal, private or sensitive information about another person, without that person’s consent.

Furthermore, you acknowledge and agree that you will not: (i) conduct yourself in any manner that may result in disputes, arguments or altercations with other users of the Services or any third parties that you encounter as a result of using the Services; (ii) stalk, intimidate, threaten or otherwise harass or cause discomfort to any other user; (iii) post the contact information of anyone else, including but not limited to phone numbers, email address, home or work addresses, and social media accounts without their permission; (iv) collect or store personal data about other users of the Services, including collecting user names or phone numbers of users by electronic or other means for the purpose of sending unsolicited messages; (v) upload, e-mail or otherwise transmit any Content, including User Generated Content, or Third Party Materials that contains viruses or any other computer code, files or programs that might interrupt, limit or interfere with the functionality of any computer software, hardware, database or file that is owned, leased or used by AI Funhouse, the Services, their users or any third party; (vi) reverse engineer, decompile, copy or adapt any software, algorithms or other code or scripts forming part of the Services; (vii) submit unwanted messages by e-mail or otherwise or post User Generated Content that disparages or insults any third party or AI Funhouse; (viii) impersonate any person or entity, or falsify or otherwise misrepresent yourself or your affiliation with any person or entity, whether by providing or omitting to provide information; (ix) hold yourself out as an agent, representative, employee or affiliate of AI Funhouse; or (x) solicit, recruit, or offer services other than intended by the Services.

#### Reporting Misconduct

AI Funhouse encourages you to report any misconduct on our Services to [contact@Cheese.baby](mailto:contact@Cheese.baby) with the subject “User Misconduct”.

#### Minors

You must be at least 13 years old to use the Service. The Service complies with the rules of the Children’s Online Privacy Protect Act (“COPPA”). COPPA applies to the online collection of personal information from children under 13. Accordingly, the Service does not permit anyone under the age of 13 to use the Service.  In addition, the Service does not knowingly collect or solicit any information from anyone under the age of 13. If the Service unknowingly collects personal information from a child under the age of 13 without parental consent, we will delete that information as soon as possible. Please contact us at [contact@Cheese.baby](mailto:contact@Cheese.baby) with the subject “COPPA” if you believe we might have any information from or about a child under 13.

#### Availability

Not all of the Services are available in all geographic areas, and AI Funhouse and Cheese reserve the right to restrict any user from the Services at any time, without notice. The Services are not directed at, or intended for distribution to or for use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or subject to regulation, or any non-United States jurisdiction that would subject AI Funhouse, its licensees or affiliates, to any registration requirement within such jurisdiction.

#### NO WARRANTY

THE SERVICES, FEATURES, PRODUCTS, MATERIALS, CONTENT AND WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, WHICH ARE HEREBY EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW. AI Funhouse SHALL NOT BE LIABLE TO ANY USER FOR ACTS OF FRAUD, THEFT, MISAPPROPRIATION, TAMPERING, HACKING, INTERCEPTION, PIRACY, MISUSE, MISREPRESENTATION, DISSEMINATION, OR OTHER ILLEGAL OR UNAUTHORIZED ACTIVITIES OF THIRD PARTIES. YOU UNDERSTAND AND EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY HARM TO YOU, YOUR PROPERTY OR ANY THIRD PARTY OR THIRD PARTY’S PROPERTY THAT RESULTS FROM YOUR USE OF THE SERVICES.

TO THE EXTENT THAT THE LAW OF YOUR JURISDICTION DOES NOT PERMIT THE DISCLAIMER OF WARRANTIES, THE SERVICES ARE WARRANTED ONLY TO THE MINIMUM EXTENT LEGALLY REQUIRED.

#### NO LIABILITY

AI Funhouse shall not be responsible for any damages that may arise in connection with your use of the Services or these Terms of Use.

YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO UNINSTALL ANY AI Funhouse SOFTWARE AND TO STOP USING THE SERVICES. WHILE AI Funhouse ACCEPTS NO RESPONSIBILITY FOR THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY, WITH RESPECT TO AI Funhouse, FOR ANY PROBLEMS OR DISSATISFACTION WITH THIRD PARTY APPLICATIONS OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP USING ANY SUCH THIRD PARTY APPLICATIONS.

IN USING THE SERVICES, YOU SPECIFICALLY AGREE THAT YOU WILL NOT HOLD AI Funhouse OR ITS OWNERS, EMPLOYEES, CONTRACTORS, ADVISORS, AGENTS OR LICENSEES LIABLE FOR ANY DAMAGES, INJURY OR LOSS, INCLUDING ANY DIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR LOST PROFITS, THAT MAY RESULT FROM THE USE OF, OR THE INABILITY TO USE THE SERVICES (OR ANY OTHER DATA, APPLICATION OR THIRD PARTY APPLICATION), OR OTHERWISE RELATING TO OR ARISING OUT OF THE WEBSITE OR CONTENT, WHETHER IN AN ACTION FOR BREACH OF CONTRACT, NEGLIGENCE, PERSONAL INJURY OR OTHERWISE. AI Funhouse SHALL NOT BE LIABLE EVEN IF IT, OR AN AUTHORIZED REPRESENTATIVE, HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. AI Funhouse’S MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SERVICES, OR THESE TERMS OF USE IS LIMITED TO THE AMOUNT PAID BY YOU TO AI Funhouse IN CONNECTION WITH YOUR USE OF THE SERVICES IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY.

#### Indemnification

To the fullest extent permitted by applicable law, you agree to indemnify and hold AI Funhouse and Cheese harmless from and against all damages, losses, and expenses of any kind (including reasonable attorney fees and costs) arising out of: (1) your breach of these Terms of Use; (2) any Profile Information; (3) any User Data; (4) any activity in which you engage on or through the Services; and (5) your violation of any law or the rights of a third party. The Services and all related products and features are designed for personal and legal uses only. It is your responsibility to comply with all national, state and local laws, rules and ordinances when using the Services. You covenant to cooperate fully in the defense of any claim.

#### Term & Termination

These Terms of Use shall commence when you first visit Cheese, download any of our applications that are powered by the Services, or use any feature incorporated into the Services and shall apply to all of your subsequent visits and uses, provided that nothing herein shall prohibit us from refusing or otherwise restricting access to any user for any reason at any time, in our sole discretion. Cheese may, with or without cause, immediately terminate these Terms of Use without notice, and deny you access to any Services in our sole discretion. Without limiting the foregoing, Cheese has the right to immediately terminate any passwords or accounts created by you in the event that you breach these Terms of Use or engage in conduct that Cheese, in its sole discretion, considers unacceptable, including but not limited to abuse of the Services, entering incorrect or inaccurate information, or any use of the Services that interferes with Cheese or any user. If your use of the Services is terminated, you will no longer be authorized to access the Services, or use any of the features we offer. Without limiting any other rights of AI Funhouse or Cheese hereunder, you understand and acknowledge that AI Funhouse, in its sole discretion, may pursue legal and equitable relief against you if you breach or threaten to breach these Terms of Use.

#### Changes

The Services and these Terms of Use are subject to change and updating by AI Funhouse, Cheese and our affiliates, licensees, partners, agents and representatives at any time without prior notice. Changes may include superseding terms of use. You should review these Terms of Use from time-to-time to be aware of any changes. Your continuing use of any Services constitutes your acceptance of any change or update, all of which shall become controlling when posted.

#### Applicable Law and Jurisdiction

Persons who access the Services do so at their own initiative and are responsible for compliance with applicable laws and regulations. The laws of the State of Delaware will govern these Terms of Use and our Privacy Policy, without regard to conflicts of law principles. Any and all claims and controversies arising out of and related to these Terms of Use and our Privacy Policy shall be settled in the courts of competent jurisdiction in Dover, Delaware. Any such claim or controversy shall be adjudicated on an individual basis and shall not be consolidated with a claim of any other party. The foregoing shall not preclude the Company from seeking injunctive relief in any court of competent jurisdiction located in other countries and jurisdictions for protection of AI Funhouse IP.

#### Dispute Resolution and Arbitration

If you are located in, are based in, have offices in, or do business in a jurisdiction in which this Section is enforceable, the following mandatory arbitration provisions apply to you:

You and AI Funhouse agree that any dispute, claim, or controversy between you and AI Funhouse arising in connection with or relating in any way to these Agreements or to your relationship with AI Funhouse as a user of the Services (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of the Agreements) will be determined by mandatory binding individual arbitration. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court (including attorney fees), except that the arbitrator may not award declaratory or injunctive relief benefiting anyone but the parties to the arbitration. This arbitration provision will survive termination of the Agreements.

Notwithstanding the paragraph above, you and AI Funhouse both agree that nothing herein will be deemed to waive, preclude, or otherwise limit either of our rights, at any time, to (1) bring an individual action in a U.S. small claims court, (2) pursue enforcement actions through applicable U.S. federal, state, or local agencies where such actions are available, (3) seek injunctive relief in a court of law, or (4) to file suit in a court of law to address intellectual property infringement claims.

If you are located in, are based in, have offices in, or do business in the United States, any arbitration between you and AI Funhouse will be finally settled under the Commercial Dispute Resolution Procedures and the Supplementary Procedures for Consumer Related Disputes of the American Arbitration Association (“AAA”) then in force (the “AAA Rules”). Any arbitration hearings will take place at a location to be agreed upon in New York, New York. If the arbitrator finds that either the substance of your claim or the relief sought in the arbitration demand is frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment of all fees will be governed by the AAA Rules. In such case, you agree to reimburse AI Funhouse for all monies previously disbursed by it that are otherwise your obligation to pay under the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.

If you are not located in, are not based in, do not have offices in, and do not do business in the United States, any arbitration between you and AI Funhouse will be finally settled under the Rules of Arbitration of the International Chamber of Commerce (the “ICC”) then in force (the “ICC Rules”) by one or more arbitrators appointed in accordance with the ICC Rules, as modified by these Terms of Use, and will be administered by the International Court of Arbitration of the ICC. Any arbitration will be conducted in the English language and the law to be applied in any arbitration shall be the law of the State of New York, without regard to choice or conflicts of law principles.

Any arbitration must be commenced by filing a demand for arbitration within ONE (1) YEAR after the date the party asserting the claim first knows or reasonably should know of the act, omission, or default giving rise to the claim; and there shall be no right to any remedy for any claim not asserted within that time period. If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law.

A party who intends to seek arbitration must first send a written notice of the dispute to the other, by certified mail or Federal Express (signature required), or in the event that we do not have a physical address on file for you, by electronic mail ("Notice"). AI Funhouse's address for Notice is [contact@AI Funhouse.com](mailto:contact@AI Funhouse.com). The Notice must (1) describe the nature and basis of the claim or dispute; and (2) set forth the specific relief sought ("Demand"). We agree to use good faith efforts to resolve the claim directly, but if we do not reach an agreement to do so within thirty (30) days after the Notice is received, you or AI Funhouse may commence an arbitration proceeding. All documents and information disclosed in the course of the arbitration shall be kept strictly confidential by the recipient and shall not be used by the recipient for any purpose other than for purposes of the arbitration or the enforcement of arbitrator’s decision and award and shall not be disclosed except in confidence to persons who have a need to know for such purposes or as required by applicable law. Except as required to enforce the arbitrator’s decision and award, neither you nor AI Funhouse shall make any public announcement or public comment or originate any publicity concerning the arbitration, including, but not limited to, the fact that the parties are in dispute, the existence of the arbitration, or any decision or award of the arbitrator.

#### No Agency

No agency, partnership, joint venture, employer-employee or franchiser-franchisee relationship is intended or created by these Terms of Use.

#### General

These Terms of Use set forth the entire understanding and agreement between you and AI Funhouse with respect to the subject matter hereof. If any provision or provisions hereof shall be held to be invalid, illegal, or unenforceable, the validity, legality, and enforceability of the remaining provisions shall not be in any way affected thereby. Except as described herein, you may not assign these Terms of Use without our explicit consent. You are responsible for fees associated with gaining access to the Services, including the fees associated with any equipment necessary to access the Internet and the fees charged by your internet service provider. The Services may not be maximized for use on all devices or in conjunction with third party software and operating systems.

The Services are maintained by AI Funhouse, all rights reserved.

#### COPYRIGHT NOTICE

AI Funhouse respects the rights of intellectual property owners. AI Funhouse’s and Cheese’s use of the name, image, or description of a product or service is solely for the purpose of referring to the actual product or service, or its source, for informational purposes in a manner that does not create the impressions of endorsement or sponsorship. Logos and product images are rendered in the most accurate and suitable quality available. In the event that a logo or image has been resized, every effort has been made to preserve its quality and appearance. AI Funhouse. Cheese and their Services use of any copyrighted or trademarked material constitutes “fair use” under United States copyright law.

If you believe that any Content infringes your intellectual property rights or other rights, or does not comply with these Terms of Use please contact us at [contact@Cheese.baby](mailto:contact@Cheese.baby) with the subject line “Copyright Notice”.

#### The Digital Millennium Copyright Act of 1998 (“DMCA”)

The DMCA provides recourse to those who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials hosted by us infringe your copyright, you may send us a notice requesting that we remove the material or block access to it. The notice must include the following information:

* Physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;

* Identification of the copyrighted work claimed to have been infringed (or if multiple copyrighted works located on the Services are covered by a single notification, a representative list of such works);

* Identification of the material that is claimed to be infringing or the subject of infringing activity and information reasonably sufficient to allow us to locate the material on the Services;

* The name, address, telephone number, and email address of the complaining party;

* A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner or the law; and

A statement that the information in the notification is accurate and is given under penalty of perjury, and that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.

If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet current statutory requirements imposed by the DMCA; please see https://www.copyright.gov for details. You can use email to send to us notices and counter-notices under the DMCA, and any other communications relating to these Terms of Use. You can send notices by email to [contact@AI Funhouse.com](mailto:contact@AI Funhouse.com) with the subject “DMCA”. We strongly recommend that you consult your legal advisor before filing a notice or counter-notice. Also, please be aware that penalties may apply for false claims under DMCA.

CALIFORNIA AND CALIFORNIA CONSUMER PRIVACY ACT (“CCPA”) PRIVACY RIGHTS AND DISCLOSURES

AI Funhouse is committed to protecting your privacy rights. If you are a California resident, you are entitled to additional privacy rights and disclosures under the CCPA. Please refer to Cheese’s privacy policy available at [Cheese.baby/privacy_policy.html](https://Cheese.baby/privacy_policy.html) all information related to your rights and disclosures under the CCPA when you use our Services.

#### EUROPEAN UNION GENERAL DATA PROTECTION REGULATION (“GDPR”)

AI Funhouse is committed to protecting your privacy rights. If you are a European Union resident, you are entitled to additional privacy rights and disclosures under GDPR. Please refer to Cheese’s privacy policy available at [Cheese.baby/privacy_policy.html](https://Cheese.baby/privacy_policy.html) for all information related to your rights and disclosures under GDPR when you use our Services.

#### General Release

In the event that you have a dispute with one or more users or a third party introduced through the Services, you release AI Funhouse, and our officers, directors, agents, subsidiaries, and employees, from any and all claims, demands, and damages, actual or consequential, of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. AI Funhouse expressly disclaims any liability that may arise between users of the Services or users and any third party.

If you are a California resident, you waive California Civil Code Section 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor."
`;

const MainEntry = ({ }) => {
    // let params = useParams();
    // we'll need to get auth data 
    return <FullContainer>
        <TitleBar />
        <Box sx={{p: 5}}>
            <Typography variant="h4" textAlign="center">Terms of Service</Typography>
            <Typography variant="subtitle1" textAlign="center">Effective Date: 11/30/22</Typography>
            <MuiMarkdown>{terms_markdown}</MuiMarkdown>
        </Box>
    </FullContainer>
}

export default MainEntry