import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { makeAspectCrop, centerCrop } from 'react-image-crop'
import Typography from '@mui/material/Typography';
import { ExpositionText, MediumTitle } from "./styled"
import OnboardingLayout from './layout';
import Modal from '@mui/material/Modal';
import { CropImageDisplay } from './helpers';
import { getImageData } from "../store";
import { useSnapshot } from "valtio"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import { CheckTypography } from "./helpers"
import { useSnackbar } from 'notistack';
import _ from "lodash"


const GridModal = ({ image, sx, crop, crop_proxy, open, ...props }) => {
    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "98%",
        // width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2.5,
        ...sx
    };

    return <Modal open={open} {...props} aria-labelledby="modal-noborder" sx={{
        // maxWidth: "md"
    }}
        onTouchStart={(e) => {
            e.stopPropagation()
        }}
        onMouseDown={(e) => {
            e.stopPropagation()
        }}
        componentsProps={{
            backdrop: {
                onTouchStart: (event) => {
                    // console.log("Backdrop touch:", event)
                    event.stopPropagation();
                },
                onMouseDown: (event) => {
                    // console.log("Backdrop down")
                    event.stopPropagation();
                },
                onClick: (event) => {
                    // console.log("Backdrop click")
                    // event.preventDefault();
                    event.stopPropagation();
                    // props.onClose()
                }
            }
        }}
        disableEscapeKeyDown={true}>
        <Box sx={style}>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flex: 1,
            }}>
                <MediumTitle >
                    Adjust to Frame You
                </MediumTitle>
                <CropImageDisplay
                    src={image.src} sx={{ maxHeight: "512px", maxWidth: "80%" }} crop={crop} crop_proxy={crop_proxy} active={open} />
                <ExpositionText id="modal-modal-description" sx={{ mt: 2, textAlign: 'left' }}>
                    TIPS:
                </ExpositionText>
                <ExpositionText sx={{}} >
                    <Stack>
                        {_.map(["Capture your face in frame", "Don't zoom too tight", "Crop out other people"], (text, index) => {
                            return <CheckTypography key={`check_${index}`} text_type="div">{text}</CheckTypography>
                        })}
                    </Stack>
                </ExpositionText>
                <Box sx={{
                    width: "100%", display: "flex",
                    alignItems: "flex-end", justifyContent: "flex-end", flexDirection: "row"
                }}>
                    <Button variant="contained" onClick={() => { props.onClose() }}>CROP</Button>
                </Box>
            </Box>
        </Box>
    </Modal>
}

const CropPreviewCard = ({ image, crop, crop_proxy, image_proxy, replaceFileAt }) => {
    const theme = useTheme();
    const [cropActive, setCropActive] = useState(false);
    const [preview, setPreview] = useState(null);

    const[generatedCrop, setGeneratedCrop] = useState(false);

    const generatePreview = async (img, crop) => {
        if (crop.width == 100 && crop.height == 100 && crop.x == 0 && crop.y == 0) {
            const cc = centerCrop(
                makeAspectCrop(
                    {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: '%',
                        width: 100,
                    },
                    1 / 1,
                    img.width,
                    img.height
                ),
                img.width,
                img.height
            )
            crop_proxy.crop = cc
        }
        
        const scaledCrop = crop.unit === "%" ? {
            x: (crop.x / 100.0) * img.width,
            y: (crop.y / 100.0) * img.height,
            width: (crop.width / 100.0) * img.width,
            height: (crop.height / 100.0) * img.height
        } : crop;

        const canvas = document.createElement('canvas');
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        canvas.width = 256;
        canvas.height = 256;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = 256 * pixelRatio;
        canvas.height = 256 * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        console.log(scaledCrop);

        ctx.drawImage(
            img,
            scaledCrop.x * scaleX,
            scaledCrop.y * scaleY,
            scaledCrop.width * scaleX,
            scaledCrop.height * scaleY,
            0,
            0,
            256,
            256,
        );
        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        setGeneratedCrop(true);
        setPreview(base64Image);
    }
    
    useEffect(()=>{
        if (!generatedCrop){
            var img = new Image();
            img.src = image.src;
            img.onload = () => {generatePreview(img, crop)};
        }
    },[image, crop, generatedCrop])

    return (
        <Box sx={{
            width: "100%",
            height: "auto",
            borderColor: theme.palette.secondary.main,
            borderRadius: '16px',
            borderStyle: "solid",
            overflow: "hidden",
            borderWidth: "2px"
        }}
        >
            {preview && generatedCrop ?
                <>
                    <GridModal
                    sx={{ overflow: "auto", height: "95%" }}
                    image={image} crop={crop} crop_proxy={crop_proxy} open={cropActive} onClose={() => {
                        setCropActive(false);
                        setGeneratedCrop(false);
                    }} />
                    <img src={preview} className="fleximg" onClick={() => { setCropActive(true) }} />
                </>
                :
                <Stack spacing={1} sx={{
                    minHeight: "126px",
                    justifyContent: "center",
                    color: theme.palette.grey.contrastText,
                    background: theme.palette.grey.main,
                    textAlign: "center",
                    // display: "flex",
                    alignItems: "center",
                }}>
                    <CircularProgress sx={{ color: theme.palette.grey.contrastText }} size={24} />
                    <Typography variant="h6" sx={{ color: theme.palette.grey.contrastText }}>Previewing...</Typography>
                </Stack>
            }
        </Box>
    );
}

const Crop = ({ handleNext, handleBack, data_state, meta_uploader }) => {
    const img_data = getImageData("auto_crop");
    const snap_data = useSnapshot(img_data);
    const [metadataUploading, setMetadataUploading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const uploadMetadata = () => {
        // handleNext()
        const json_mdata = { auto_crop: img_data.crops }
        // _.mapValues(img_data, (val, key) => {
        //     return val.crops
        // })

        console.log("Metadata to send: ", json_mdata, img_data)
        if (_.size(json_mdata) == 0) {
            // throw new Error("No metadata to send")
            enqueueSnackbar("No metadata to send", { variant: "error" });
            return
        }
        // add metadata information regarding instance token and class
        // to be passed to training -- potentially
        json_mdata.metadata = {
            "instance_token": "yegdfn",
            "instance_class": "person"
        }

        setMetadataUploading(true)
        meta_uploader.post('/', json_mdata).then((res) => {
            if (res.status != 200) {
                enqueueSnackbar("Error uploading metadata: " + res.status, { variant: "error" });
                setMetadataUploading(false)
            }
            console.log("Metadata upload response: ", res)
            // make a call to server with the crop metadata
            setMetadataUploading(false)
            data_state.completed = true;
            handleNext()
        }).catch((err) => {
            setMetadataUploading(false)
            console.log("Error: ", err)
            enqueueSnackbar("Error uploading images. Try again shortly", { variant: "error" });
        })
    }

    const getFooter = () => {
        return <Box sx={{ width: "90vw", maxWidth: "1000px", display: "flex" }}>
            <Button
                sx={{
                    textTransform: "none", background: "white",
                    '&:hover': {
                        backgroundColor: '#fff',
                    }
                }}
                variant="outlined"
                size="large"
                onClick={() => handleBack()}
            >
                Back
            </Button>
            <Box flex={1} />
            <Button
                variant="contained"
                size="large"
                disabled={metadataUploading}
                onClick={() => uploadMetadata()}
                endIcon={<ArrowForwardIcon />}
            >
                My Crops are Perfect
            </Button>
        </Box>
    }

    return (
        <OnboardingLayout footerComponent={getFooter()} title="Crop" stageText='Step 2 of 4'>
            <ExpositionText>
                Make sure yours is the only face in frame for each pic.
                <br />
                Tap any pic to fix the crop.
            </ExpositionText>
            <Box flexGrow={1}>
                <Grid container spacing={2}>
                    {_.map(snap_data.images, (images, index) => {
                        // const cropSnap = useSnapshot(img_data.crops[index])
                        return (<Grid key={`image_card_${index}`} item xs={6} md={4} lg={3} xl={2}>
                            <CropPreviewCard
                                image={snap_data.images[index]}
                                image_proxy={img_data.images[index]}
                                crop={snap_data.crops[index].crop}
                                crop_proxy={img_data.crops[index]} />
                        </Grid>)
                    })}
                </Grid>
            </Box>
        </OnboardingLayout>
    );
}

export default Crop;