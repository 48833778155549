import React, { useCallback, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ReactCrop, { makeAspectCrop, centerCrop, PixelCrop } from 'react-image-crop'
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import Typography from '@mui/material/Typography';

const CheckTypography = ({ children, text_type }) => {
    return <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        ml: 1
    }}>
        <CheckCircleOutlineOutlined color="primary" />
        <Typography variant={text_type} sx={{ ml: 1.5, flex: 1, textAlign: 'left' }}>
            {children}
        </Typography>
    </Box>
}

const AspectRatioBox = ({ children, sx, window = true, maxSize = null, percent = 100, centered = true }) => {
    const boxRef = React.useRef(null)
    const state = useWidthHeight(boxRef)
    let minVal
    if (state) {
        if (window) {
            minVal = state.height * percent / 100
        }
        else
            minVal = Math.max(state.width, state.height)
    }
    if (maxSize)
        minVal = Math.min(minVal, maxSize)
    return <Box
        ref={window ? null : boxRef}
        sx={{
            ...sx,
            width: minVal ? minVal : "100%",
            height: minVal ? minVal : "100%",
            marginLeft: centered ? `calc(50% - ${minVal / 2}px)` : null,
        }}>
        {children}
    </Box>
}

export function useWidthHeight(ref = null) {
    const [height, setHeight] = React.useState(measureHeight(ref))
    const [width, setWidth] = React.useState(measureWidth(ref))

    const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce()

    React.useEffect(() => {
        if (!wasRenderedOnClientAtLeastOnce) return

        function setMeasuredSize() {
            const measuredHeight = measureHeight(ref)
            const measuredWidth = measureWidth(ref)
            setHeight(measuredHeight)
            setWidth(measuredWidth)
        }

        if (width == null || height == null) {
            setMeasuredSize()
        }

        window.addEventListener('resize', setMeasuredSize)
        return () => window.removeEventListener('resize', setMeasuredSize)
    }, [wasRenderedOnClientAtLeastOnce, ref])
    return wasRenderedOnClientAtLeastOnce ? { width, height } : null
}

export function measureHeight(ref) {
    if (!isClient()) return null
    return window.innerHeight
}

export function measureWidth(ref) {
    if (!isClient()) return null
    return window.innerWidth
}

// Once we ended up on the client, the first render must look the same as on
// the server so hydration happens without problems. _Then_ we immediately
// schedule a subsequent update and return the height measured on the client.
// It's not needed for CSR-only apps, but is critical for SSR.
function useWasRenderedOnClientAtLeastOnce() {
    const [
        wasRenderedOnClientAtLeastOnce,
        setWasRenderedOnClientAtLeastOnce
    ] = React.useState(false)

    React.useEffect(() => {
        if (isClient()) {
            setWasRenderedOnClientAtLeastOnce(true)
        }
    }, [])
    return wasRenderedOnClientAtLeastOnce
}

function isClient() {
    return typeof window !== 'undefined' && typeof document !== 'undefined'
}


const useLongPress = (
    onLongPress,
    onClick,
    { shouldPreventDefault = true, delay = 300 } = {}
) => {
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const timeout = useRef();
    const target = useRef();

    const start = useCallback(
        event => {
            if (shouldPreventDefault && event.target) {
                event.target.addEventListener("touchend", preventDefault, {
                    passive: false
                });
                target.current = event.target;
            }
            timeout.current = setTimeout(() => {
                onLongPress(event);
                setLongPressTriggered(true);
            }, delay);
        },
        [onLongPress, delay, shouldPreventDefault]
    );

    const clear = useCallback(
        (event, shouldTriggerClick = true) => {
            timeout.current && clearTimeout(timeout.current);
            shouldTriggerClick && !longPressTriggered && onClick();
            setLongPressTriggered(false);
            if (shouldPreventDefault && target.current) {
                target.current.removeEventListener("touchend", preventDefault);
            }
        },
        [shouldPreventDefault, onClick, longPressTriggered]
    );

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => clear(e),
        onMouseLeave: e => clear(e, false),
        onTouchEnd: e => clear(e)
    };
};

const isTouchEvent = event => {
    return "touches" in event;
};

const preventDefault = event => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};


const CropImageDisplay = ({ src, sx, crop, crop_proxy, active }) => {
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

        if (crop.width == 100 && crop.height == 100 && crop.x == 0 && crop.y == 0) {
            const cc = centerCrop(
                makeAspectCrop(
                    {
                        // You don't need to pass a complete crop into
                        // makeAspectCrop or centerCrop.
                        unit: '%',
                        width: 100,
                    },
                    1 / 1,
                    width,
                    height
                ),
                width,
                height
            )
            crop_proxy.crop = cc
        }
    }

    return <Box sx={{ ...sx, pointerEvents: !active ? "none" : null }}>
        <ReactCrop
            onDragStart={(e) => {
                // console.log("Drag start:", e)

            }}
            onDragEnd={(e) => {
                // console.log("Drag end:", e)
                // crop_proxy.crop = e
            }}
            crop={crop}
            locked={!active}
            disabled={!active}
            aspect={1 / 1}
            keepSelection={true} onChange={(cc, percCrop) => {
                // console.log("Crop change:", percCrop)

                crop_proxy.crop = percCrop
                // setCrop(cc) 
            }} className={"rcrop"} >
            <img src={src} onLoad={onImageLoad} className={"flexaspectimg"} />
        </ReactCrop></Box >
}

export {
    CheckTypography,
    CropImageDisplay,
    useLongPress,
    AspectRatioBox
}