import './App.css';
// import 'react-circular-progressbar/dist/styles.css';
import 'react-image-crop/dist/ReactCrop.css'
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/hashtag/lib/plugin.css';

import React, {useEffect} from 'react';
import SPHome from './routes/SPHome'
import SPDream from './routes/SPDream'
import Gallery from './routes/Gallery'
import Experiments from "./routes/Experiments"
import Login from './routes/Login'
import Signup from './routes/Signup'
import Onboarding from './routes/Onboarding'
import Settings from "./routes/Settings"
import Admin from "./routes/Admin"
import MyProfile from './routes/MyProfile'
import Home from './routes/Home';
import Create from './routes/Create'
import Search from './routes/Search'
import Privacy from './routes/Privacy';
import Support from './routes/Support';
import Terms from './routes/Terms';
import TemplateDetail from './routes/TemplateDetail'
import UserGallery from './routes/User'
// import NoModel from "./routes/NoModel"

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Theme from "./theme"
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import { SnackbarProvider } from 'notistack'
import { Routes, Route, Navigate, useNavigate, useLocation, useParams } from "react-router-dom";
import { useAuthSession, useAdminAuthSession, getUserJobs, useSingleGetAuthSession } from "./components/supastore"
import { CenterBox } from './components/styled/containers';
import _ from "lodash"
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

// const stripeSecret = process.env.STRIPE_SECRET;


export const AdminProtectedRoute = ({ children, required_privileges = ["dashboard"], redirect = "/me" }) => {
  const { admin_checked, is_admin, is_super_admin, privileges } = useAdminAuthSession()
  const { isInit, auth, user_id, isAuthenticated } = useAuthSession()
  if (user_id === null && !is_admin) {
    // user is not authenticated
    return null
    // return <Navigate to={redirect} replace={true} />
  }
  const has_privileges = is_super_admin || _.intersection(privileges, required_privileges).length == required_privileges.length

  // console.log("has_privileges", isInit, is_super_admin, has_privileges, privileges, required_privileges)
  // (required_privileges, (priv) => { return _.includes(required_privileges, priv) || is_super_admin })
  if (!admin_checked) {
    return null
  }
  if (!has_privileges) {
    return <Navigate to={redirect} replace={true} />
    // return <Navigate to={redirect} replace={true} />
  }

  return children
}


export const ProtectedRoute = ({
  children, redirect = "/signup",
  redirect_nomodel = "/nomodel" }) => {
  const { isInit, auth, user_id, isAuthenticated } = useAuthSession()

  let [navigateTo, setNavigateTo] = React.useState(null)

  React.useEffect(() => {
    if (isAuthenticated) {
      // check for jobs
      // getUserJobs(user_id).then(({ data: jobs_data }) => {
      //   // no training job? redirect to training only location
      //   if (jobs_data.length == 0) {
      //     setNavigateTo(redirect_nomodel)
      //   }
      // })
    }
  }, [user_id])

  console.log("Protected route, no auth or user")
  // no === null becuase that's not the same as undefined
  if (!isInit && !auth && !user_id) {
    // user is not authenticated
    return <Navigate to={redirect} replace={true} />
  }
  else if (isInit && !auth) {
    return <CenterBox><CircularProgress /></CenterBox>
  }
  else if (!isInit && !auth) {
    return <Navigate to={redirect} replace={true} />
  }
  if (navigateTo != null) {
    return <Navigate to={navigateTo} replace={true} />
  }
  return children;
};

const RedirectUsername = ({ redirect }) => {
  const { isInit, auth, user_id, profile, isAuthenticated } = useAuthSession()
  if (isInit) {
    return <CenterBox><CircularProgress /></CenterBox>
  }
  else {
    // navigate to username or basic redirect
    return <Navigate to={profile ? `/@${profile.username}` : redirect} replace={true} />
  }
}

const ProtectedMe = ({ MeComponent, UserComponent, redirect }) => {
  const { username } = useParams()
  const { isInit, auth, user_id, profile, isAuthenticated } = useAuthSession()
  if (isInit) {
    return <CenterBox><CircularProgress /></CenterBox>
  }
  else {
    return (profile && profile.username == username) ?
      <ProtectedRoute redirect={redirect}>
        <MeComponent />
      </ProtectedRoute> :
      <UserComponent />

    // <ProtectedRoute redirect={redirect}>
    //   {(profile && profile.username == username) ? <MeComponent /> : <UserComponent />}
    // </ProtectedRoute>
  }
}


const CheeseDown = () => {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  }}>
    <Typography variant="h6">Cheese is down for maintenance! Be back soon...</Typography>
    <Typography variant="h1" sx={{ color: "white", fontSize: "10rem", fontWeight: "bold", textAlign: "center" }}>🧀</Typography>
  </Box>
}
const URedirect = () => {
  const { username } = useParams()
  return <Navigate to={`/@${username}`} replace={true} />

}
const UserInvite = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const invite_code = searchParams.get('code');
  return <Navigate to={`/signup?code=${invite_code}`} replace={true} />
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  // only call once per app
  const session = useSingleGetAuthSession()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (location && location.pathname == "/cheesedown") {
      navigate("/")
    }
    // navigate("/cheesedown")
  }, [])
  return (
    <ThemeProvider theme={Theme}>
      {/* <Elements stripe={stripePromise} options={{ clientSecret: stripeSecret }}> */}
      <CssBaseline />
      <SnackbarProvider classes={{ containerRoot: "snackbar-container" }}>
      <ScrollToTop />
        <Routes>
          {/* <Route path="/dream/login" element={<SPDream />} /> */}
          <Route path="/cheesedown" element={<CheeseDown />} />

          <Route path="/dream/:action" element={<SPDream />} />
          <Route path="/dream/:action/:session_id" element={<SPDream />} />

          <Route path="/@:username/invite" element={
            // reroute to signup with code info attached
            <UserInvite />
          } />
          <Route path="/@:username" element={
            <ProtectedMe MeComponent={MyProfile} UserComponent={UserGallery} />
          } />
          <Route path="/user/:username" element={
            <URedirect />
          } />


          <Route path="/payments/success" element={<Navigate to={"/dream/create"} replace={true} />} />
          <Route path="/payments/cancel" element={<Navigate to={"/dream/create"} replace={true} />} />

          {/* <Route path="/:artifact/:uuid/:layer" element={<SPHome />} />
          <Route path="/:artifact/:uuid" element={<SPHome />} />
          <Route path="/:artifact/" element={<Gallery />} /> */}


          {/* <Route path="/nomodel" element={<NoModel />} /> */}
          <Route path="/search" element={<Search />} />
          <Route path="/template/:id" element={<TemplateDetail />} />

          <Route path="/" element={<Home />} />
          <Route path="/me" element={
            <RedirectUsername redirect="/signup" />
            // <ProtectedMe MeComponent={MyProfile} UserComponent={MyProfile} />
            // <ProtectedRoute>
            //   <MyProfile />
            // </ProtectedRoute>
          } />
          <Route path="/create" element={
            <ProtectedRoute>
              <Create />
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          } />
          <Route path="/experiments" element={
            <AdminProtectedRoute>
              <Experiments />
            </AdminProtectedRoute>
          } />
          <Route path="/experiments/:queue_id" element={
            <AdminProtectedRoute>
              <Experiments />
            </AdminProtectedRoute>
          } />


          <Route path="/admin" element={
            <AdminProtectedRoute>
              <Admin />
            </AdminProtectedRoute>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/beta_signup" element={<Onboarding />} />

          {/* Legal and Privacy */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/support" element={<Support />} />

          {/* Wild card catch all */}
          <Route path="*" element={
            <Navigate to="/" replace={true} />
          } />
        </Routes>
      </SnackbarProvider>
      {/* </Elements> */}
    </ThemeProvider>)
}

export default App;
