import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import InfiniteImageList from "../../styled/infiniteImageList";
import { useRealtimeImages, supabase, useAuthSession, getUserImages, getBaseUrl, getUserFeedStore } from "../../supastore"
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { getWrappedActions } from "../actions"
import { useSnapshot } from 'valtio'
import moment from 'moment'

const inference_api = axios.create({ baseURL: `${getBaseUrl()}/api/inference/` })

const fetchImages = async ({ user_id, min_range, max_range }) => {

  const { data, error } = await getUserImages(user_id, min_range, max_range)
  if (error) {
    console.log("user image error", error)
    // return
  }

  // console.log("Queried User Images: ", data)

  const img_promises = _.map(data, (d) => {
    const { id: user_id, session_id, image_id, created_at, ...img_props } = d
    const img_format = 'image.jpg'
    // return `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/image`
    // return axios.get(`${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/image`)
    return {
      src: `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/${img_format}`,
      user_id,
      session_id,
      image_id,
      created_at: moment(created_at).unix(),
      ...img_props,
    }
    // return inference_api.get(`/${user_id}/${session_id}/${image_id}/image`)
    //   .then(res => {
    //     return { src: "data:image/png;base64," + res.data, user_id: user_id, image_id, session_id }
    //   })
  })

  // get all img data
  return await Promise.all(img_promises)
}

const mergeImages = ({ prevImages, newImages }) => {
  // always sort by unique and descending in time
  const unique_sorted_images = _.orderBy(_.uniqBy([...prevImages, ...newImages], 'image_id'), 'created_at', 'desc')
  // const unique_sorted_images = _.uniqBy([...prevImages, ...newImages], 'image_id')
  // group by queue_id, sort by created_at
  const grouped_images = _.groupBy(unique_sorted_images, 'queue_id')
  const sorted_images = _.map(grouped_images, (g) => {
    return _.orderBy(g, 'created_at', 'desc')
  })
  const flattened_images = _.flatten(sorted_images)
  return flattened_images
}

const AllImagesList = ({ userId, isActive = true }) => {
  // data handling
  const feedStore = getUserFeedStore('all_images_' + userId)
  const { images } = useSnapshot(feedStore)
  const rtImages = useRealtimeImages()
  const [hasMore, setHasMore] = useState(true);

  const { auth, user_id, profile } = useAuthSession()
  const { enqueueSnackbar } = useSnackbar()
  
  const fetchNext = async ({ min_range, max_range, changeHasMore = true }) => {
    let img_data = await fetchImages({ user_id, min_range, max_range })
    img_data = img_data.map((i) => {return {...i, ...profile}})
    const fetchSize = max_range - min_range
    if (changeHasMore && (img_data.length == 0 || (img_data.length % fetchSize !== 0))) {
      // ignore for now
      setHasMore(false)
    }
    // console.log("Setting all images: ", img_data)
    const flattened_images = mergeImages({
      prevImages: feedStore.images,
      newImages: img_data
    })
    feedStore.images = flattened_images
  }

  React.useEffect(() => {
    if (rtImages && rtImages.length > 0) {
      fetchNext({ min_range: 0, max_range: 4, changeHasMore: false })
    }
  }, [rtImages])

  const fetchMore = async () => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 
    const fetchSize = 12
    const min_range = images.length
    // const min_range = Math.max(images.length, 4)
    const max_range = min_range + fetchSize
    fetchNext({ min_range, max_range })
  };

  
  const onAction = getWrappedActions({ enqueueSnackbar, user_id, auth, inference_api })

  return (
    <InfiniteImageList
      showViewOptions={false}
      images={images}
      imageData={feedStore.images}
      actionClick={onAction}
      fetchMore={fetchMore}
      hasMore={hasMore}
      showNSFWBlur={true}
      showActions={true}
      showUsers={true}
      isActive={isActive}
    />
  );
}


export default AllImagesList