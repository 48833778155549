import React, { useEffect } from 'react';
import { getKeyedStore, useAuthSession } from '../supastore';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import _ from "lodash"


const WaitlistTableHeader = ({ }) => {
  return <TableHead>
    <TableRow>
      <TableCell>Email</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Date Added</TableCell>
      <TableCell>Reason</TableCell>
      <TableCell>URLs</TableCell>
    </TableRow>
  </TableHead>
}

const WaitlistTableBody = ({ users }) => {

  const adminData = getKeyedStore("admin_waitlist")

  const sorted_users = _.orderBy(users, ['created_at'], ['desc'])


  return <TableBody>
    {_.map(sorted_users, (user) => {
      const user_id = user.id
      const session_id = (user.models && user.models.length > 0) ? user.models[0].queue_id : null

      return <TableRow key={user.email}>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.status}</TableCell>
        <TableCell>{moment(user.created_at).format("MM-DD-YY")}</TableCell>
        <TableCell>{user.reason}</TableCell>
        <TableCell>{user.websites}</TableCell>
      </TableRow>
    }
    )}
  </TableBody>
}

const WaitlistTableComponent = ({ users }) => {
  return <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
    <Table aria-label="simple table">
      <WaitlistTableHeader />
      <WaitlistTableBody users={users} />
    </Table>
  </TableContainer>
}

const InfiniteWaitlist = ({ users, userData, fetchMore, hasMore, isActive = true }) => {

  useEffect(() => {
    fetchMore();
  }, [])

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      height: 'calc(100vh - 200px)',
    }}>
      <Box sx={{ flexGrow: 1 }} >
        <WaitlistTableComponent users={users} />
      </Box>
    </Box>
  );
};

export default InfiniteWaitlist;
