import React, { useState } from 'react';
import TitleBar from "../components/titleBar";
import BottomNav from "../components/bottomNav";
import InfiniteImageList from "../components/styled/infiniteImageList";
import { FullContainer, InnerContainer, HomeFullContainer } from "../components/styled/containers"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useAuthSession, getBaseUrl, getUserFeed, getUserFeedStore, getIsObserveCheck } from "../components/supastore"
import _ from "lodash"
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { getWrappedActions } from '../components/profile/actions';
import { useSnapshot } from "valtio"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { TextLoop } from "../components/styled/motion"

const MainEntry = () => {
  // user_id should come from auth provider
  const { isInit, isAuthenticated, auth, user_id } = useAuthSession();

  // const scrollRef = React.useRef(null);

  return (
    <HomeFullContainer
      // ref={scrollRef}
      sx={{
        // display: 'flex',
        // alignItems: "flex-start",
        // justifyContent: "center",
        // flexDirection: "column",
        // width: "100%",
        // height: "100%",
        // overflowY: "auto",
        // overflow: "auto",
      }}>
      <TitleBar />
      <InnerContainer sx={{
        // display: 'flex',
        // overflow: "auto",
        height: isAuthenticated ? "calc(100% - 70px)" : "100%"
      }}
      >
        {(isAuthenticated || isInit) ? <></> :
          <Box sx={{ p: 2, pt: 1, pb: 1 }}>
            <Typography
              component="div"
              variant="h3"
            >The best selfies you've never taken.</Typography>
            <Typography
              component="div"
              variant="h4"
            ><TextLoop
                prefix="Be"
                texts={[
                  "anyone", "anywhere", "any style"
                ]} /></Typography>
          </Box>}
        <MainFeed user_id={user_id ?? "me"} isAuthenticated={isAuthenticated} auth={auth}
        // scrollRef={scrollRef} 
        />
      </InnerContainer>
      {isAuthenticated ? <BottomNav selectedIndex={0} /> : <></>}
    </HomeFullContainer>
  );
}
export default MainEntry


const MainFeed = ({ isAuthenticated, auth, user_id, scrollRef }) => {
  // data handling
  // const [images, setImages] = useState([]);
  const { isInit, isObserving } = useAuthSession()
  const [hasMore, setHasMore] = useState(true);
  const feedStore = getUserFeedStore('community_')
  const { images } = useSnapshot(feedStore)
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const inference_api = axios.create({ baseURL: `${getBaseUrl()}/api/inference/` })

  const fetchMore = async () => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 
    const fetchSize = 12
    const min_range = images.length
    const max_range = min_range + fetchSize
    console.log("Comm images with user id", user_id, " auth?", isAuthenticated, " min/max", min_range, max_range)
    const { data, error } = await getUserFeed({
      like_id: isAuthenticated ? user_id : null,
      min_range, max_range
    })
    if (error) {
      console.log(error)
      // return
    }
    console.log("Queried: ", data, data.length, data.length % fetchSize)
    if (data.length == 0 || data.length % fetchSize !== 0) {
      // ignore for now
      setHasMore(false)
    }
    // set a max for unregistered users
    if (!isAuthenticated && (data.length + images.length >= 24)) {
      setHasMore(false);
    }

    // console.log("Setting jobs: ", data)

    const img_promises = _.map(data, (d) => {
      const { id: img_user_id, session_id, image_id, imagelikes, ...img_props } = d
      const img_format = 'image.jpg'

      const is_liked = _.find(imagelikes || [], (data) => data.id == user_id) ? true : false

      return {
        src: `${getBaseUrl()}/api/inference/${img_user_id}/${session_id}/${image_id}/${img_format}`,
        user_id: img_user_id,
        session_id, image_id,
        imagelikes,
        liked: is_liked,
        ...img_props,
      }

    })

    // get all img data
    let img_data = await Promise.all(img_promises)
    // console.log("Setting images: ", img_data)
    feedStore.images = [...images, ...img_data]

  };

  const onAction = getWrappedActions({ enqueueSnackbar, user_id, auth, inference_api })


  const imageClick = isObserving ? (() => {
    // let's do a quick check to API to see if you are/aren't observing
    getIsObserveCheck({ user_id }).then(({ isObserving }) => {
      if (isObserving) {
        enqueueSnackbar("Please wait while we finish teaching your cheese! 🧀⏰🏃‍♂️", { variant: "info" })
      }
      else
        enqueueSnackbar("Account unlocked and ready for Cheesing!", { variant: "success" })
    })
  }) : (() => { navigate("/signup") })


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      // height: "100%",
      // overflow: "auto",
      // width: '100%',
      // height: '100%',
    }}>
      {!isInit &&
        <InfiniteImageList
          images={images}
          imageData={feedStore.images}
          actionClick={onAction}
          fetchMore={fetchMore}
          hasMore={hasMore}
          showNSFWBlur={false}
          showActions={isAuthenticated ?? false}
          showUsers={isAuthenticated ?? false}
          onImageClick={(isAuthenticated && !isObserving) ? null : imageClick}
        // scrollRef={scrollRef}
        />}
      {!isAuthenticated && !hasMore ?
        <Box
          sx={{
            margin: '0 auto',
            width: '100%',
            display: "flex",
            padding: 10,
            justifyContent: 'center'
          }}>
          <Button
            variant="contained"
            sx={{
              width: "100%",
              maxWidth: "500px",
              height: "70px",
            }}
            onClick={() => navigate("/signup")}
          >
            Join the waitlist
          </Button>
        </Box> : null}
    </Box>
  );
}

