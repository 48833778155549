import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchResultCard from './searchResultCard';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Grid from '@mui/material/Grid';
import { styled, alpha } from '@mui/material/styles';

// Image gen result type:
// status: complete / error / pending
// url: string
// prompt: string
// id: uuid
// person_token: string

const replacementTokens = [
  "Dwayne Johnson",
  "Jack Black",
  "Zendaya",
  "Micheal Caine",
  "Judi Dench"
];

const ResultDetailView = ({ resultItem }) => {
  const [isFetchingPrompt, setIsFetchingPrompt] = useState(false);
  const [customPrompt, setCustomPrompt] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatingImages, setGeneratingImages] = useState(false);

  const generateImages = () => {
    //height width seed prompt
    setGeneratingImages(true);

    const reqsData = replacementTokens.map((t) => {
      const prompt = customPrompt.replace('<sks_person>', t);
      const url = `https://prompttotemplateapi.eerac.repl.co/generate_image?prompt=${prompt}`;//&height=${resultItem.height}&width=${resultItem.width}&seed=${resultItem.seed}`;

      return {
        token: t,
        prompt: prompt,
        queryUrl: url
      }
    });
    setGeneratedImages(reqsData.map((d, idx) => {
      console.log(d.queryUrl);
      return {
        status: "complete", // / error / pending
        url: d.queryUrl,
        prompt: d.prompt,
        id: idx.toString(),
        person_token: d.token,
      };
    }));

    // Promise.all(reqsData.map(d =>
    //   fetch(d.queryUrl)
    //   .then(resp => resp.json())
    // )).then(ims => {
    //   setGeneratedImages(replacementTokens.map((t, idx) => {
    //     return {
    //       status: "complete", // / error / pending
    //       url: ims.src,
    //       prompt: customPrompt.replace('<sks_person>', t),
    //       id: idx.toString(),
    //       person_token: t,
    //     };
    //   }));
    // });

    // console.log(reqsData);
  }

  const fetchPromptTemplate = () => {
    setIsFetchingPrompt(true);
    setCustomPrompt("");
    fetch(`https://prompttotemplateapi.eerac.repl.co/templatize?sd_prompt=${resultItem.prompt}`)
      .then((response) => response.json())
      .then((data) => {
        setIsFetchingPrompt(false);
        setCustomPrompt(data.sd_template);
      });
  }

  useEffect(() => {
    if (!isFetchingPrompt && !customPrompt) {
      fetchPromptTemplate();
    }
  }, [resultItem]);


  return (
    <Box sx={{
      width: "100%",
      p: 5,
    }}>
      <Typography variant="h6">Original Prompt:</Typography>
      <Typography variant="subtitle1">{resultItem.prompt}</Typography>
      <br />
      <Typography variant="h6">Templatized Prompt:</Typography>
      {isFetchingPrompt ?
        <CircularProgress />
        :
        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item xs={8}>

            <TextField
              id="filled-textarea"
              label=""
              placeholder="Templatized prompt..."
              value={customPrompt}
              onChange={(e) => setCustomPrompt(e.target.value)}
              multiline
              fullWidth={true}
              variant="filled"
            />
          </Grid>
          <Grid item xs={4}>
            <Button variant="contained" onClick={() => generateImages()}>Generate</Button>
          </Grid>
        </Grid>
      }

      <br />
      <Typography variant="h6">Images</Typography>
      <ImageList variant="masonry" cols={3} gap={8}>
        <ImageListItem key="img_orig">
          <img
            src={`${resultItem.src}`}
            alt="Original"
            loading="lazy"
            style={{ objectFit: "contain" }}
          />
          <ImageListItemBar
            title="Original"
            position="below"
          />
        </ImageListItem>
        {generatedImages.map((im, idx) =>
          <ImageListItem key={`img_${idx}`}>
            {im.status === "complete" ?
              <img
                src={im.url}
                alt={im.person_token}
                loading="lazy"
                style={{ objectFit: "contain" }}
              />

              : im.status === "pending" ?
                <CircularProgress />
                : <Typography variant="subtitle1">Error...</Typography>
            }
            <ImageListItemBar
              title={im.person_token}
              position="below"
            />
          </ImageListItem>
        )}
      </ImageList>
    </Box>
  )
}


export default ResultDetailView;
