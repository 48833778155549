
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import { getUserProfile, getUserJobs, useAuthSession, getBaseUrl, getUserPrivate, supabase } from '../supastore';
import _ from 'lodash';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ProfilePictureDrawer from '../profile/profilePictureDrawer';

const settings_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user`,
    headers: {
        // 'apikey': process.env.REACT_APP_SUPABASE_ANON_KEY,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});


const Settings = ({ }) => {
    const { user_id, auth, profile } = useAuthSession();
    const { enqueueSnackbar } = useSnackbar();
    const [showProfilePictureDrawer, setShowProfilePictureDrawer] = useState(false);
    const [userData, setUserData] = useState({
        avatar_url: "",
        displayName: "",
        id: "",
        followerCount: 0,
    });
    const [userPrivate, setUserPrivate] = useState(null)
    const [jobCount, setJobCount] = useState(0);
    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setnewPassword] = useState("");
    const email_ref = React.useRef(null);
    const text_ref = React.useRef(null);

    const fetchUserData = async () => {
        const profile = await getUserProfile(user_id);
        setUserData({ ...profile })

        const { data: jobs_data } = await getUserJobs(user_id);
        // count the number of open inference jobs 
        console.log("Jobs data back from fed in settings: ", jobs_data)
        setJobCount(_.filter(jobs_data, (job) => job.queue_job_type.includes("train_inference") && job.queue_progress != 100).length)

        const { data: uprivate } = await getUserPrivate(user_id)
        if (uprivate.length > 0) {
            setUserPrivate({ ...uprivate[[0]] })
        }
        else
            enqueueSnackbar("No private user data found", { variant: "warning" })
        // console.log("User private: ", uprivate)
    };

    useEffect(() => {
        async function fetchData() {
            await fetchUserData();
        }
        fetchData();
    }, []);

    const changeEmail = async (e) => {
        e.preventDefault();
        const email = newEmail;
        console.log("Email: ", email)
        if (email.length == 0 || email == profile.email) {
            enqueueSnackbar(`Email is empty or unchanged ${email}`, { variant: "warning" })
            return
        }
        supabase.auth
            .updateUser({ email }).then(({ data, error }) => {
                // clear the textfield 
                if (error) {
                    console.log("Error updating password: ", error)
                    enqueueSnackbar(`Email update failed: ${error}`, { variant: 'error' });
                }
                else {
                    if (email_ref.current)
                        email_ref.current.value = ""
                    console.log("Email update res: ", data)
                    enqueueSnackbar(`Check new email for sign-in link!`, { variant: 'success' });
                }
            })
            .catch((error) => {
                console.log("Email error:", error);
                enqueueSnackbar(`Email update failed, try again`, { variant: 'error' });
            })

        // settings_api.post('/accountstuff', { update: { email }, access_token: auth.access_token }).then(res => {
        //     console.log(res);
        //     enqueueSnackbar(`Email updated to ${email}`, { variant: 'success' });
        //     setUserPrivate({ ...userPrivate, email })
        // }).catch(err => {
        //     console.log("Email error:", err);
        //     if (err.response.status == 422) {
        //         enqueueSnackbar(`Email invalid or already in use`, { variant: 'error' });
        //     }
        //     else
        //         enqueueSnackbar(`Email update failed: ${err.response.data.error}`, { variant: 'error' });
        // })
        // const { data: profile } = await auth.update({ email });
        // setUserData({ ...profile });
    };

    return (
        <Box sx={{
            overflow: "unset"
            // width: "100%",
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "flex-start",
        }}>
            <Stack justifyContent="center" display="flex" textAlign="center">
                <Avatar
                    src={profile.avatar_url}
                    style={{ border: '4px solid #fff' }}
                    sx={{ width: 96, height: 96, margin: "auto" }}
                    onClick={()=>setShowProfilePictureDrawer(true)}
                />
                <Typography variant="h5" sx={{
                    //bold
                    fontWeight: 600,

                }}>{userData.name || userData.username}</Typography>
                {userPrivate ? <Typography variant="h6">{userPrivate.email}</Typography> : null}
            </Stack>
            {user_id ? (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Typography variant="h6">Settings</Typography>
                    <Typography variant="body1">You have {jobCount} active jobs</Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",

                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "1rem"
                        }}>
                            {/* textfield */}
                            <TextField
                                label="New Email"
                                id="email"
                                name="email"
                                type={"email"}
                                autoComplete="email"
                                inputProps={{ type: "email" }}
                                variant="outlined"
                                inputRef={email_ref}
                                onChange={(e) => setNewEmail(e.target.value)} />
                            <Button variant="contained" sx={{ margin: 1 }} onClick={changeEmail}>Change Email</Button>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "1rem"
                        }}>
                            <TextField
                                inputRef={text_ref}
                                label="New Password"
                                name="password"
                                type="password"
                                id="password"
                                onChange={(e) => setnewPassword(e.target.value)}
                                autoComplete="current-password"
                                inputProps={{ type: "password" }}
                                variant="outlined"
                            />
                            <Button variant="contained" sx={{ margin: 1 }} onClick={() => {
                                supabase.auth
                                    .updateUser({ password: newPassword }).then(({ data, error }) => {
                                        // clear the textfield 
                                        if (error) {
                                            console.log("Error updating password: ", error)
                                            enqueueSnackbar(`Password update failed: ${error}`, { variant: 'error' });
                                        }
                                        else {
                                            if (text_ref.current)
                                                text_ref.current.value = ""
                                            console.log("Password update res: ", data)
                                            enqueueSnackbar(`Password updated!`, { variant: 'success' });
                                        }
                                    })
                                    .catch((error) => {
                                        console.log("Password error:", error);
                                        enqueueSnackbar(`Password update failed, try again`, { variant: 'error' });
                                    })
                                // enqueueSnackbar("Password change not yet implemented", { variant: "warning" })
                            }}>Change Password</Button>
                        </Box>
                    </Box>
                    <Box sx={{py: 2, alignItems: "center", textAlign: "center"}}>
                        <Typography variant="subtitle1">
                            <Link href="/terms" style={{ textDecoration: 'none' }}>Terms of Service</Link>
                            {" • "}
                            <Link href="/privacy" style={{ textDecoration: 'none' }}>Privacy Policy</Link>
                        </Typography>
                    </Box>
                    <Button variant="outlined" onClick={() => {
                        supabase.auth.signOut();
                        enqueueSnackbar("Signed out", { variant: "success" })
                    }}>Sign Out</Button>
                    <ProfilePictureDrawer onClose={()=>{setShowProfilePictureDrawer(false)}} open={showProfilePictureDrawer} />
                </Box>
            ) : null

            }
        </Box>
    )
}


export default Settings