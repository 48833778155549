import React, { useState } from 'react';
import TitleBar from "../components/titleBar"
import BottomNav from "../components/bottomNav";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FullContainer, InnerContainer } from "../components/styled/containers"
import Box from '@mui/material/Box';
import axios from "axios";
import { useAuthSession, getUserModels, getBaseUrl, useCurrentTab } from "../components/supastore"
import LexicaSearch from '../components/lexicaSearch';
import EditorWithMentionHashtag from '../components/create/editor';
import { useSnackbar } from 'notistack';
import { queueRequest } from "../helpers/inference"
import { proxy } from 'valtio'
import { useTheme } from "@mui/material/styles";
import { EditorState, ContentState } from 'draft-js';
import { styled } from "@mui/material/styles";


// import parse from 'html-react-parser'
import { convertFromHTML, convertToHTML } from 'draft-convert';

const inference_api = axios.create({ baseURL: `${getBaseUrl()}/api/inference/` })
const globalEditorState = proxy({ editorState: EditorState.createEmpty(), mentions: [] })

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  height: "30px",
  color: 'white',
  "&.Mui-selected, &.Mui-selected:hover": {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  }
}));

const MainEntry = ({ initialPrompt = "" }) => {
  const [selection, setSelection] = useState('compose');
  // const [prompt, setPrompt] = useState(initialPrompt);
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { auth, user_id } = useAuthSession()
  const [processedInput, setProcessedInput] = useState(initialPrompt);
  const theme = useTheme();

  const handleChange = (event, newSelection) => {
    if (newSelection) {
      setSelection(newSelection);
    }
  };

  const onPromptCopy = (prompt) => {
    const q = query.length ? query + " " : query;
    setQuery(q + prompt);
  }

  const getForm = () => {
    return selection == 'compose' ?
      <Box sx={{ px: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography sx={{ m: 1 }} variant="subtitle1">What do you want to see?</Typography>
        <EditorWithMentionHashtag
          sx={{ m: 1 }}
          editorStateProxy={globalEditorState}
          placeholder="an impressionist painting of me standing in a field of tall poppies"
        />
        <Button
          onClick={() => {
            const prompt = globalEditorState.editorState.getCurrentContent().getPlainText()
            if (prompt == "") {
              enqueueSnackbar("Please enter a prompt", { variant: "error", autoHideDuration: 3000 })
              return
            }
            // console.log("Prompt:", prompt)
            // console.log("Content state:", globalEditorState.editorState.getCurrentContent())
            const mentions = []
            const htmlObject = convertToHTML({
              // styleToHTML: (style) => {
              //   console.log("Style:", style)
              // },
              blockToHTML: (block) => {
                console.log("block:", block)
                return block.text
              },
              entityToHTML: (entity, originalText) => {
                if (entity.type == 'mention') {
                  mentions.push(entity.data.mention)
                  console.log("Otext:", originalText)
                  return ''
                }
                console.log("entity:", entity, " otext:", originalText)
                return originalText;
              }
            })(globalEditorState.editorState.getCurrentContent())
            // console.log("HTML object:", htmlObject, " mentions:", mentions)

            // clear text plz
            // globalEditorState.editorState = EditorState.push(globalEditorState.editorState, ContentState.createFromText(''))
            // enqueueSnackbar("Dev decline submit", { variant: "success", autoHideDuration: 3000 })
            // return
            queueRequest({
              prompt, auth, user_id, enqueueSnackbar, successFct: () => {
                globalEditorState.editorState = EditorState.push(globalEditorState.editorState, ContentState.createFromText(''))
              }
            })
          }}
          variant="contained"
          sx={{ m: 1.5, width: "100%", alignSelf: "center" }}
        >Create</Button>
        <Typography component="div" sx={{ m: 1, fontSize: "0.75em" }} variant="subtitle1">
          <b>Pro Tips:</b>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>💁🏾‍♀️</Box>
            <Box sx={{ pl: 1 }}>Use “me” to refer to yourself</Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>👨🏼‍🎨</Box>
            <Box sx={{ pl: 1 }}>Be as descriptive as possible - lighting, setting, camera angle, medium, etc.</Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>🎥</Box>
            <Box sx={{ pl: 1 }}>Try putting yourself in film and TV with prompts like “me, production still from Shrek, (2001)”</Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>🚫</Box>
            <Box sx={{ pl: 1 }}>Put things you DON’T want after two slashes like “// moldy cheese”</Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>📖</Box>
            <Box sx={{ pl: 1 }}>Check out the Reference tab for ideas</Box>
          </Box>
        </Typography>

      </Box>
      : <LexicaSearch
        query={query}
        setQuery={setQuery}
        results={searchResults}
        setResults={setSearchResults}
      />
  }

  return <FullContainer sx={{ backgroundColor: theme.palette.background.home, }}>
    <TitleBar />
    <InnerContainer sx={{ pb: "100px" }}>
      <Box sx={{ alignItems: "center" }}>
        {/* <Typography variant="h5">Create</Typography> */}
        <Stack spacing={2} sx={{ margin: "0 auto" }}>

          {getForm()}
        </Stack>
      </Box>
    </InnerContainer>
    <Box sx={{
      p: 2,
      flexGrow: 1,
      justifyContent: "center",
      display: "flex",
      width: "100%",
      position: 'fixed',
      bottom: "55px",
      borderTop: 1,
      borderColor: theme.palette.background.divider,
      backgroundColor: theme.palette.background.home,
    }}>
      <ToggleButtonGroup
        // color="primary"
        value={selection}
        exclusive
        onChange={handleChange}
        aria-label="selection"
      // sx={{ alignSelf: "center" }}
      >
        <StyledToggleButton value="reference">Reference</StyledToggleButton>
        <StyledToggleButton value="compose">Compose</StyledToggleButton>
      </ToggleButtonGroup>
    </Box>
    <BottomNav selectedIndex={1} />
  </FullContainer>
}

export default MainEntry