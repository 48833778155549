import Resizer from "react-image-file-resizer";


const maxImageSize = 2048

const convertFileToBase64 = ({ file, onImageData }) => {
    Resizer.imageFileResizer(
        file,
        maxImageSize, // Is the maxWidth of the resized new image.
        maxImageSize, // Is the maxHeight of the resized new image.
        'PNG', // Is the compressFormat of the resized new image.
        100, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri) => {
            // console.log("Resized image:", uri)
            onImageData(uri)
        }, // Is the callBack function of the resized new image URI.
        "base64", // Is the output type of the resized new image.
        200, // Is the minWidth of the resized new image.
        200 // Is the minHeight of the resized new image.
    )
}

const asyncConvertFileToBase64 = async ({ file }) => {
    return new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(
                file,
                maxImageSize, // Is the maxWidth of the resized new image.
                maxImageSize, // Is the maxHeight of the resized new image.
                'PNG', // Is the compressFormat of the resized new image.
                100, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                    // console.log("Resized image:", uri)
                    resolve(uri)
                }, // Is the callBack function of the resized new image URI.
                "base64", // Is the output type of the resized new image.
                200, // Is the minWidth of the resized new image.
                200 // Is the minHeight of the resized new image.
            )
        } catch (err) {
            reject(err)
        }
    })
}

const pasteToImage = ({ event, onImageData, items }) => {
    var items = (items || event.clipboardData || event.originalEvent.clipboardData).items;
    var isData = false
    // console.log("Received items;", items)
    for (var index in items) {
        var item = items[index];
        if (item.kind === 'file') {
            convertFileToBase64({ file: item.getAsFile(), onImageData })
        }
        // else
        // console.error("Issue with item kind:", item)
    }
    if (!isData) {
        onImageData(null)
    }
}

function createPasteEvent(onImageData, setErrorMsg, ref = null) {
    // var pasteTarget = document.createElement("div");
    // pasteTarget.contentEditable = true;
    var prevActive = ref || document.activeElement
    // setErrorMsg(prevActive)
    // console.log("Ref :", prevActive)
    // add the event listerenr 
    const pasteHandler = (event) => {
        // setErrorMsg("works2")
        pasteToImage({ event, onImageData })
    }
    // setErrorMsg("works")
    prevActive.addEventListener("paste", pasteHandler);
    // var actElem = document.activeElement.appendChild(pasteTarget).parentNode;
    // pasteTarget.focus();
    // pasteTarget.addEventListener('paste', function (e) {
    //     pasteToImage({ event: e, onImageData })
    // })
    if (document.execCommand("Paste", null, null)) {
        // setErrorMsg("works")

    }
    else {
        // alert("paste failed")
        // setErrorMsg("Paste failed! Please try again.")
        // attempt another type of copy
        pasteFromClipboard(onImageData, setErrorMsg)

        // navigator.clipboard.readText().then(
        //     clipText => {
        //         console.log("Clipboard text:", clipText)
        //     })
        // alert("No paste on your platform yet, try another method!")
    }

    // remove the event listener
    prevActive.removeEventListener("paste", pasteHandler);
    // var paste = pasteTarget.innerText;
    // actElem.removeChild(pasteTarget);
    // prevActive.focus()
    // return paste;
};

function pasteFromClipboard(onImageData, setErrorMsg) {
    return navigator.clipboard.read()
        .then(items => {
            // console.log('Pasted content: ', items);
            return items[0].getType('image/png')
        }).then(blob => {
            // console.log("Blob:", blob)
            return blobToBase64(blob)
        }).then(base64 => {
            // console.log("Base64:", base64.substring(0, 100))
            onImageData(base64)
        })
        .catch(err => {
            console.error('Failed to read clipboard contents: ', err);
            setErrorMsg("Failed to read clipboard contents: " + err)
        });
}


function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}
const getBaseUrl = () => {
    return window.location.protocol + '//' + window.location.host
}

// const base64ToFile = (blob) => {
//     return new Promise((resolve, reject) => {
//         const reader = new FileReader()
//         reader.onloadend = () => resolve(reader.result)
//         reader.onerror = reject
//         reader.readAsDataURL(blob)
//     })
// }

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export {
    getBaseUrl,
    dataURLtoFile,
    blobToBase64,
    convertFileToBase64,
    asyncConvertFileToBase64,
    createPasteEvent,
    pasteFromClipboard,
    pasteToImage
}