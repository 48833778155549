import { queueRequest, processPrompt, copyCleanPromptForUI } from "../../helpers/inference"
import copy from 'copy-to-clipboard';
import { social_api } from "../../helpers/social"

const getWrappedActions = ({ enqueueSnackbar, user_id, auth, inference_api }) => {
    const onButtonAction = ({ action_name, img_data, img_idx, is_private, action_label }) => {
        console.log("Action: ", action_name, " on ", img_data, " at ", img_idx)
        // console.log("WARNIGN ACTIONS TEMP DISABLED")
        // return
        if (action_name == 'publish') {
            // if is_private, you share public, else make private
            console.log("Take action!")
            const share_type = is_private ? 'public' : 'private'
            // let's make public via api call
            const { session_id, image_id } = img_data

            console.log("Posting to inference @ ", `/${user_id}/${session_id}/${image_id}/share`, " with ", { share_type })
            inference_api.post(`/${user_id}/${session_id}/${image_id}/share`, { access_token: auth.access_token, share_type: share_type }).then(res => {
                console.log("Shared: ", res)
                enqueueSnackbar(`Image made ${share_type}`, { variant: 'success' })
                img_data.image_privacy = share_type
                // change the img_data 
            }).catch(err => {
                if (err.response && err.response.data && err.response.data.error) {
                    enqueueSnackbar(`Image failed making ${share_type}. Try again!`, { variant: 'error' })
                }
                else
                    enqueueSnackbar(`Image failed making ${share_type}. Try again!`, { variant: 'error' })
                console.log("Share error: ", err)
            })
        }
        else if (action_name == 'remix') {
            const img_metadata = JSON.parse(img_data.metadata);
            // let prompt = "";
            let seed = img_metadata.seed ?? null;
            console.log("Img data: ", img_data)
            console.log("Img meta: ", img_metadata)
            const prompt = copyCleanPromptForUI(img_metadata)
            // if (!img_metadata.tokenized_prompt) {
            //     prompt = img_metadata.prompt.replaceAll("yegfdn person", "me");
            // } else {
            //     prompt = img_metadata.tokenized_prompt.replaceAll("yegfdn person", "me").replaceAll(/\b<token>\b/g, "me");
            // }
            console.log("Prompt: ", prompt, " remix id: ", img_data.image_id)
            queueRequest({ prompt, remix_image_id: img_data.image_id, seed, auth, user_id, enqueueSnackbar });
        }
        else if (action_name == 'copy-prompt') {
            const img_metadata = JSON.parse(img_data.metadata);
            // let to_copy_prompt = "";

            // if (!img_metadata.tokenized_prompt) {
            //     to_copy_prompt = img_metadata.prompt.replaceAll("yegfdn person", "me");
            // } else {
            //     to_copy_prompt = img_metadata.tokenized_prompt.replaceAll("yegfdn person", "me").replaceAll("<token>", "me");
            // }

            // // add negative prompt to copy
            // if (img_metadata.negative_prompt) {
            //     to_copy_prompt = to_copy_prompt + " //" + img_metadata.negative_prompt;
            // }
            // const { prompt: original_prompt, negative_prompt: original_negative } = img_metadata;
            // console.log("Original prompt: ", original_prompt, " original negative: ", original_negative)
            // const { error, positive_prompt, negative_prompt } = processPrompt(to_copy_prompt);
            // if (error) {
            //     enqueueSnackbar(`Failed to copy prompt. ${error}`, { variant: 'error' })
            // }
            // console.log("Pos prompt:", positive_prompt, " Neg prompt:", negative_prompt);
            // check positive prompt 
            // const prompt = to_copy_prompt
            const prompt = copyCleanPromptForUI(img_metadata)

            // console.log("Prompt:", prompt.trim())
            const text = new window.ClipboardItem({ "text/plain": prompt.trim() });

            navigator.clipboard.write([text]).then(() => {
                enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success" });
            })
                .catch((err) => {

                    if (!copy(prompt.trim())) {
                        enqueueSnackbar("Error: " + err, { variant: "error" });
                    }
                    else
                        enqueueSnackbar("Copied prompt! Go to compose to generate!", { variant: "success" });

                });

        }
        else if (action_name == 'like') {
            const { image_id, liked } = img_data || {}
            // console.log("Like image: ", image_id, " liked: ", liked, " img data", img_data)
            if (image_id) {
                // check if liked already
                social_api.post(`/like`, {
                    like_id: image_id,
                    access_token: auth.access_token,
                    liked
                }).then(res => {
                    // console.log("Liked: ", res)
                    if (liked) {
                        // enqueueSnackbar("Unliked image!", { variant: 'success' })
                    }
                    else {
                        // enqueueSnackbar("Liked image!", { variant: 'success' })
                    }
                    img_data.liked = !liked
                }).catch(err => {
                    console.log("Like error: ", err)
                    if (liked) {
                        enqueueSnackbar("Failed to unlike image!", { variant: 'error' })
                    }
                    else {
                        enqueueSnackbar("Failed to like image!", { variant: 'error' })
                    }
                })
            }
            // social_api.post(`/${user_id}/${img_data.image_id}/like`, { access_token: auth.access_token }).then(res => {
            // // console.log("Idata:", img_data)
            // // enqueueSnackbar(`'${action_label}' coming soon!`, { variant: 'warning' })
            // new Promise(r => setTimeout(r, 400)).then(() => {
            //     enqueueSnackbar(`'${action_label}' coming soon!`, { variant: 'warning' })
            //     img_data.liked = false
            // })
        }
        else {
            enqueueSnackbar(`'${action_label}' coming soon!`, { variant: 'warning' })
        }
    }
    return onButtonAction
}



export {
    getWrappedActions
}