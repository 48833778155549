import { Navigate } from "react-router-dom";

const Cancel = () => {
    // back you go! should send you to payment location -- since you got cancelled from there
    const searchParams = new URLSearchParams(window.location.search);
    const session_id = searchParams.get("session_id");
    let navigate_to = session_id ? `/dream/create/${session_id}` : `/dream/create`;
    return <Navigate to={navigate_to} replace={true} />
}

export default Cancel;