import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import InfiniteImageList from "../../styled/infiniteImageList";
import { supabase, useAuthSession, getUserFeed, getBaseUrl, getUserFeedStore } from "../../supastore"
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { getWrappedActions } from "../actions"
import { useSnapshot } from "valtio"
import { useNavigate } from 'react-router-dom';

const PublicImagesList = ({ userId, isActive = true }) => {
    // data handling
    // const [images, setImages] = useState([]);
    const feedStore = getUserFeedStore('public_' + userId)
    const { images } = useSnapshot(feedStore)

    const [hasMore, setHasMore] = useState(true);

    const { auth, user_id, isAuthenticated, profile } = useAuthSession()
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate();
    const isAuthUser = user_id == userId;

    const inference_api = axios.create({ baseURL: `${getBaseUrl()}/api/inference/` })

    const onImageClick = !isAuthenticated ? () => {
        navigate("/signup");
    } : null;

    const fetchMore = async () => {
        // await new Promise(resolve => setTimeout(resolve, 500));
        // fetch jobs 
        const fetchSize = 12
        const min_range = images.length
        const max_range = min_range + fetchSize
        const { data, error } = await getUserFeed({ user_id: userId, min_range, max_range })
        if (error) {
            console.log(error)
            // return
        }
        console.log("Queried Cdata: ", data, data.length, data.length % fetchSize)
        if (data.length == 0 || data.length % fetchSize !== 0) {
            // ignore for now
            setHasMore(false)
        }
        console.log("Setting pub jobs: ", data)

        const img_promises = _.map(data, (d) => {
            const { id: user_id, session_id, image_id, ...img_props } = d
            const img_format = 'image.jpg'
            let r = {
                src: `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/${img_format}`,
                user_id,
                session_id, image_id,
                ...img_props
            }
            if (profile && isAuthUser) {
                r = {...r, ...profile}
            }

            return  r
        })

        // get all img data
        let img_data = await Promise.all(img_promises)
        console.log("Setting pub images: ", img_data)
        feedStore.images = [...images, ...img_data]
    };


    React.useEffect(() => {
        if (isAuthUser && profile) {
            feedStore.images = _.map(images, (img) => {
                return {
                    ...img,
                    ...profile
                }
            })
        }
    }, [profile, isAuthUser])
    
    const onAction = getWrappedActions({ enqueueSnackbar, user_id, auth, inference_api })
    return (
        <InfiniteImageList
            images={images}
            imageData={feedStore.images}
            onImageClick={onImageClick}
            actionClick={onAction}
            fetchMore={fetchMore}
            hasMore={hasMore}
            showNSFWBlur={false}
            showActions={isAuthenticated}
            showUsers={isAuthenticated}
            isActive={isActive}
        />
    );
}


export default PublicImagesList