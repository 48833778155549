import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

const ExpositionText = ({ children, sx }) => {
    return <Typography variant="h6" sx={{
        fontWeight: 400, fontSize: '1.1em',
        ...sx,

    }} >
        {children}
    </Typography >
}

const AccentExpositionText = ({ children, sx }) => {
    const theme = useTheme();
    return <Typography component='span' variant='h6' sx={{
        fontWeight: 400, fontSize: '1em',
        color: theme.palette.secondary.main,
        ...sx,
    }}>
        {children}
    </Typography>
}

const MediumTitle = ({ children, sx }) => {
    return <Typography sx={{
        fontWeight: 800,
        textTransform: 'uppercase',
        ...sx,
    }}
        variant="h6"> {children}
    </Typography >
}

const LargeTitle = ({ children, sx }) => {
    return <Typography sx={{
        fontWeight: 500,
        letterSpacing: -1.5,
        ...sx
    }}
        variant="h3" > {children}</Typography >
}

const QuietText = ({ children, sx }) => {
    return <Typography variant="p" sx={{
        m: 0, p: 0,
        color: "text.secondary",
        width: "100%",
        textAlign: 'right',
        fontWeight: 400, fontSize: '1.1em',
        ...sx
    }}>
        {children}
    </Typography>
}

const ContinueText = ({ children, sx }) => {
    return <ExpositionText sx={{
        m: 0, p: 0,
        width: "100%",
        textAlign: 'right',
        ...sx
    }}>
        {children}
    </ExpositionText>
}

const StickyFooter = ({ children, sx }) => {
    return (
        <Paper
            sx={{
                p: 1,
                flexGrow: 1,
                justifyContent: "center",
                display: "flex",
                width: "100%",
                position: 'fixed',
                bottom: 0,
                ...sx
            }}
            component="footer"
        >
            {children}
        </Paper>
    );
}


const PageBox = ({ children, sx }) => {
    return <Box className="container" sx={{
        textAlign: "left",
        textAlign: "left",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        width: "100%",
        maxWidth: "200px",
        flex: 1,
        flexDirection: "column",
        alignItems: "space-between",
        justifyContent: "space-between",
        ...sx
    }}>
        {children}
    </Box>
}




const images = [
    {
        url: '/static/images/buttons/breakfast.jpg',
        title: 'Breakfast',
        width: '40%',
    },
    {
        url: '/static/images/buttons/burgers.jpg',
        title: 'Burgers',
        width: '30%',
    },
    {
        url: '/static/images/buttons/camera.jpg',
        title: 'Camera',
        width: '30%',
    },
];

const BetterIconButtom = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 100,
    maxWidth: 150,
    border: '1px dashed',
    borderRadius: "10px",
    margin: "5px",
    padding: "4px",
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    [theme.breakpoints.down('md')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    width: '100% !important', // Overrides inline-style
    '&:focus': {
        outline: "none",
        boxShadow: "none",
        '& .MuiComplexButton-root': {
            border: '4px solid currentColor',
        },
    },
    '&:hover': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiComplexButton-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    paddingTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const ComplexIconButton = ({ ButtonIcon, label, image }) => {
    return <BetterIconButtom
        focusRipple
        key={label}
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            focusable: "false",
        }}

        className="MuiComplexButton-root"

    >
        {/* <ImageSrc style={{ backgroundImage: `url(${image.url})` }} /> */}
        {/* <ImageBackdrop className="MuiImageBackdrop-root" /> */}
        <Box sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: "7.5%",
            bottom: "auto",
        }}
        // className="MuiComplexButton-root"
        >
            <ButtonIcon color="secondary" />
        </Box>
        <Image>
            <Typography
                component="span"
                variant="subtitle1"
                color="secondary"
                sx={{
                    position: 'relative',
                    // p: 3,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
            >
                {label}
            </Typography>
        </Image>
    </BetterIconButtom>
}


const StyledCard = styled(Card)(({ theme }) => ({
    border: `2px solid rgba(0, 76, 153, .6)`,
    borderRadius: 10,
    width: "100%",
    backgroundColor: theme.palette.card.background
    // backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"
}));

const StyledActions = styled(CardActions)({
})
const StyleActionButton = styled(Button)({
})
const StyleActionIconButton = styled(IconButton)({
})

const UploadBox = styled(ButtonBase)(({ theme }) => ({
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // bottom: 0,
    // right: 0,
    // backgroundColor: 'silver',/* show me! */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: theme.palette.secondary.main,
    borderRadius: '16px',
    borderStyle: "dashed",
    borderWidth: "2px",
    '&.Mui-disabled': {
        // color: "orange",
        // width: "200px",
        borderColor: theme.palette.action.disabled,
    }
}))
export {
    QuietText,
    UploadBox,
    StyledCard,
    StyledActions,
    StyleActionButton,
    ExpositionText,
    AccentExpositionText,
    MediumTitle,
    ContinueText,
    LargeTitle,
    PageBox,
    StickyFooter,
    ComplexIconButton
}