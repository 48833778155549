import React from 'react';
import Button from '@mui/material/Button';
import _ from 'lodash'
import { ExpositionText, MediumTitle } from "./styled"
import OnboardingLayout from './layout';

const Landing = ({ handleNext, data_state }) => {

    const footerButtons = () => {
        return <Button
                sx={{width: "90vw", maxWidth: "1000px", textTransform: "none"}}
                variant="contained"
                size="large"
                onClick={() => handleNext()}
            >
                LEGGO!
            </Button>
    }

    return (
        <OnboardingLayout footerComponent={footerButtons()} title="You Are About to Cheese">
            <ExpositionText>
                We are going to make amazing AI-generated pictures of you starting right now. 📸 🤩 🧀
            </ExpositionText>
            <ExpositionText>
                How does it work? 
            </ExpositionText>
            <ExpositionText>
                <ol>
                    <li>You upload 10-20 pix of you</li>
                    <li>You crop your pix so it's only your face - but not too tight</li>
                    <li>You'll see</li>
                    <li>Payment</li>
                    <li>Magic</li>
                </ol>
            </ExpositionText>
            <ExpositionText>
            It’s that easy! 🤖 ✨ 🧀
            </ExpositionText>
        </OnboardingLayout>
    );
}

export default Landing;