import React from 'react'
import { getBaseUrl, useAuthSession, getKeyedStore, supabase, getIdToProfiles } from "../../supastore";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import _ from "lodash"
import { useSnapshot } from "valtio"
import { useSnackbar } from 'notistack'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import copy from 'copy-to-clipboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import { SquishButton, SquishAvatar } from "../../styled/motion"
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { admin_api, userStore, fetchMoreUsers } from "./user"

const cheddar_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user/beta`,
})
const cheddarStore = getKeyedStore('cheddar_admin', {
    cheddar: [],
    transactions: [],
    selected: null,
    hasMore: true,
    reason: "test",
    adjust_amount: 1,
})

const fetchCheddarTransactions = async ({ user_id, cheddarStore, access_token, enqueueSnackbar }) => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 

    const { data, error: cheddar_error } = await admin_api.post('/', {
        access_token,
        action: 'list_cheddar',
        action_args: { user_id }
    })

    if (cheddar_error) {
        console.log(cheddar_error)
        enqueueSnackbar("Failed fetch beta codes", { variant: 'error' })
        return
    }

    const { cheddar, transactions } = data
    console.log("Cheddar:", cheddar, "transact:", transactions)

    cheddarStore.cheddar = cheddar
    cheddarStore.transactions = _.orderBy(_.uniqBy([...transactions], 'transaction_id'), 'created_at', 'desc')
    const profiles = await getIdToProfiles(_.map(transactions, (c) => c.id))
    cheddarStore.transactions = _.map(cheddarStore.transactions, (c) => { return { ...c, ...profiles[c.id] } })
};

const createBetaCode = async ({ access_token, user_id, enqueueSnackbar, ...action_props }) => {
    const { data, error } = await admin_api.post('/', {
        access_token, action: 'create_beta_invite', action_args: { user_id, ...action_props }
    })

    if (error) {
        // console.log(error)
        enqueueSnackbar(`Failed Create Beta Code: ${error}`, { variant: 'error' })
        return
    }
    enqueueSnackbar(`Created Beta Code: ${data.code_id}`, { variant: 'success' })
    return data
}

const adjustCheddar = async ({ access_token, user_id, adjust_amount, reason, enqueueSnackbar }) => {

    // update_beta_invite
    const { data, error } = await admin_api.post('/',
        {
            access_token,
            action: 'adjust_cheddar_balance',
            action_args: { user_id, adjust_amount, reason }
        })
    // const rpc_args = { increment, beta_code_id: code_id }
    // console.log("Calling with values: ", rpc_args)
    // const { data, error } = await supabase
    //     .rpc('increment_beta_code', rpc_args)
    if (error) {
        enqueueSnackbar(`Failed Increment Beta Code: ${error}`, { variant: 'error' })
        return
    }
    console.log("adjust cheddar:", data, " error :", error)
    // enqueueSnackbar(`Incremented Beta Code: ${code_id}`, { variant: 'success' })
    return data
}


const CheddarListItem = ({ transaction, enqueueSnackbar }) => {
    const { access_token } = useAuthSession()
    // we have three cells, [id, code_id, code_count]
    // we want to format as a table
    const { username, id: user_id, transaction_id, transaction_type,
        transaction_reason, created_at, transaction_amount } = transaction
    // console.log("Code:", code)
    return <TableRow>
        <TableCell>{username ? username : "loading..."}</TableCell>
        <TableCell align="center">{transaction_type}</TableCell>
        <TableCell align="center">{transaction_reason}</TableCell>
        <TableCell align="center">{transaction_amount}</TableCell>
        <TableCell>{moment(created_at).format('MM/DD/YY, h:mm a')}</TableCell>
        <TableCell align="center">{transaction_id}</TableCell>
    </TableRow>

}

const CheddarTableTransactions = ({ transactions, enqueueSnackbar }) => {
    return <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell >Transact Type</TableCell>
                    <TableCell >Transact Reason</TableCell>
                    <TableCell >Amount</TableCell>
                    <TableCell >Created At</TableCell>
                    <TableCell >Transact ID</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {/* <List> */}
                {transactions.map((transaction, idx) => {
                    return <CheddarListItem
                        enqueueSnackbar={enqueueSnackbar}
                        key={`c_${idx}_${transaction.transaction_id}`} transaction={transaction}
                    />
                    // return <ListItem key={code.code_id}>
                    // <ListItemText primary={code.code_id} />
                    // </ListItem>
                }
                )}
                {/* </List> */}
            </TableBody>
        </Table>
    </TableContainer>

}

const CheddarActionsComponent = ({ isActive }) => {
    // see our beta invites plz 
    const {
        cheddar: snap_cheddar,
        transactions: snap_transactions,
        selected: snap_selected,
        hasMore: snap_has_more,
        adjust_amount: snap_adjust_amount,
        reason: snap_reason,
        accountType: snap_account,
        failCode } = useSnapshot(cheddarStore)
    const { users: snap_users } = useSnapshot(userStore)
    const real_users = _.filter(snap_users, (u) => u.username != null)

    // const snap_has_more = useSnapshot(cheddarStore.hasMore)
    const { enqueueSnackbar } = useSnackbar()
    const { auth, user_id, profile } = useAuthSession();
    const { access_token } = auth || {}
    const current_user = _.find(real_users, (u) => u.id == user_id)
    const selected_username = snap_selected ? snap_selected.username : (profile ? profile.username : "loading...")

    // make query for beta codes and list 
    React.useEffect(() => {
        // get initial batch 
        // i should use autocomplete to fetch these in reality
        fetchMoreUsers({ snap_users, userStore, access_token, enqueueSnackbar })
        // fetchCheddarTransactions({ user_id, cheddarStore, access_token, enqueueSnackbar })
    }, [])

    const updateTransactions = async () => {
        return fetchCheddarTransactions({
            user_id: snap_selected ? snap_selected.id : user_id,
            cheddarStore, access_token, enqueueSnackbar
        })
    }
    const adjustCheddarForSelected = async (adjust_amount) => {
        // take current user and plus their cheddar
        return adjustCheddar({
            user_id: snap_selected ? snap_selected.id : user_id,
            adjust_amount,
            cheddarStore,
            reason: snap_reason,
            access_token,
            enqueueSnackbar,
        }).then(() => {
            // update the transactions plz
            updateTransactions()
        }).catch(err => {
            console.log("Error adjusting cheddar:", err)
        })
    }

    React.useEffect(() => {
        // get initial batch 
        // i should use autocomplete to fetch these in reality
        updateTransactions()
    }, [snap_selected])

    const userSelect = (e, val) => {
        // console.log("Selected user:", val)
        cheddarStore.selected = val
        cheddarStore.cheddar = []
        cheddarStore.transactions = []
        console.log("Select user:", cheddarStore.selected)
    }
    return <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,

    }}>
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

            <Autocomplete
                isOptionEqualToValue={(option, value) => option.username === value.username}
                getOptionLabel={(option) => option.username}
                onChange={userSelect}
                options={_.sortBy(real_users, (u) => u.username.toLowerCase())}
                defaultValue={profile}
                // inputDefaultValue={current_user && current_user.username}
                // inputValue={inputValue}
                // onInputChange={(_, newInputValue) => {
                //   setInputValue(newInputValue)
                // }}
                // defaultValue={_.find(real_users, (u) => u.id == user_id)}
                sx={{ width: "50%", m: 2 }}
                renderInput={(params) => <TextField {...params} label="Username Selection" />}
            />
        </Box>
        <CardHeader
            sx={{ cursor: "pointer" }}
            avatar={
                <SquishAvatar
                    // sx={{ cursor: "pointer" }}
                    aria-label="user" src={snap_selected ? snap_selected.avatar_url : ""}>
                    {selected_username[0]}
                </SquishAvatar>
            }
            title={<div style={{ cursor: "pointer" }}>{selected_username}</div>}
            titleTypographyProps={{ variant: 'body', align: 'left', fontWeight: 600 }}
            onClick={() => { }}
        />
        <Typography sx={{ m: 1 }} variant="h4" color="secondary">Cheddar Actions</Typography>
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <TextField
                    sx={{ m: 1 }}
                    id="count-number"
                    label="Adjust Amount (+/-)"
                    type="number"
                    autoFocus={false}
                    defaultValue={snap_adjust_amount}
                    onChange={(e) => cheddarStore.adjust_amount = Math.min(Math.max(1, e.target.value), 1000)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <FormControl
                    sx={{ m: 1 }}
                >
                    <InputLabel id="select-account">Adjust Reason</InputLabel>
                    <Select
                        labelId="select-account-label"
                        id="select-account-sel"
                        defaultValue={snap_reason}
                        label="Adjust Reason"
                        onChange={(e) => {
                            cheddarStore.reason = e.target.value
                        }}
                    >
                        <MenuItem value={'test'}>Test</MenuItem>
                        <MenuItem value={'refund'}>Refund</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <SquishButton sx={{ m: 2 }} variant="outlined" onClick={() => {
                    // take current user and plus their cheddar
                    adjustCheddarForSelected(snap_adjust_amount)
                }} >Add Cheddar</SquishButton>
                <SquishButton sx={{ m: 2 }} variant="outlined" onClick={() => {
                    adjustCheddarForSelected(-snap_adjust_amount)
                }}>Remove Cheddar</SquishButton>
            </Box>

        </Box>
        {/* now we create our current cheddar details */}
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

            <Typography sx={{ m: 1 }} variant="h4" color="secondary">Current Cheddar</Typography>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }}>
                {snap_cheddar && snap_cheddar.map((cheddar, cix) => {
                    return <Typography key={cheddar.id + "_" + cheddar.token_type} variant="h6">{cheddar.token_type}: {cheddar.token_count}</Typography>
                })}
            </Box>
        </Box>
        <Typography sx={{ m: 1 }} variant="h4" color="secondary">Cheddar Transaction History</Typography>
        {/* now we do our cheddar transaction details */}
        <CheddarTableTransactions
            transactions={snap_transactions}
            enqueueSnackbar={enqueueSnackbar}
        />
        {/* <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
        }}>
            {snap_transactions && snap_transactions.map((transaction, cix) => {
                console.log("Transaction:", transaction)
                return <Typography key={transaction.id + "_" + transaction.token_type} variant="h6">{transaction.token_type}: {transaction.token_count}</Typography>
            })}
        </Box> */}
    </Box >
    // // make query for beta codes and list
    // React.useEffect(() => {
    //     // get initial batch 
    //     // i should use autocomplete to fetch these in reality
    //     fetchMoreUsers({ snap_users, userStore, access_token, enqueueSnackbar })
    //     fetchCheddarTransactions({ beta_codes: snap_codes, cheddarStore, access_token, enqueueSnackbar })
    // }, [])
    // const userSelect = (e, val) => {
    //     // console.log("Selected user:", val)
    //     cheddarStore.selected = val
    //     console.log("Selected:", cheddarStore.selected)
    //     console.log("EE:", e)
    // }
    // const adjustCodeCount = async ({ count, code_id }) => {
    //     if (code_id == null) {
    //         enqueueSnackbar("No code selected", { variant: 'error' })
    //         return
    //     }
    //     const select_id = cheddarStore.selected ? cheddarStore.selected.id : profile.id

    //     incrementBetaCode({ access_token, user_id: select_id, code_id, increment: count, enqueueSnackbar }).then((data) => {
    //         cheddarStore.hasMore = true
    //         // cheddarStore.transactions = []
    //         fetchCheddarTransactions({ beta_codes: snap_codes, cheddarStore, access_token, enqueueSnackbar, start_ix: 0 })
    //         enqueueSnackbar(`Adjusted Beta Code ${code_id} by ${count}`, { variant: 'success' })
    //     }).catch(err => {
    //         enqueueSnackbar(`Failed Increment Beta Code: ${err}`, { variant: 'error' })
    //     })
    // }

    // const testUseBetaCode = async ({ email, code_id }) => {
    //     if (code_id == null) {
    //         enqueueSnackbar("No code selected", { variant: 'error' })
    //         return
    //     }
    //     cheddar_api.post('/', {
    //         email, code_id,
    //         password: "password",
    //         username: "betatest",
    //         acceptedTerms: true,
    //         metadata: {}
    //     }).then((data) => {
    //         enqueueSnackbar(`Tested Beta Code ${code_id}`, { variant: 'success' })
    //         cheddarStore.hasMore = true
    //         // cheddarStore.transactions = []
    //         fetchCheddarTransactions({ beta_codes: snap_codes, cheddarStore, access_token, enqueueSnackbar, start_ix: 0 })
    //         console.log("Data:", data)
    //     }).catch(err => {
    //         console.log("err:", err)
    //         enqueueSnackbar(`Failed Test Code: ${err.response.data.error}`, { variant: 'error' })
    //     })
    // }


    // // const current_user = _.find(real_users, (u) => u.id == user_id)
    // // list all of our codes
    // // then make a button to create a new code 
    // console.log("User match:", profile)

    // return <Box sx={{
    //     width: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     p: 3,

    // }}>
    //     {failCode && <a
    //         style={{ color: "#FFF", textDecoration: "none", cursor: "pointer", backgroundColor: "#242424" }}
    //         href={failCode} target="_blank" rel="noreferrer">Failed to copy to clipboard, right click here</a>}
    //     <Box sx={{
    //         width: '100%',
    //         display: 'flex',
    //         flexDirection: 'row',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //     }}>

    //         <Autocomplete
    //             isOptionEqualToValue={(option, value) => option.username === value.username}
    //             getOptionLabel={(option) => option.username}
    //             onChange={userSelect}
    //             options={_.sortBy(real_users, (u) => u.username.toLowerCase())}
    //             defaultValue={profile}
    //             // inputDefaultValue={current_user && current_user.username}
    //             // inputValue={inputValue}
    //             // onInputChange={(_, newInputValue) => {
    //             //   setInputValue(newInputValue)
    //             // }}
    //             // defaultValue={_.find(real_users, (u) => u.id == user_id)}
    //             sx={{ width: "50%", m: 2 }}
    //             renderInput={(params) => <TextField {...params} label="Username Selection" />}
    //         />

    //         <TextField
    //             id="count-number"
    //             label="Beta Use Count"
    //             type="number"
    //             autoFocus={false}
    //             defaultValue={snap_count}
    //             onChange={(e) => cheddarStore.codeCount = e.target.value}
    //             InputLabelProps={{
    //                 shrink: true,
    //             }}
    //         />
    //         <TextField
    //             id="initial-token-number"
    //             label="Initial Cheddar"
    //             type="number"
    //             autoFocus={false}
    //             defaultValue={snap_cheddar}
    //             onChange={(e) => cheddarStore.initialCheddar = e.target.value}
    //             InputLabelProps={{
    //                 shrink: true,
    //             }}
    //         />
    //         <FormControl fullWidth>
    //             <InputLabel id="select-account">Account Type</InputLabel>
    //             <Select
    //                 labelId="select-account-label"
    //                 id="select-account-sel"
    //                 defaultValue={snap_account}
    //                 label="Account Type"
    //                 onChange={(e) => {
    //                     cheddarStore.accountType = e.target.value
    //                 }}
    //             >
    //                 <MenuItem value={'full'}>Full</MenuItem>
    //                 <MenuItem value={'nomodel'}>No model</MenuItem>
    //             </Select>
    //         </FormControl>

    //     </Box>
    //     <Button sx={{ m: 2 }} variant="contained" onClick={() => {
    //         // console.log("Create new code")
    //         const select_id = cheddarStore.selected ? cheddarStore.selected.id : profile.id

    //         createBetaCode({
    //             access_token,
    //             user_id: select_id,
    //             enqueueSnackbar,
    //             code_count: cheddarStore.codeCount,
    //             token_count: cheddarStore.initialCheddar,
    //             account_type: cheddarStore.accountType,
    //         }).then((data) => {
    //             // fetch new 
    //             cheddarStore.hasMore = true
    //             return fetchCheddarTransactions({ beta_codes: snap_codes, cheddarStore, user_id, access_token, enqueueSnackbar })
    //         }).catch((err) => {
    //             console.log("Error creating code:", err)
    //         })
    //     }}> <AddIcon /> Create New Beta Code</Button>
    //     <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
    //         <Table aria-label="simple table">
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>User</TableCell>
    //                     {/* <TableCell>User ID</TableCell> */}
    //                     <TableCell >Code (Click Copy)</TableCell>
    //                     <TableCell >Remaining</TableCell>
    //                     <TableCell >Total</TableCell>
    //                     <TableCell >Initial Tokens</TableCell>
    //                     <TableCell >Adjust</TableCell>
    //                     <TableCell >Created At</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {/* <List> */}
    //                 {snap_codes.map((code) => {
    //                     return <CheddarListItem
    //                         enqueueSnackbar={enqueueSnackbar}
    //                         adjustCodeCount={adjustCodeCount}
    //                         testUseBetaCode={testUseBetaCode}

    //                         key={code.code_id} code={code} failedCodeCopy={(code_url) => {
    //                             cheddarStore.failCode = code_url
    //                             enqueueSnackbar("Failed to Copy Code Link to Clipboard. See top line. ", { variant: 'warning' })
    //                         }} />
    //                     // return <ListItem key={code.code_id}>
    //                     // <ListItemText primary={code.code_id} />
    //                     // </ListItem>
    //                 }
    //                 )}
    //                 {/* </List> */}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // </Box>

}


export default CheddarActionsComponent

export {
    cheddar_api,
    cheddarStore,
    fetchCheddarTransactions,
}