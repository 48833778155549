import React from 'react'
import { useSnapshot, proxy } from 'valtio'
import createUploader, { UPLOADER_EVENTS } from "@rpldy/uploader";
import retryEnhancer from "@rpldy/retry";
import { supabase } from "../sb"
import axios from 'axios'
import _ from 'lodash'
const img_data = {
    global: proxy({})
}
const getImageData = (name) => {
    if (img_data.global[name] == null)
        img_data.global[name] = {
            images: {},
            uploaded: 0,
            files: [],
            crops: {}
        }
    return img_data.global[name]
}

const getAllImageData = () => {
    return img_data.global
}


const upload_data = {}
const getImageUploadData = (name) => {
    if (upload_data[name] == null)
        upload_data[name] = proxy({
            images: {},
        })
    return upload_data[name]
}


let page_data = null
const _inner_page_data = {}
const getDefaultPageData = (view_data, index) => {
    const data_name = view_data.name
    if (_inner_page_data[data_name] == null) {
        _inner_page_data[data_name] = {
            index,
            name: view_data.name,
            label: view_data.label,
            completed: view_data.completed || false,
            state: {}
        }
    }
    return _inner_page_data[data_name]
}

const getPageDataProxy = (views) => {
    if (page_data == null) {
        page_data = proxy(_.keyBy(_.map(views, getDefaultPageData), 'name'))
    }
    return page_data
    // if (page_data[data_name] == null) {
    //     page_data[data_name] = proxy({
    //         ...def_data
    //     })
    // }
    // return page_data[data_name]
}

const user_auth = proxy({
    user: null,
    token: null,
    is_logged_in: false,
    is_admin: false,
    is_superuser: false,
    is_staff: false,
    is_active: false,
})

const _local_session_data = proxy({
    session: null
})

const getSessionData = ({ session_id }) => {
    if (session_id == null)
        return _local_session_data

    // check for any changes in the auth data
    let user_data = window.__USER_DATA__
    let local_name = `afh_session_data`
    if (user_data == null) {
        console.error("No session data found")
        return null
    }

    let sess_data = localStorage.getItem(local_name)
    if (sess_data != null)
        sess_data = JSON.parse(sess_data)

    if (sess_data == null) {
        // we don't have auth data so we create it 
        sess_data = {
            sessions: {},
        }
        localStorage.setItem(local_name, JSON.stringify(sess_data))
    }

    // // now we should have auth data loaded
    // // let's check if we have a user_id yet
    // if (sess_data.user_id == null) {
    //     sess_data.user_id = user_data.user_id
    //     localStorage.setItem(local_name, JSON.stringify(sess_data))
    // }

    // we have authorization data
    // let's extend it with the new session info
    if (!sess_data.sessions[session_id]) {
        // we extend and save
        sess_data.sessions[session_id] = user_data
        localStorage.setItem(local_name, JSON.stringify(sess_data))
    }
    else {
        // we have the auth data, this will now be the user_data
        user_data = sess_data.sessions[session_id]
    }

    // now user_data should be the saved object,
    // lets load into our proxy and send it back
    _local_session_data.session = user_data
    // _local_session_data.user_id = sess_data.user_id

    return _local_session_data
}
const getAuthData = () => {
    return user_auth
}

const _uploaders = {}
const getImageUploader = (url, options) => {
    if (_uploaders[url] == null) {
        console.log("Creating uploader for:", url)
        _uploaders[url] = {
            upload: createUploader({ destination: { url }, forceJsonResponse: true, ...options }),
            destination_url: url
        }
    }
    return _uploaders[url]
}

const getMetaUploader = (url, options) => {
    return axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        ...options
    })
}


const _sb_auth_session = { global: null, login: null }

const useAuthSession = () => {
    if (_sb_auth_session.global == null) {
        _sb_auth_session.global = proxy({ session: {} })
    }
    const psession = _sb_auth_session.global
    const { session } = useSnapshot(psession)

    React.useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            console.log("Session updated;", session)
            psession.session = session
        })

        supabase.auth.onAuthStateChange((_event, session) => {
            psession.session = session
            console.log("Session auth change:", session)
        })

    }, [])
    return session
}
const getLoginDetails = () => {
    if (_sb_auth_session.login == null) {
        _sb_auth_session.login = proxy({ username: '', email: '', password: '', signUpLoading: false, submittedEmail: null, acceptedTerms: false })
    }
    return _sb_auth_session.login
}

const clearUserSession = () => {
    var sb_keys = _.filter(Object.keys(localStorage),
        (k) => { return k.startsWith("sb-") && k.includes('auth-token') })
    _.each(sb_keys, (k) => {
        localStorage.removeItem(k)
    })
    _sb_auth_session.global.session = {}
}

export {
    clearUserSession,
    useAuthSession,
    getLoginDetails,
    getImageUploader,
    getMetaUploader,
    getAuthData,
    getSessionData,
    getDefaultPageData,
    getPageDataProxy,
    getImageData,
    getAllImageData,
    getImageUploadData
}