import React from 'react'
import Card from '@mui/material/Card';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // elevation: 5,
    maxWidth: 512,
    margin: 6,
    border: `2px solid rgba(0, 76, 153, .6)`,
    borderRadius: 10,
    width: "100%",
    backgroundColor: theme.palette.card.background// "#121926"
    // backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"

    // padding: 10,
    // borderThickness: 2,
    // borderColor: "rgb(0, 76, 153)",
    // backgroundColor: "rgba(0, 58, 117, 0.2)"
}));


const MainImageCard = styled(Card)(({ theme }) => ({
    backgroundImage: "none",
    borderRadius: "20px",

    backgroundColor: theme.palette.card.background// "#121926"
}))

export {
    StyledCard,
    MainImageCard
    // FullContainer,
    // FullStackStart,
    // FullStackBetween
}