
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useAdminUIState } from '../supastore';
import { styled } from '@mui/material/styles';

const StyledDrawer = styled(Drawer)(({ theme }) => ({


}))
const drawerWidth = 240

export default function SwipeableTemporaryDrawer({ sx = {}, pages, active_page, toggleAdminDrawer, selectAdminDrawerItem }) {
    const { drawerOpen } = useAdminUIState()
    // const [state, setState] = React.useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    // });
    const anchor = 'left';

    // const toggleDrawer =
    //     (anchor, open) =>
    //         (event) => {
    //             if (
    //                 event &&
    //                 event.type === 'keydown' &&
    //                 (event.key === 'Tab' ||
    //                     event.key === 'Shift')
    //             ) {
    //                 return;
    //             }

    //             setState({ ...state, [anchor]: open });
    //         };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleAdminDrawer}
            onKeyDown={toggleAdminDrawer}
        >
            {pages.map((page_list, page_list_idx) => {
                console.log("Page list:", page_list)
                return <div key={'dlist_' + page_list.group_name}>
                    <List key={'list_' + page_list.group_name}>
                        {page_list.pages.map((pdata, pindex) => (
                            <ListItem key={'li_' + pdata.name} disablePadding>
                                <ListItemButton
                                    selected={active_page === pdata.name}
                                    onClick={() => { return selectAdminDrawerItem(pdata.name) }}>
                                    <ListItemIcon>
                                        {pindex % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={pdata.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </div>
            })}
        </Box>
    );

    return (
        <StyledDrawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                ...sx
            }}

            variant="temporary"
            anchor={anchor}
            open={drawerOpen ? drawerOpen : false}
            onClose={toggleAdminDrawer}
        // onOpen={toggleAdminDrawer}
        >
            {list(anchor)}
        </StyledDrawer>
    );
}

export {
    drawerWidth
}