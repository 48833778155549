import React from 'react';
import Button from '@mui/material/Button';
import _ from 'lodash'
import { ExpositionText, MediumTitle } from "./styled"
import OnboardingLayout from './layout';

const Guidelines = ({ handleNext, data_state }) => {

    const footerButtons = () => {
        return <Button
                sx={{width: "90vw", maxWidth: "1000px", textTransform: "none"}}
                variant="contained"
                size="large"
                onClick={() => handleNext()}
            >
                Start maturing my cheese
            </Button>
    }

    return (
        <OnboardingLayout footerComponent={footerButtons()} title="Get Ready">
            <ExpositionText>
            Tips for radical AI success - the best pix for Cheesin’ are:
            </ExpositionText>
            <ExpositionText>
            ✅ ... of you and no one else!
            <br />
            ✅ ... of you making different faces
            <br />
            ✅ ... in different places
            <br />
            ✅ ... at different times of day
            <br />
            ✅ ... looking every which way, not just into the camera!
            </ExpositionText>
            <ExpositionText>
            ❌ No nudie pix!
            <br />
            ❌ No crappy blurry pix!
            <br />
            ❌ Don't crop too close!
            <br />
            ❌ NOBODY ELSE IN YER PIX!
            <br />
            ❌ Don't just upload 20 duckface pix, Tammy! VARIETY!
            </ExpositionText>
        </OnboardingLayout>
    );
}

export default Guidelines;