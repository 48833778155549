import React from 'react'
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        "fontFamily": `"Inter", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    backgroundColor: "#111926",
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: "#202939",
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: "#202939",
                    boxShadow: "0px 2px 1px -1px #697586, 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
                    // bottomBorderColor: "#697586",
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#202939",
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#f2f4f7",
                }
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    width: "100%",
                    backgroundColor: '#7f56d9'
                }
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: 'white',
                    borderRadius: "5",
                    '&.Mui-selected': {
                        padding: "5",
                        color: '#7f56d9',
                        backgroundColor: '#fff',
                    }
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#7f56d9',
            contrastText: "#f2f4f7",
        },
        secondary: {
            main: '#e9d7fe',
            contrastText: "#f2f4f7",
        },
        cheddar: {
            main: "#fdb022"
        },
        body: {
            main: '#202939',
            contrastText: "#f2f4f7",
        },
        card: {
            background: "#111926",
        },
        background: {
            default: '#111926',
            box: "#111926",
            paper: "#111926",
            main: '#202939',
            home: '#202939',
            root: "#202939",
            contrastText: "#FFF",
            accent: '#53389E',
            divider: "#697586",
        },
        text: {
            primary: '#fff',
            secondary: '#a3a3a3',
        },
        disco: {
            main: '#e20',
            color: "#FFF",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        white: {
            main: '#FFF',
            color: "#000",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        title: {
            main: 'hsla(0, 0%, 100%, .5)',
            color: "hsla(0, 0%, 100%, .5)",
            contrastText: 'rgba(0, 0, 0, 0.87)',
        },
        working: {
            // kind of a meh color, reddish 
            main: '#ee220033',
        },
        good: {
            // better, towrads yellow
            main: '#ee22ee55',
        },
        great: {
            // better, towards green
            main: '#22ee22AA',
        },
        perfect: {
            // best, towards blue
            main: '#2222eeFF',
        },
        cheese: {
            main: '#FEC84B',
            color: "#000",
            contrastText: "#000",
            hover: '#FDB022',
        },
        grey: {
            main: "#98A2B3",
            contrastText: "#667085",
        },
        editor: {
            fontFamily: "Inter, Poppins, Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.4375em",
            color: "rgb(255, 255, 255)",
            boxSizing: "border-box",
            cursor: "text",
            // display: "inline-flex",
            // align-items: "center",
            // position: "relative",
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            // borderBottom: "2px solid rgb(127, 86, 217)",
            transition: "background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            padding: "12px 12px 8px",
            borderBottom: "2px solid rgb(127, 86, 217)",
            minHeight: "140px",
            maxHeight: "220px",
            overflow: "auto",
            // before:
            // "&.Mui-selected": {
            //     backgroundColor: "green",
            //     "&.Mui-focusVisible": { background: "orange" }
            // },
            // '&:focus': {
            //     transform: 'scaleX(1) translateX(0px)',
            //     borderBottom: "2px solid rgb(127, 86, 217)",
            // },
            // '&:before': {
            //     borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
            //     transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            //     pointerEvents: "none",
            // },
            // // after:
            // '&:after': {
            //     borderBottom: "2px solid rgb(127, 86, 217)",
            //     transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            // }
        }
    },
    status: {
    },
});

export default theme;