import { getUserModels, getBaseUrl } from "../components/supastore";
import axios from "axios";

const inference_api = axios.create({ baseURL: `${getBaseUrl()}/api/inference/` })

const random_seed = () => {
  return Math.floor(Math.random() * 2 ** 32)
}
const hasTokenMatch = (prompt) => {
  const temp_matches = prompt.match(/\b[Mm][Ee]\b/g)
  let has_at_least_one_me = temp_matches && temp_matches.length > 0
  has_at_least_one_me = has_at_least_one_me || prompt.includes("<token>")
  return has_at_least_one_me
}

const copyCleanPromptForUI = (img_metadata) => {
  let prompt = img_metadata.tokenized_prompt ? img_metadata.tokenized_prompt : img_metadata.prompt
  // if (!img_metadata.tokenized_prompt) {
  prompt = prompt.replaceAll("yegfdn person", "me").replaceAll("<token>", "me");
  // } else {
  // prompt = img_metadata.tokenized_prompt.replaceAll("yegfdn person", "me").replaceAll("<token>", "me");
  // }

  // add negative prompt to copy
  if (img_metadata.negative_prompt) {
    prompt = prompt + " //" + img_metadata.negative_prompt;
  }

  return prompt
}

const processPrompt = (prompt, remix_image_id) => {
  if (!hasTokenMatch(prompt)) {
    // let user know that was a good
    // TODO: show through toast plz and also add text verification step
    console.error("Please include at least 1 'me' in the prompt")
    if (remix_image_id != null)
      return { error: "Error in Do Me, missing token" }
    // enqueueSnackbar("Error in Do Me, missing token", { variant: 'error' });
    else
      return { error: "Please include at least 1 'me' in the prompt" }
    // enqueueSnackbar("Please include at least 1 'me' in the prompt", { variant: 'error' });
    return
  }
  let negative_prompt = null
  // attempt to do negative input check 
  const neg_match = prompt.match(/\/\/(.*)/g)
  if (neg_match && neg_match.length > 0 && neg_match[0].length > 0) {
    // we do have neg input 
    negative_prompt = neg_match[0].replace("//", "").trim()
    prompt = prompt.replace(neg_match[0], "").trim()
    if (hasTokenMatch(negative_prompt)) {
      // no tokens in negative prompts!
      return { error: "Please remove 'me' from negative prompt!" }
      // enqueueSnackbar("Please remove 'me' from negative prompt!", { variant: 'error' });
      // return
    }
    if (!hasTokenMatch(prompt)) {
      // missing token in regular prompt!
      // enqueueSnackbar("Please include at least 1 'me' in the prompt", { variant: 'error' });
      return { error: "Please include at least 1 'me' in the prompt" }
      // return
    }
    // remove the negative prompt from the prompt
    // console.log("Nprompt:", negative_prompt, " match::", neg_match, new_prompt)
  }
  let positive_prompt = prompt.replaceAll(/\byegdfn person\b/g, "<token>").replaceAll(/\b[Mm][Ee]\b/g, "<token>")
  return { positive_prompt, negative_prompt }
}

const queueRequest = ({ prompt, remix_image_id, seed = null, auth, user_id, enqueueSnackbar, successFct, failFct }) => {
  if (!auth || !auth.access_token) {
    console.error("Not logged in")
    return
  }
  // // console.log(prompt, seed);
  // if (remix_image_id != null) {
  //   console.log("remixing image", remix_image_id, " prompt: ", prompt);
  //   const has_at_least_one_me = prompt.includes("<token>") || prompt.includes('yegdfn person')
  //   if (!has_at_least_one_me) {
  //     console.error("Please include at least 1 'me' in the prompt")
  //     enqueueSnackbar("Error in Do Me, missing token", { variant: 'error' });
  //     return
  //   }
  // }
  // else {
  // do multiple regex matched for \b[Mm][Ee]\b, checking for the word me in any form 

  // const temp_matches = prompt.match(/\b[Mm][Ee]\b/g)
  // let has_at_least_one_me = temp_matches && temp_matches.length > 0
  // if (remix_image_id != null) {
  //   has_at_least_one_me = has_at_least_one_me || prompt.includes("<token>")
  // }
  // if you're remixing, you can ignore the limitations, it should have <token>

  // }
  const { error, positive_prompt, negative_prompt } = processPrompt(prompt, remix_image_id)
  if (error) {
    enqueueSnackbar(error, { variant: 'error' });
    return
  }

  getUserModels(user_id).then((models) => {
    console.log("User models to run on:", models)
    if (models.data.length == 0) {
      // TODO: show through toast plz and also add text verification step
      console.error("No models to run on, plz retry")
      return
    }
    const session_id = models.data[0].queue_id
    const prompt_text = positive_prompt

    console.log("Prompt text with tokens replaced: ", prompt_text)
    const prompts = [
      { prompt: prompt_text, seed: seed ?? random_seed(), cfg_scale: 7.5, negative_prompt }
    ]
    // enqueueSnackbar("Dev Ignore submit testing", { variant: "error", autoHideDuration: 3000 })
    // return
    // console.log("Sending prmpts for inference: ", prompts, "for user and session:", user_id, "sess", session_id)
    inference_api.post(`/${user_id}/${session_id}/inference`, { access_token: auth.access_token, remix_image_id, prompts }).then(res => {
      console.log("Inference successfully sent! ", res)
      enqueueSnackbar("Sent! Images will be ready soon", { variant: "success", autoHideDuration: 3000 })
      if (successFct) successFct()
    }).catch(err => {
      console.log("Inference post failed: ", err)
      if (err.response && err.response.status == 409) {
        enqueueSnackbar("You already remixed that!", { variant: "error", autoHideDuration: 3000 })

      }
      else if (err.response && err.response.status == 402) {
        const inf_type = remix_image_id ? "remix" : "create"
        const msg = `You don't have enough tokens to ${inf_type} that image!`
        enqueueSnackbar(msg, { variant: "error", autoHideDuration: 3000 })
      }
      else {
        enqueueSnackbar("Uh oh, failed to send, try again shortly", { variant: "error", autoHideDuration: 3000 })
      } if (failFct) failFct()
    })
  })

}

export { queueRequest, processPrompt, hasTokenMatch, copyCleanPromptForUI };