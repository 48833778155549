import React, { useState } from 'react';
import TitleBar from "../components/titleBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import BottomNav from "../components/bottomNav";
import InfiniteImageList from "../components/styled/infiniteImageList";
import { FullContainer, InnerContainer } from "../components/styled/containers"
import { useAuthSession, getBaseUrl, getUserFeed, getUserFeedStore, useAdminAuthSession, getAdminUIStateStore } from "../components/supastore"
import _ from "lodash"
import { useSnackbar } from 'notistack'
import axios from 'axios';
import { getWrappedActions } from '../components/profile/actions';
import { useSnapshot } from "valtio"
import Admin, { admin_pages } from "../components/admin"
import AdminDrawer, { drawerWidth } from "../components/admin/drawer"
import { styled } from '@mui/material/styles';

const StyledAdmin = styled(Admin, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const StyledTitleBar = styled(TitleBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const StyledBottomNav = styled(BottomNav, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MainEntry = () => {
    // user_id should come from auth provider
    const { isAuthenticated, auth, user_id } = useAuthSession();
    const { is_admin, is_super_admin, privileges } = useAdminAuthSession()
    const ui_state = getAdminUIStateStore()
    const { adminPage, drawerOpen } = useSnapshot(ui_state)

    return (
        // <Box sx={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     // minHeight: '100vh',
        //     // height: "calc(100% - 80px)",
        //     // flex start to top of page 
        //     alignItems: 'flex-start',
        //     justifyContent: 'center',
        //     height: "100%",
        //     width: '100%',
        //     overflow: 'auto',
        //     pb: "80px",
        //     // position: 'relative',
        //     // overflow: 'hidden',
        // }}>
        <FullContainer
            // ref={scrollRef}
            sx={{
                // display: 'flex',
                // alignItems: "flex-start",
                // justifyContent: "center",
                // flexDirection: "column",
                // width: "100%",
                // height: "100%",
                // overflowY: "auto",
                // overflow: "auto",
            }}>

            <TitleBar open={drawerOpen} />
            <InnerContainer sx={{
                display: 'flex',
                flexDirection: 'row',
                // display: 'flex',
                // overflow: "auto",
                // height: isAuthenticated ? "calc(100% - 70px)" : "100%"
            }}
            >
                <AdminDrawer
                    pages={admin_pages}
                    active_page={adminPage}
                    toggleAdminDrawer={() => {
                        // set ui_state
                        ui_state.drawerOpen = ui_state.drawerOpen ? false : true
                    }}
                    selectAdminDrawerItem={(item) => {
                        // console.log("Select Admin Page:", item)
                        ui_state.adminPage = item
                    }}
                />
                {/* <StyledInnerContainer > */}
                <Admin open={drawerOpen} />
                {/* </StyledInnerContainer> */}
            </InnerContainer>
            <BottomNav selectedIndex={3} open={drawerOpen} />
        </FullContainer>
    );
}
export default MainEntry

// const AdminFeed = ({ userId }) => {
//     const { isAuthenticated, auth, user_id } = useAuthSession();
//     const { is_admin, is_super_admin, privileges } = useAdminAuthSession()
//     return <Admin />
// }

