// concept from: https://mui.com/material-ui/react-stepper/
import React from 'react';
import Box from '@mui/material/Box';
import { useParams, useSearchParams } from 'react-router-dom'
import { useAuthSession } from "./store"
// import { useSnapshot, proxy } from 'valtio'
import _ from "lodash"

const DreamTrainInterface = ({ }) => {
    const { session_id } = useParams()
    const [searchParams] = useSearchParams()
    const search_session_id = searchParams.get("session_id")
    console.log("Session id:", session_id,
        'search:', search_session_id)
    const session = useAuthSession()
    console.log("Wait on session:", session)
    return <Box sx={{}}>
        <h1>Waiting on Dream</h1>
        <h2>Session ID: {session_id}</h2>
    </Box>
}
export default DreamTrainInterface;