import React from 'react';
import TitleBar from "../components/titleBar"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FullContainer, InnerContainer } from "../components/styled/containers"
import { useTheme } from "@mui/material/styles";
import BottomNav from '../components/bottomNav';


const SupportLink = ({ theme }) => {
    return <a style={{ color: theme.palette.secondary.main }} href='mailto:support@cheese.baby'>support@cheese.baby</a>
}


const getFaqs = ({ theme }) => {

    return [
        { question: "Is it 'Cheese' or 'Cheese Baby'?", answer: "It's just Cheese, baby." },
        { question: "How does this work?", answer: "You give us just a few images, and we train an AI to make images of you in any context you can imagine!" },
        {
            question: "But how does this really work?", answer: ["Currently, Cheese images are generated using ",
                <a style={{ color: theme.palette.secondary.main }} href="https://stability.ai/blog/stable-diffusion-public-release">Stable Diffusion</a>,
                " an open-source AI text-to-image model created by Stability AI, Huggingface, and CoreWeave. We are using an additional fine-tuning technique on top of Stable Diffusion known as ",
                <a style={{ color: theme.palette.secondary.main }} href="https://dreambooth.github.io/">DreamBooth</a>,
                " to 'teach' Stable Diffusion what you look like."]
        },
        { question: "Who can see my images?", answer: "By default, every image you make with Cheese is private and only visible to you. If you have an image you'd like to share, you can do that two ways:\n1) You can make the image Public, which posts it to your profile, adds it to your followers' feeds, adds it to the main Cheese feed, and makes it visible on the internet, including for people not logged in to Cheese.\n2) You can share a Private Link to your image without making it public. Anybody with that link can see the image, whether they're logged in or not, but it won't be visible to search engines, won't show up on your Cheese profile, and won't be visible in the main Cheese feed." },
        { question: "Why do I get free Cheddar from time to time?", answer: "When you create an image with an original prompt (on the Generate page) and then make that prompt public, other users can hit the 'Do Me' button on your image to try a version with themselves in it. When they do that, you get free Cheddar for making something other people want. If you get a viral hit you could be Cheesin' free for a good while!" },
        { question: "Why are some of my images better than others?", answer: "This AI tech is brand-new, bleeding-edge stuff. It's not perfect, and you often get something different from what you were expecting. But it is getting better every day, and we'll keep incorporating the latest and greatest techniques as they come out.\nWith that said, you can get better results right now simply by experimenting with the phrasing of your prompts. This is called 'prompt engineering', and it's an art in and of itself. For some inspiration, here's a [quick overview](https://www.howtogeek.com/833169/how-to-write-an-awesome-stable-diffusion-prompt/) of what is a VERY deep subject.\nSince you're clearly serious, some pro tips for you:\n• Be specific! Longer, more specific prompts are often better.\n• Make sure 'me' is early on in the prompt - things earlier in the prompt tend to get more consideration in the image, so make sure you're front and center.\n• 'Negative prompts' are things you _don't_ want in the image, and you can list them after a '//' in your normal prompt. If, for example, you're finding that you tend to look sleepy in your images, you could write something like 'me, alert, looking handsome, nice face, 35mm photo // sleepy' and that might clear it up. Negative prompts are very powerful! Get creative!\n• If you ask the AI to depict you in a really outlandish situation, you might find the AI struggles. If you do want to make a picture with some really crazy stuff happening, be as descriptive as you can be - **longer prompts seem to work better.**\n• If you ask the AI to depict you 'as' someone who looks wildly different from you, you might get a result that doesn't look much like either you or that person. Don't let that stop you from trying, though!" },
        { question: "Is my data safe with Cheese?", answer: "We completely understand any concerns about privacy, security, and safety you have. Your images are private by default, and other users will never be able to make images of you without your permission.\nWe will never sell your data. At Cheese, our business is selling images, not data - that's why Cheddar isn't free.\nWe have a zero-tolerance policy for hate, racism, and harassment of any kind.\nWe take your privacy very seriously and we want Cheese to be a safe place for people to have fun and get creative." },
        { question: "I'm having a problem with Cheese. How can I get help?", answer: ["Reach out to us at ", <SupportLink theme={theme} />, " and we'll get you squared away!"] }
    ]
}
const MainEntry = ({ }) => {
    const theme = useTheme()
    const faqs = getFaqs({ theme })
    // let params = useParams();
    // we'll need to get auth data 
    const getFAQElements = () => {
        return <div>
            {faqs.map((item, idx) => {
                return <Accordion key={`faq_${idx}`}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${idx}_panel_content`}
                        id={`${idx}_panel_header`}
                    >
                        <Typography>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {item.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })}
        </div>
    }

    return <FullContainer>
        <TitleBar />
        <InnerContainer>
            <Stack sx={{ p: 1 }} spacing={2}>
                <Typography variant="h4" textAlign="center">Need support?</Typography>
                <Typography variant="subtitle1" textAlign="center">Check out the FAQs below, or reach out to <SupportLink theme={theme} /> for more help</Typography>
                {getFAQElements()}
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:support@cheese.baby`}
                >
                    I need more help!
                </Button>
            </Stack>
        </InnerContainer>
    </FullContainer>
}

export default MainEntry