import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { useAuthSession } from '../supastore';
import { cheddarSvgOutlineString } from "./svgIcon";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getBaseUrl } from "../supastore"
import axios from 'axios';

const base_api = axios.create({
    baseURL: `${getBaseUrl()}/api/`,
    headers: {
        'Content-Type': 'application/json'
    },
});

const PriceButton = ({ price, onClick }) => {
    const theme = useTheme();

    return <Box
        onClick={onClick}
        sx={{
            padding: "16px",
            textAlign: "left",
            position: 'relative',
            alignItems: "center",
            width: '100% !important',
            color: 'black', //price.isPreferred ? theme.palette.primary.contrastText : "black",
            background: "#F9F5FF", //price.isPreferred ? theme.palette.primary.main : "#F9F5FF",
            border: '1px solid #E9D7FE',
            borderRadius: "8px",
            '&:hover': {
                backgroundColor: "#E9D7FE", // price.isPreferred ? "#53389E" : "#E9D7FE",
                borderColor: "#F9F5FF",
            }
        }}>
        <Grid container>
            <Grid item xs={11} alignItems="center" justifyContent="start" sx={{ my: "auto" }}>
                {/* <Typography variant="body1" sx={{ color: price.isPreferred ? theme.palette.primary.contrastText : "black" }}>{price.description} - {price.value}</Typography> */}
                <Typography variant="body1" sx={{ color: "black" }}>{price.description} — {price.value}</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" sx={{ my: "auto" }}>
                <KeyboardDoubleArrowRightIcon />
            </Grid>
        </Grid>
    </Box>
}

const PurchaseDrawer = ({ open = false, onClose = null, anchor = "bottom" }) => {
    const [prices, setPrices] = useState([]);
    const [loadingPrices, setLoadingPrices] = useState(false);
    const { profile, auth } = useAuthSession();
    const {enqueueSnackbar} = useSnackbar();


    const createPaymentForPrice = async (price) => {
        console.log(price)
        base_api.post("/payments/purchase-cheddar", { product_qualifier: price.type, access_token: auth.access_token })
            .then(({ data }) => {
                console.log("Cheddar purchase return success:", data)
                if (data.error) {
                    enqueueSnackbar(data.error, {variant: "info"});
                    onClose();
                }
                else if (data.is_free) {
                    onClose();
                }
                else {
                    window.location.href = data.checkout_url;
                }
                // set the stripe secret and off we go
                // setCheckoutUrl(res.data.client_secret);
            }).catch(err => {
                console.log("Cheddar purchase return error:", err)
                enqueueSnackbar(err, {variant: "error"});
                onClose();
            })

    }

    const getPrices = async () => {
        if (auth && auth.access_token) {
            setLoadingPrices(true);
            base_api.post(`/user/products`, { access_token: auth.access_token })
                .then((res) => {
                    // console.log(res);
                    setPrices(res.data.map((p) => {
                        return {
                            type: p.type,
                            value: p.unit_price_string,
                            description: p.description,
                            isPreferred: p.type == "medium",
                        }
                    }));
                })
                .catch((error) => {
                    console.log("Price fetch error: ", error)
                })
                .finally(() => {
                    setLoadingPrices(false);
                })
        }
    }

    useEffect(() => {
        getPrices();
    }, [auth])


    return (
        <Drawer anchor={anchor} onClose={onClose} open={open}>
            <Box sx={{
                width: "100%",
                p: 3,
            }}>
                <IconButton variant='contained' onClick={onClose} sx={{ float: "right" }}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4">Cheddar <img height="45px" src={`data:image/svg+xml;base64,${btoa(cheddarSvgOutlineString)}`} alt="cheddar" /></Typography>
                <Typography variant="h4">Makes it better</Typography>
                <Box sx={{ py: 2 }}>
                    <Typography variant="body1">Use Cheddar to make images.</Typography>
                    <Typography variant="body1">Earn Cheddar when others remix your prompts.</Typography>
                </Box>
                <Stack spacing={2} alignContent="center">
                    {loadingPrices ? (
                        <>
                            <CircularProgress />
                            <Typography variant="body1">Loading prices</Typography>
                        </>
                    ) : (
                        prices.map((p, idx) => {
                            return <PriceButton key={`p_${idx}`} price={p} onClick={() => { createPaymentForPrice(p) }} />
                        })
                    )}
                </Stack>
                <Box sx={{ paddingTop: 2, alignItems: "center", textAlign: "center" }}>
                    <Typography variant="subtitle1">60 Cheddar = 1 Generation (9 pics)</Typography>
                    <Typography variant="subtitle1">Current balance: <b>{profile ? profile.token_count ?? 0 : 0}</b></Typography>
                </Box>
                <Box sx={{ paddingTop: 2, alignItems: "center", textAlign: "center" }}>
                    <Typography variant="subtitle1">
                        <Link href="/terms" style={{ textDecoration: 'none' }}>Terms of Service</Link>
                        {" • "}
                        <Link href="/privacy" style={{ textDecoration: 'none' }}>Privacy Policy</Link>
                    </Typography>
                </Box>
            </Box>
        </Drawer>
    )
}


export default PurchaseDrawer