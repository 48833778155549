import React, {
    ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { EditorState } from 'draft-js';
import mentionStyles from './mention.module.css';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import { useTheme } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useSnapshot } from 'valtio'

const mentions = [
    {
        name: 'Mentions coming soon!',
        link: 'https://twitter.com/comingsoon',
        avatar: ''
    },
];

// export default mentions;

// import editorStyles from './SimpleMentionEditor.module.css';
// import mentions from './Mentions';

export default function SimpleMentionEditor({ editorStateProxy, ...props }) {
    const ref = useRef(null);
    const theme = useTheme();
    // console.log("Theme:", theme)

    const { editorState } = useSnapshot(editorStateProxy)
    const setEditorState = (val) => {
        editorStateProxy.editorState = val
    }

    // const [editorState, setEditorState] = useState(() =>
    //     EditorState.createEmpty()
    // );
    const [open, setOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(mentions);

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: mentionStyles,
            mentionPrefix: '@',
            entityMutability: 'IMMUTABLE',
            supportWhitespace: true,
        });
        // Creates an Instance. At this step, a configuration object can be passed in
        // as an argument.
        // const hashtagPlugin = createHashtagPlugin();
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);
    const onSearchChange = useCallback(({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions));
    }, []);

    return (
        <div
            // style={theme.palette.editor}
            className={'some-class'}
            onClick={() => {
                if (ref.current)
                    ref.current.focus();
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    // h1: { color: theme.palette.primary.main },
                    // h2: { color: "green" },
                    ".some-class": { ...theme.palette.editor }
                })}
            />
            <Editor
                editorKey={'editor'}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                ref={ref}
                onFocus={() => {
                    // setHidePlaceholder(true);
                }}
                {...props}
            />
            <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                onAddMention={(mm) => {
                    // get the mention object selected
                    console.log('mention', mm);
                    editorStateProxy.mentions.push(mm)
                }}
            />
        </div>
    );
}

// import React from 'react';
// import Editor from "@draft-js-plugins/editor";
// import TextField from '@mui/material/TextField';

// const DraftField = React.forwardRef(function DraftField(props, ref) {
//     const { component: Component, editorRef, handleOnChange, ...rest } = props;

//     React.useImperativeHandle(ref, () => ({
//         focus: () => {
//             editorRef?.current?.focus();
//         },
//     }));

//     return <Component {...rest} ref={editorRef} onChange={handleOnChange} />;
// });

// const DraftEditor = ({ }) => {
//     const [editorState, setEditorState] = React.useState();
//     const editorRef = React.useRef(null);

//     const handleOnChange = (newEditorState) => {
//         setEditorState(newEditorState);
//     };

//     return (
//         <TextField
//             fullWidth
//             label="Content"
//             InputProps={{
//                 inputProps: {
//                     component: Editor,
//                     editorRef,
//                     editorState,
//                     handleOnChange
//                 },
//                 inputComponent: DraftField,
//             }}
//         />
//     );
// };
// export default DraftEditor;

// // import React from 'react';
// // import { Editor } from 'react-draft-wysiwyg';

// // const EditorWithMentionHashtag = ({ height }) => (
// //     <Editor
// //         style={{ height: height }}
// //         className={'MuiInputBase-root MuiInputBase-colorPrimary text css-t7o8fi-MuiInputBase-root'}
// //         wrapperClassName="editor-wrapper"
// //         editorClassName="MuiInputBase-root MuiInputBase-colorPrimary text css-t7o8fi-MuiInputBase-root"
// //         toolbar={{
// //             options: []
// //         }}
// //         mention={{
// //             separator: ' ',
// //             trigger: '@',
// //             suggestions: [
// //                 { text: 'APPLE', value: 'apple', url: 'apple' },
// //                 { text: 'BANANA', value: 'banana', url: 'banana' },
// //                 { text: 'CHERRY', value: 'cherry', url: 'cherry' },
// //                 { text: 'DURIAN', value: 'durian', url: 'durian' },
// //                 { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
// //                 { text: 'FIG', value: 'fig', url: 'fig' },
// //                 { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
// //                 { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
// //             ],
// //         }}
// //         hashtag={{
// //             separator: ' ',
// //             trigger: '#',
// //             suggestions: [
// //                 { text: 'APPLE', value: 'apple', url: 'apple' },
// //                 { text: 'BANANA', value: 'banana', url: 'banana' },
// //                 { text: 'CHERRY', value: 'cherry', url: 'cherry' },
// //                 { text: 'DURIAN', value: 'durian', url: 'durian' },
// //                 { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
// //                 { text: 'FIG', value: 'fig', url: 'fig' },
// //                 { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
// //                 { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
// //             ],
// //         }}
// //     />
// // )
// // export default EditorWithMentionHashtag