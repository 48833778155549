import React, { useState } from 'react';
import TitleBar from "../components/titleBar"
import BottomNav from "../components/bottomNav";
import AccountSettings from "../components/account"

import { FullContainer, InnerContainer } from "../components/styled/containers"


const MainEntry = ({ }) => {
  return <FullContainer>
    <TitleBar />
    <InnerContainer>
      <AccountSettings />
    </InnerContainer>
    <BottomNav selectedIndex={1} />
  </FullContainer>
}

export default MainEntry