import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import { ExpositionText } from "./styled"
import OnboardingLayout from './layout';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getImageData } from "../store";
import { useSnapshot } from "valtio"
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const Classification = ({ meta_uploader, handleNext, handleBack, data_state }) => {
    const img_data = getImageData("auto_crop");
    const snap_data = useSnapshot(img_data);
    const [modelClass, setModelClass] = useState('person');
    const [metadataUploading, setMetadataUploading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const uploadMetadata = () => {
        const json_mdata = { auto_crop: img_data.crops }

        if (_.size(json_mdata) == 0) {
            // throw new Error("No metadata to send")
            enqueueSnackbar("No metadata to send", { variant: "error" });
            return
        }
        // add metadata information regarding instance token and class
        // to be passed to training -- potentially
        json_mdata.metadata = {
            "instance_token": "yegdfn",
            "instance_class": modelClass
        }

        setMetadataUploading(true)
        meta_uploader.post('/', json_mdata).then((res) => {
            if (res.status != 200) {
                enqueueSnackbar("Error uploading metadata: " + res.status, { variant: "error" });
                setMetadataUploading(false)
            }
            console.log("Metadata upload response: ", res)
            // make a call to server with the crop metadata
            setMetadataUploading(false)
            data_state.completed = true;
            handleNext()
        }).catch((err) => {
            setMetadataUploading(false)
            console.log("Error: ", err)
            enqueueSnackbar("Error uploading data. Try again shortly", { variant: "error" });
        })
    }

    const handleChange = (event) => {
        setModelClass(event.target.value);
    };

    const menuItems = [
        "Person",
        "Man",
        "Woman",
        // "Child",
        // "Baby",
        // "Couple",
        // "Dog",
        // "Cat"
    ];

    const selectionForm = () => {
        return (
            <Box sx={{ minWidth: "sm" }}>
                <FormControl fullWidth>
                    <Select
                        labelId="class-select"
                        id="class-select"
                        value={modelClass}
                        onChange={handleChange}
                    >
                        {menuItems.map((m, idx) => {
                            const v = m.toLowerCase()
                            return (<MenuItem value={v} key={`option_${idx}`}>
                                <ListItemText>{m}</ListItemText>
                                {/* {v == modelClass ? <CheckIcon /> : null} */}
                            </MenuItem>);
                        })}
                    </Select>
                </FormControl>
            </Box>);
    }

    const getFooter = () => {
        return <Button
            sx={{ width: "90vw", maxWidth: "1000px", textTransform: "none" }}
            variant="contained"
            size="large"
            disabled={metadataUploading || !modelClass}
            endIcon={<ArrowForwardIcon />}
            onClick={() => uploadMetadata()}
        >
            WE DID IT!
        </Button>
    }

    return (
        <OnboardingLayout footerComponent={getFooter()} title="Who are we cheesin'?" stageText='Step 3 of 4'>
            <ExpositionText>
                Pick the term that best describes the person or animal in the photos you uploaded. (It’s an AI thing.)
            </ExpositionText>
            {selectionForm()}
        </OnboardingLayout>
    );
}

export default Classification;