import React from 'react';
import TitleBar from "../components/titleBar";
import Onboarding from "../components/onboarding";
import { FullContainer, InnerContainer } from "../components/styled/containers"
import { useParams } from 'react-router-dom';

const MainEntry = () => {
  // user_id should come from auth provider
  // const {user_id} = useParams();
  const { betaCode } = useParams();
  
  return <FullContainer>
    <TitleBar />
    <InnerContainer>
      <Onboarding />
    </InnerContainer>
  </FullContainer>
}

export default MainEntry