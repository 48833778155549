import React from 'react';
import { proxy, useSnapshot } from 'valtio'
import { supabase } from "../sb"
import _ from 'lodash'

const _sb_auth_session = { global: null, login: null }

const useAuthSession = () => {
    if (_sb_auth_session.global == null) {
        _sb_auth_session.global = proxy({ session: {} })
    }
    const psession = _sb_auth_session.global
    const { session } = useSnapshot(psession)

    React.useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            console.log("Session updated;", session)
            psession.session = session
        })

        supabase.auth.onAuthStateChange((_event, session) => {
            psession.session = session
            console.log("Session auth change:", session)
        })

    }, [])
    return session
}
const getLoginDetails = () => {
    if (_sb_auth_session.login == null) {
        _sb_auth_session.login = proxy({ email: '', password: '', signUpLoading: false, submittedEmail: null, acceptedTerms: false })
    }
    return _sb_auth_session.login
}

const clearUserSession = () => {
    var sb_keys = _.filter(Object.keys(localStorage),
        (k) => { return k.startsWith("sb-") && k.includes('auth-token') })
    _.each(sb_keys, (k) => {
        localStorage.removeItem(k)
    })
    _sb_auth_session.global.session = {}
}

export {
    clearUserSession,
    useAuthSession,
    getLoginDetails,
}