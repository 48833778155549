import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { LargeTitle, AccentExpositionText, StickyFooter } from "./styled"

const OnboardingLayout = ({children, footerComponent, footerHeight=50, title="", stageText=""}) => {
    return (
        <Box sx={{}}>
            <Stack
                spacing={2}
                sx={{
                    maxWidth: "md",
                    margin: "0 auto",
                    px: 2, pb: `${footerHeight}px`,
                    ...(stageText && {
                        pt: "24px"
                      }),
                    ...(!stageText && {
                        pt: "48px",
                    })
                }}
            >
                {stageText ? <AccentExpositionText>{stageText}</AccentExpositionText> : null}
                {title ? <LargeTitle>{title}</LargeTitle> : null}
                {children}
            </Stack>
            {footerComponent ? <StickyFooter className="footer">
                {footerComponent}
            </StickyFooter> : null}
        </Box>
    );
}

export default OnboardingLayout;