// concept from: https://mui.com/material-ui/react-stepper/
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useSearchParams } from 'react-router-dom'
import { useAuthSession, getAxiosAPI, getBaseUrl } from "./store"
import { supabase } from "../sb"
import Typography from '@mui/material/Typography';

// import { useSnapshot, proxy } from 'valtio'
import _ from "lodash"

const tryStartTraining = async (session_id, session, setAttempts, setQueueTraining) => {
    if (session_id && session != null && session.access_token) {
        setQueueTraining(true)
        const api = getAxiosAPI(getBaseUrl() + `/api/dream/${session_id}/${session.user.id}`, { method: "POST" })
        return api.post("/train", session).then((res) => {
            if (res.status == 500) {
                throw new Error(res.error)
            }
            setQueueTraining(false)
            console.log("Response:", res)
            // window.location = "/@" + session.user.id
            window.location = "/me"
        }).catch((err) => {
            console.log("Error:", err)
            setQueueTraining(false)
            setAttempts()
            // might need to retry queueing
            return err
        })
    }
}

const DreamFinishInterface = ({ }) => {
    // get the fragments from the url

    // const nurl = "http://whatever.com/?" + window.location.href.split("#")[1]
    // console.log("NURL:", nurl)
    // const { searchParams: sessionParams } = new URL(nurl);
    const [searchParams] = useSearchParams()

    // const session_data = Object.fromEntries(sessionParams.entries())
    // console.log("Session Data:", session_data)
    // console.log("Split: ", window.location.href.split("#")[1])
    // console.log("entries:", )
    // const access_token = session_data.access_token
    // searchParams.get('access_token')
    // const [searchParams] = useSearchParams()
    const session_id = searchParams.get("session_id")
    const session = useAuthSession()
    const [queueTraining, setQueueTraining] = React.useState(false)
    const [attempts, setAttempts] = React.useState(0)

    const getQueueData = async () => {
        const { data } = await supabase
            .from('queuejobs')
            .select('*')
        return data
    }

    React.useEffect(() => {
        tryStartTraining(session_id, session, () => { setAttempts(attempts + 1) }, setQueueTraining)
    }, [session, session_id])

    console.log("Session id:", session_id)
    console.log("Finish session:", session)
    // const access_token = session.access_token
    // console.log("Access token:", access_token)

    React.useEffect(() => {
        if (session_id == null) {
            // redirect immediately
            window.location = "/me"
        }
    }, [session_id])

    return <Box sx={{ width: "100%", maxWidth: "md" }}>

        {queueTraining &&
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 5,
            }}><h2>Preparing 🧀-y results</h2><CircularProgress /> </Box>}
        {attempts > 0 && <Typography>Something went wrong, please click retry to successfully continue...</Typography>}
        {attempts > 0 &&
            <Button variant="contained"
                onClick={() => {
                    tryStartTraining(session_id, session, () => { setAttempts(attempts + 1) }, setQueueTraining)
                }}
            >Retry Loading Page</Button>
        }
        {/* <h1>Finish Dream</h1>
        <h2>Session ID: {session_id}</h2>
        <Button variant="contained"
            onClick={() => {
                if (session_id && access_token) {
                    getQueueData().then((data) => {
                        console.log("Queue data:", data)
                    }).catch((err) => {
                        console.log("Error:", err)
                    })
                }
            }}

        >TEMP: queue</Button>
        <Button
            onClick={() => {
                if (session_id && access_token) {
                    // need to send post to specific API loc
                    const api = getAxiosAPI(getBaseUrl() + `/api/dream/${session_id}/${session.user.id}`, { method: "POST" })
                    api.post("/train", session).then((res) => {
                        console.log("Response:", res)
                    }).catch((err) => {
                        console.log("Error:", err)
                    })
                }
            }}

        >TEMP: Train</Button> */}
    </Box>
}
export default DreamFinishInterface;