import React, { useEffect, useState } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ImageCard from './imageCard';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnapshot } from 'valtio';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  backgroundColor: theme.palette.background.home,
  height: "100%",
}));

// const StyledInfiniteScroll = styled(InfiniteScroll, {
//   shouldForwardProp: (prop) => prop !== "location",
// })(({ theme, location }) => ({
//   backgroundColor: location.pathname == "/" ? theme.palette.background.home : theme.palette.background.default,
// }));

const InfiniteImageList = ({ scrollableTarget = null,
  images, imageData, actionClick,
  showCopy = true,
  showSelection = false,
  selectedImageId = null,
  showViewOptions = false,
  onImageClick,
  openModalWhenCompact = false,
  fetchMore,
  getActions = null,
  hasMore, showUsers = true,
  showNSFWBlur = true,
  showActions = true, isActive = true, compact = false }) => {
  // console.log(images);
  // const snap_images = useSnapshot(images)
  const snap_images = useSnapshot(imageData)
  const location = useLocation();

  const [viewOption, setViewOption] = useState('full');

  const showCompact = viewOption === 'compact' || compact;

  const handleViewOption = (event, newAlignment) => {
    setViewOption(newAlignment);
  };

  useEffect(() => {
    fetchMore();
  }, [])

  // const snap_images = useSnapshot(images)
  // console.log("Feed change happened: ", snap_images.length)
  
  return (
    <StyledInfiniteScroll
      // key={uuid4()}
      location={location}
      scrollableTarget={scrollableTarget}
      style={{
        display: 'flex',
        // flexDirection: 'column-reverse', 
        overflow: "contain"
      }}
      // style={{ height: "100%", overflow: "auto" }}
      dataLength={snap_images.length}
      next={() => {
        if (isActive) {
          console.log("Fetching more: ", hasMore)
          fetchMore()
        }
      }}
      hasMore={hasMore}
      loader={<CircularProgress />}
      scrollThreshold={0.8}
      useWindow={!scrollableTarget}
    >
      {showViewOptions ?
        <Box sx={{ alignSelf: "flex-end", p: 2 }}>
          <ToggleButtonGroup
            value={viewOption}
            exclusive
            onChange={handleViewOption}
            aria-label="view option"
          >
            <ToggleButton value="full" aria-label="full" sx={{ height: "30px", p: "5px", }}>
              <ViewStreamOutlinedIcon sx={{ height: "20px" }} />
            </ToggleButton>
            <ToggleButton value="compact" aria-label="compact" sx={{ height: "30px", p: "5px" }}>
              <GridViewOutlinedIcon sx={{ height: "20px" }} />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        : null}
      <Grid container width="100%" spacing={showCompact ? 1 : 2}>
        {snap_images.map((img_snap_data, img_idx) => {
          const img_data = imageData[img_idx]

          // console.log("Rendering image: ", img_snap_data)
          return <Grid item key={`im_${img_snap_data.image_id}_${img_idx}`} xs={showCompact ? 4 : 12} sm={showCompact ? 4 : 6} md={showCompact ? 4 : 4} lg={showCompact ? 2 : 3} sx={{
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
          }}>
            <ImageCard
              // key={`im_card_${img_snap_data.image_id}_${img_idx}`}
              actionClick={({ action_name, ...args }) => { return actionClick({ action_name, img_data, img_idx, ...args }) }}
              showActions={showActions && !showCompact}
              showNSFWBlur={showNSFWBlur}
              showUser={showUsers && !showCompact}
              showCopy={showCopy && !showCompact}
              getActions={getActions}
              onImageClick={onImageClick}
              isSelected={showSelection && img_data.image_id === selectedImageId}
              imageData={{
                url: img_data.src, creator: {
                  user_id: img_data.user_id,
                  avatar_url: img_data.avatar_url,
                  username: img_data.username
                },
                data: img_data
              }}
            />
          </Grid>
        })}
      </Grid>
    </StyledInfiniteScroll>
  );
};

export default InfiniteImageList;
