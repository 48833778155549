import React from 'react'
import { getBaseUrl, useAuthSession, getKeyedStore, supabase, getIdToProfiles } from "../../supastore";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import _ from "lodash"
import { useSnapshot } from "valtio"
import { useSnackbar } from 'notistack'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import copy from 'copy-to-clipboard';
import BugReportIcon from '@mui/icons-material/BugReport';
import { SquishButton } from "../../styled/motion"
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { admin_api, userStore, fetchMoreUsers } from "./user"

const beta_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user/beta`,
})
const betaStore = getKeyedStore('beta_code_admin', {
    codes: [], selected: null, hasMore: true,
    selected: null,
    initialCheddar: 100,
    accountType: 'unlimited',
    codeCount: 1, failCode: null
})

const fetchBetaCodes = async ({ beta_codes, betaStore, access_token, enqueueSnackbar, start_ix = null }) => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 
    if (!betaStore.hasMore) {
        return
    }
    const fetchSize = 1000
    const min_range = start_ix == null ? betaStore.codes.length : start_ix
    const max_range = min_range + fetchSize
    const { data, error: invite_error } = await admin_api.post('/', {
        access_token,
        action: 'list_beta_invite',
        action_args: { min_range, max_range }
    })

    const { invites: invite_data } = data

    // make a query against betainvites plz 
    // const { data: invite_data, error: invite_error } = await supabase
    //     .from('betacodes')
    //     .select('*')


    // .eq('id', user_id)

    if (invite_error) {
        console.log(invite_error)
        enqueueSnackbar("Failed fetch beta codes", { variant: 'error' })
        return
    }

    console.log("Codes:", invite_data)
    if (invite_data.length == 0 || invite_data.length % fetchSize !== 0) {
        // ignore for now
        // setHasMore(false)
        betaStore.hasMore = false
    }
    // console.log("Queried User Images: ", data)
    // const { codes } = invite_data
    // get all img data
    betaStore.codes = _.orderBy(_.uniqBy([...invite_data, ...betaStore.codes], 'code_id'), 'created_at', 'desc')
    const profiles = await getIdToProfiles(_.map(invite_data, (c) => c.id))
    // const codes = JSON.parse(JSON.stringify(betaStore.codes))
    // const ucodes = JSON.parse(JSON.stringify(_.uniq(betaStore.codes, 'code_id')))
    // console.log("Queried:", invite_data, " profiles: ", profiles, "cur codes:", JSON.parse(JSON.stringify(betaStore.codes)))
    // console.log("Codes:", codes, " uniq codes: ", ucodes)
    betaStore.codes = _.map(betaStore.codes, (c) => { return { ...c, ...profiles[c.id] } })
    // console.log("Profile update codes:", JSON.parse(JSON.stringify(betaStore.codes)))
    // console.log("Queried Beta Codes: ", invite_data)
};

const createBetaCode = async ({ access_token, user_id, enqueueSnackbar, ...action_props }) => {
    const { data, error } = await admin_api.post('/', {
        access_token, action: 'create_beta_invite', action_args: { user_id, ...action_props }
    })

    if (error) {
        // console.log(error)
        enqueueSnackbar(`Failed Create Beta Code: ${error}`, { variant: 'error' })
        return
    }
    enqueueSnackbar(`Created Beta Code: ${data.code_id}`, { variant: 'success' })
    return data
}

const incrementBetaCode = async ({ access_token, user_id, code_id, increment = 1, enqueueSnackbar }) => {

    // update_beta_invite
    const { data, error } = await admin_api.post('/',
        {
            access_token,
            action: 'update_beta_invite',
            action_args: { user_id, code_id, increment }
        })

    // const rpc_args = { increment, beta_code_id: code_id }
    // console.log("Calling with values: ", rpc_args)
    // const { data, error } = await supabase
    //     .rpc('increment_beta_code', rpc_args)
    if (error) {
        enqueueSnackbar(`Failed Increment Beta Code: ${error}`, { variant: 'error' })
        return
    }
    console.log("DatA:", data, " error :", error)
    // enqueueSnackbar(`Incremented Beta Code: ${code_id}`, { variant: 'success' })
    return data
}
const accountTypes = {
    unlimited: "Unlimited",
    only_cheddar_discount: "Free Train, Pay Cheddar (Discounted)",
    only_cheddar_pay: "Free Train, Pay Cheddar",
    discount: "Pay (Discounted)",
    pay: "Pay (Full Price)",
    lurker: "Lurker",
}

const BetaListItem = ({ code, testUseBetaCode, adjustCodeCount, failedCodeCopy, enqueueSnackbar }) => {
    const { access_token } = useAuthSession()
    // we have three cells, [id, code_id, code_count]
    // we want to format as a table
    let { username, id: user_id, code_id, code_count,
        initial_count, created_at, initial_cheddar_amount, account_type } = code

    account_type = account_type == "full" ? "unlimited" : account_type
    // console.log("Code:", code)
    return <TableRow>
        <TableCell>{username ? username : "loading..."}</TableCell>
        {/* <TableCell>{user_id}</TableCell> */}
        <TableCell align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
                const url = `https://cheese.baby/@${username}/invite?code=${code_id}`
                if (copy(url))
                    enqueueSnackbar("Copied Code Link to Clipboard", { variant: 'success' })
                else {
                    failedCodeCopy(url)
                }
            }}
        >{code_id}</TableCell>
        <TableCell align="center">{code_count}</TableCell>
        <TableCell align="center">{initial_count}</TableCell>
        <TableCell align="center">{initial_cheddar_amount}</TableCell>
        <TableCell><Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'

        }}>

            <SquishButton onClick={() => {
                adjustCodeCount({ count: 1, code_id })
                // fetch more beta codes
            }}>+</SquishButton>
            <SquishButton onClick={() => {
                adjustCodeCount({ count: -1, code_id })
                // fetch more beta codes
            }}>-</SquishButton>
            <SquishButton onClick={() => {
                testUseBetaCode({ email: "paul@aitooltime.com", code_id })
                // fetch more beta codes
            }}><BugReportIcon /></SquishButton>
        </Box>
        </TableCell>
        <TableCell>{accountTypes[account_type]}</TableCell>
        <TableCell>{moment(created_at).format('MM/DD/YY, h:mm a')}</TableCell>
    </TableRow>

}

const BetaInvitesComponent = ({ isActive }) => {
    // see our beta invites plz 
    const { codes: snap_codes, hasMore: snap_has_more,
        codeCount: snap_count,
        accountType: snap_account,
        initialCheddar: snap_cheddar,
        selected: snap_select,
        failCode } = useSnapshot(betaStore)
    const { users: snap_users } = useSnapshot(userStore)
    const real_users = _.filter(snap_users, (u) => u.username != null)

    // const snap_has_more = useSnapshot(betaStore.hasMore)
    const { enqueueSnackbar } = useSnackbar()
    const { auth, user_id, profile } = useAuthSession();
    const { access_token } = auth || {}

    if (snap_select == null && profile) {
        betaStore.selected = profile
    }

    // make query for beta codes and list 
    React.useEffect(() => {
        // get initial batch 
        // i should use autocomplete to fetch these in reality
        fetchMoreUsers({ snap_users, userStore, access_token, enqueueSnackbar })
        fetchBetaCodes({ beta_codes: snap_codes, betaStore, access_token, enqueueSnackbar })
    }, [])
    const userSelect = (e, val) => {
        // console.log("Selected user:", val)
        betaStore.selected = val
        console.log("Selected:", betaStore.selected)
        console.log("EE:", e)
    }
    const adjustCodeCount = async ({ count, code_id }) => {
        if (code_id == null) {
            enqueueSnackbar("No code selected", { variant: 'error' })
            return
        }
        const select_id = betaStore.selected ? betaStore.selected.id : profile.id

        incrementBetaCode({ access_token, user_id: select_id, code_id, increment: count, enqueueSnackbar }).then((data) => {
            betaStore.hasMore = true
            // betaStore.codes = []
            fetchBetaCodes({ beta_codes: snap_codes, betaStore, access_token, enqueueSnackbar, start_ix: 0 })
            enqueueSnackbar(`Adjusted Beta Code ${code_id} by ${count}`, { variant: 'success' })
        }).catch(err => {
            enqueueSnackbar(`Failed Increment Beta Code: ${err}`, { variant: 'error' })
        })
    }

    const testUseBetaCode = async ({ email, code_id }) => {
        if (code_id == null) {
            enqueueSnackbar("No code selected", { variant: 'error' })
            return
        }
        beta_api.post('/', {
            email, code_id,
            password: "password",
            username: "betatest",
            acceptedTerms: true,
            metadata: {}
        }).then((data) => {
            enqueueSnackbar(`Tested Beta Code ${code_id}`, { variant: 'success' })
            betaStore.hasMore = true
            // betaStore.codes = []
            fetchBetaCodes({ beta_codes: snap_codes, betaStore, access_token, enqueueSnackbar, start_ix: 0 })
            console.log("Data:", data)
        }).catch(err => {
            console.log("err:", err)
            enqueueSnackbar(`Failed Test Code: ${err.response.data.error}`, { variant: 'error' })
        })
    }


    // const current_user = _.find(real_users, (u) => u.id == user_id)
    // list all of our codes
    // then make a button to create a new code 
    console.log("User match:", profile)

    return <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 3,

    }}>
        {failCode && <a
            style={{ color: "#FFF", textDecoration: "none", cursor: "pointer", backgroundColor: "#242424" }}
            href={failCode} target="_blank" rel="noreferrer">Failed to copy to clipboard, right click here</a>}
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        }}>

            <Autocomplete
                isOptionEqualToValue={(option, value) => option.username === value.username}
                getOptionLabel={(option) => option.username}
                onChange={userSelect}
                options={_.sortBy(real_users, (u) => u.username.toLowerCase())}
                // defaultValue={profile}
                value={snap_select}
                // inputDefaultValue={current_user && current_user.username}
                // inputValue={inputValue}
                // onInputChange={(_, newInputValue) => {
                //   setInputValue(newInputValue)
                // }}
                // defaultValue={_.find(real_users, (u) => u.id == user_id)}
                sx={{ width: "50%", m: 2 }}
                renderInput={(params) => <TextField {...params} label="Username Selection" />}
            />

            <TextField
                id="count-number"
                label="Beta Use Count"
                type="number"
                autoFocus={false}
                defaultValue={snap_count}
                onChange={(e) => betaStore.codeCount = e.target.value}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="initial-token-number"
                label="Initial Cheddar"
                type="number"
                autoFocus={false}
                defaultValue={snap_cheddar}
                onChange={(e) => betaStore.initialCheddar = e.target.value}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <FormControl fullWidth>
                <InputLabel id="select-account">Account Type</InputLabel>
                <Select
                    labelId="select-account-label"
                    id="select-account-sel"
                    defaultValue={snap_account}
                    label="Account Type"
                    onChange={(e) => {
                        betaStore.accountType = e.target.value
                    }}
                >
                    {_.map(accountTypes, (value, key) => {
                        return <MenuItem key={key} value={key}>{value}</MenuItem>
                    })
                    }
                    {/* <MenuItem value={'unlimited'}>Unlimited</MenuItem>
                    <MenuItem value={'only_cheddar_discount'}>Free Train, Pay Cheddar (Discounted)</MenuItem>
                    <MenuItem value={'only_cheddar_pay'}>Free Train, Pay Cheddar</MenuItem>
                    <MenuItem value={'discount'}>Pay (Discounted)</MenuItem>
                    <MenuItem value={'pay'}>Pay (Full Price)</MenuItem>
                    <MenuItem value={'lurker'}>Lurker</MenuItem> */}
                </Select>
            </FormControl>

        </Box>
        <Button sx={{ m: 2 }} variant="contained" onClick={() => {
            // console.log("Create new code")
            const select_id = betaStore.selected ? betaStore.selected.id : profile.id

            createBetaCode({
                access_token,
                user_id: select_id,
                enqueueSnackbar,
                code_count: betaStore.codeCount,
                token_count: betaStore.initialCheddar,
                account_type: betaStore.accountType,
            }).then((data) => {
                // fetch new 
                betaStore.hasMore = true
                return fetchBetaCodes({ beta_codes: snap_codes, betaStore, user_id, access_token, enqueueSnackbar })
            }).catch((err) => {
                console.log("Error creating code:", err)
            })
        }}> <AddIcon /> Create New Beta Code</Button>
        <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        {/* <TableCell>User ID</TableCell> */}
                        <TableCell >Code (Click Copy)</TableCell>
                        <TableCell >Remaining</TableCell>
                        <TableCell >Total</TableCell>
                        <TableCell >Initial Tokens</TableCell>
                        <TableCell >Adjust</TableCell>
                        <TableCell >Account Type</TableCell>
                        <TableCell >Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* <List> */}
                    {snap_codes.map((code) => {
                        return <BetaListItem
                            enqueueSnackbar={enqueueSnackbar}
                            adjustCodeCount={adjustCodeCount}
                            testUseBetaCode={testUseBetaCode}

                            key={code.code_id} code={code} failedCodeCopy={(code_url) => {
                                betaStore.failCode = code_url
                                enqueueSnackbar("Failed to Copy Code Link to Clipboard. See top line. ", { variant: 'warning' })
                            }} />
                        // return <ListItem key={code.code_id}>
                        // <ListItemText primary={code.code_id} />
                        // </ListItem>
                    }
                    )}
                    {/* </List> */}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>

}


export default BetaInvitesComponent

export {
    beta_api,
    betaStore,
    fetchBetaCodes,
}