import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import { ExpositionText } from "./styled"
import OnboardingLayout from './layout';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getImageData } from "../store";
import { useSnapshot } from "valtio"
import { useSnackbar } from 'notistack';
import { useParams } from "react-router-dom"
import _ from 'lodash';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { getBaseUrl } from "./utils"

const payment_api = axios.create({
  baseURL: `${getBaseUrl()}/api/payments`,
})

const stripePromise = loadStripe('pk_live_51LyxrvDC85gUONUlm7BMOY4INrrIe1g7rv3AfbH8kaKWK93P0tKv8nxY0dSUwSSiKOY7rHVLbEizndVDUodMHkxY007Z1raVZV');

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();

//   const handleSubmit = async (event) => {
//     // We don't want to let default form submission happen here,
//     // which would refresh the page.
//     event.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     const result = await stripe.confirmPayment({
//       //`Elements` instance that was used to create the Payment Element
//       elements,
//       confirmParams: {
//         return_url: `https://cheese.baby/dream/create/?session_id=${session_id}`,
//       },
//     });

//     if (result.error) {
//       // Show error to your customer (for example, payment details incomplete)
//       console.log(result.error.message);
//     } else {
//       // Your customer will be redirected to your `return_url`. For some payment
//       // methods like iDEAL, your customer will be redirected to an intermediate
//       // site first to authorize the payment, then redirected to the `return_url`.
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <button disabled={!stripe}>Submit</button>
//     </form>
//   )
// };

const Payments = ({ meta_uploader, handleNext, handleBack, data_state, checkoutUrl }) => {
  const { session_id } = useParams()
  // const img_data = getImageData("auto_crop");
  // const snap_data = useSnapshot(img_data);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const is_free = checkoutUrl === "free"

  const getFooter = () => {
    return <Button
      sx={{ width: "90vw", maxWidth: "1000px", textTransform: "none" }}
      variant="contained"
      size="large"
      disabled={isLoading}
      endIcon={<ArrowForwardIcon />}
      onClick={() => {
        // redirect to stripe checkout
        // checkoutUrl
        if (is_free) {
          // reroute based on session_id
          window.location.href = `/dream/complete?session_id=${session_id}`
        }
        else {
          window.location.href = checkoutUrl
        }
      }}
    >
      Train my model!
    </Button>
  }

  const pricing_info = is_free ? "Normal users would pay at this point..." : "For $9.99 you get:"
  const free_text = is_free ? "but you’ve been given a free model and more:" : null
  const footer_info = is_free ? "...for FREE!" : null
  return (
    // <Elements stripe={stripePromise} options={{ clientSecret: stripeSecret }}>
    <OnboardingLayout footerComponent={getFooter()} title="Payment" stageText='Step 4 of 4'>
      <ExpositionText>We’re ready to make your Cheese!</ExpositionText>
      <ExpositionText>{pricing_info}</ExpositionText>
      {free_text && <ExpositionText>{free_text}</ExpositionText>}
      <ExpositionText>
        • A trained AI model for your images<br />
        • 72 free images just for signing up<br />
        • Plus 1500 Cheddar - good for making another 225 images of your choice!
      </ExpositionText>
      {footer_info && <ExpositionText>{footer_info}</ExpositionText>}
      <Box sx={{ width: "100%", maxWidth: "md", height: "auto", overflow: "hidden" }}>
        <img src="/payment_demo_img.png" alt="preview"
          style={{ width: "100%", objectFit: "fill" }} />
      </Box>
      {/* <CheckoutForm /> */}
    </OnboardingLayout>
    // </Elements>
  );
}


const InitiatePayement = ({ ...props }) => {

  const { session_id } = useParams()
  // fetch our payment intent from stripe, then set the stripe secret
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  React.useEffect(() => {
    payment_api.post("/cheese-create", { session_id }).then(({ data }) => {
      console.log("Cheese create return success:", data)
      if (data.is_free) {
        setCheckoutUrl("free")
      }
      else
        setCheckoutUrl(data.checkout_url)
      // set the stripe secret and off we go
      // setCheckoutUrl(res.data.client_secret);
    }).catch(err => {
      console.log("Cheese create return error:", err)
    })

  }, [])

  return checkoutUrl ? <Payments {...props} checkoutUrl={checkoutUrl} /> : null;
}

export default InitiatePayement;