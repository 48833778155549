import React from 'react';
import TitleBar from "../components/titleBar";
import Profile from "../components/profile";
import BottomNav from "../components/bottomNav";
import { FullContainer, InnerContainer } from "../components/styled/containers"
import { useParams } from 'react-router-dom';
import { getIdfromUsername, getUsernameToProfile, useAuthSession } from "../components/supastore"
import { useSnapshot } from "valtio"

const MainEntry = () => {
  // user_id should come from auth provider
  // const {user_id} = useParams();
  const { isInit, isAuthenticated } = useAuthSession();
  const { username } = useParams();
  const user_id_data = getIdfromUsername(username)
  const { user_id } = useSnapshot(user_id_data)

  React.useEffect(() => {
    getUsernameToProfile(username).then(data => {
      console.log("Ret data:", data)
    })
  }, [])

  // const user_id = useIdFromUsername(username)
  console.log("User id:", user_id, "username:", username, "auth", isAuthenticated)
  return (!isInit ? <FullContainer>
    <TitleBar />
    <InnerContainer>
      {user_id && <Profile userId={user_id} />}
    </InnerContainer>
    {isAuthenticated && <BottomNav selectedIndex={0} />}
  </FullContainer> : null)
}

export default MainEntry