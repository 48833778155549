import React, { useState } from 'react'
import SearchResultList from './views/searchResultList';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBar from './views/searchBar';
import { getKeyedStore } from "../supastore"
import { useSnapshot } from "valtio"
import _ from "lodash"

export default function LexicaSearch({ query, setQuery, results, setResults, onCopyText }) {
  const [isFetching, setIsFetching] = useState(false);

  const itemStore = getKeyedStore("searchItemStore", {
    items: [],
    hasMore: false,
    isActive: true
  })
  const { hasMore, isActive, items: snap_items } = useSnapshot(itemStore)
  // onAction, fetchMore, hasMore, isActive
  const fetchLexicaResults = () => {
    if (!query || query.length <= 3) {
      return;
    } else {
      setIsFetching(true);
      setResults([]);
      var url = new URL("https://lexica.art/api/v1/search");
      url.searchParams.append("q", query);
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setIsFetching(false);
          console.log("Fetch return:", data.images)
          const img_data_arr = _.map(data.images, (im_ret) => {
            return {
              src: im_ret.src,
              creator: {
                user_id: "lexica",
                avatar_url: null,
                username: "lexica"
              },
              data: im_ret,
              metadata: JSON.stringify({
                prompt: im_ret.prompt,
                nsfw: im_ret.nsfw,
                cfg_scale: im_ret.guidance,
                seed: im_ret.seed
              })
            }
          })
          itemStore.items = img_data_arr
          // setResults(data.images ?? []);
        });
    }
  };
  const onAction = (action) => {
    console.log("Take Action:", action)
  }
  const fetchMore = async () => {
    console.log("Fetch more!")
  }

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <SearchBar searchQuery={query} setSearchQuery={setQuery} onSubmit={fetchLexicaResults} />
      {isFetching ?
        <CircularProgress />
        : <SearchResultList
          items={snap_items}
          itemStore={itemStore}
          hasMore={hasMore}
          isActive={isActive}
          onAction={onAction}
          fetchMore={fetchMore}
        />
      }
    </div>
  );
}