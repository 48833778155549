import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { supabase, useAuthSession, getUserJobs } from "../../supastore"
import InfiniteQueueList from "../../styled/infiniteQueueList";
// import { reject } from 'lodash';
import _ from 'lodash'
// const dummyIms = [
//     "https://image.lexica.art/md/72d689fc-0b47-46f6-9eea-dc77a3cae338",
//     "https://image.lexica.art/md/4c9f56bd-d218-4764-8a55-11fc9a3503f6",
//     "https://image.lexica.art/md/9c7d161f-d6da-41e7-be18-43ec89bf71a9",
//     "https://image.lexica.art/md/5a0afa7d-10ee-4c23-871a-5d2f3c5f91a1",
//     "https://image.lexica.art/md/a84c9eb4-c5e1-48f2-88c2-f09a5a91039c",
//     "https://image.lexica.art/md/40e22034-4b9c-42f5-85ec-47a084316a05",
//     "https://image.lexica.art/md/c1384281-d797-43c6-a418-dafaf06bf310",
//     "https://image.lexica.art/md/e3324a1b-1726-4d6f-8cbb-ad3a875af55e",
//     "https://image.lexica.art/md/8e33b4df-e852-4cf6-a0fc-85e5a7073807",
// ];

const QueueJobsList = ({ userId, isActive=true }) => {
    // data handling
    const [jobData, setJobData] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const { auth, user_id } = useAuthSession()

    const fetchMore = async () => {
        // await new Promise(resolve => setTimeout(resolve, 500));
        // fetch jobs 
        const fetchSize = 10
        const min_range = jobData.length
        const max_range = min_range + fetchSize
        const { data, error } = await getUserJobs(user_id, min_range, max_range)
        if (error) {
            console.log(error)
            // return
        }
        console.log("Queried: ", data)
        if (data.length == 0 || data.length % fetchSize !== 0) {
            // ignore for now
            setHasMore(false)
        }

        let joined_data = [...jobData, ...data]
        // remove duplicate queue_id 
        joined_data = _.uniqBy(joined_data, 'queue_id')
        // sort by last_progress_at
        joined_data = _.map(joined_data, (x) => { return { 'last_progress_at': new Date(x.last_progress_at), ...x } })
        joined_data = _.orderBy(joined_data, ['last_progress_at'], ['desc'])
        console.log("Job data all:", joined_data)
        setJobData([...joined_data])
        // else {
        // }
        // setJobData(newIms);
        // setHasMore(newIms.length <= 50);
    };

    // React.useEffect(() => {
    //     if (user_id) {
    //         // let's make our request
    //         getUserJobs(user_id).then(res => {
    //             console.log("Queue jobs: ", res)
    //         })
    //     }
    // }, [user_id])

    return (user_id ?
        <InfiniteQueueList
            data={jobData}
            fetchMore={fetchMore}
            hasMore={hasMore}
            isActive={isActive}
        /> : null
    );
}


export default QueueJobsList