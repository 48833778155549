import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import TitleBar from "../components/titleBar"
import { FullContainer, InnerContainer } from "../components/styled/containers"
import _ from 'lodash';
import { getAuthData } from "../utils"
import DreamCreate from "../components/dream/create"
import DreamFinish from "../components/dream/finish"
import DreamWait from "../components/dream/wait"
import DreamComplete from "../components/dream/complete"
import DreamCancel from "../components/dream/cancel"

import Box from '@mui/material/Box';

const getSearchParams = ({ search }) => {
    const obj = {}
    search.replace('?', '').split("&").forEach((param) => {
        const [key, value] = param.split('=')
        obj[key] = value
        return obj
    })
    return obj
}

const DefaultContent = ({ action }) => {
    return <div>{action}</div>
}

const SelectContent = ({ action, uuid, location }) => {
    const [showModal, setshowModal] = React.useState(true)
    if (action == "create") {
        return <DreamCreate />
    }
    else if (action == "finish") {
        return <DreamFinish />
    }
    else if (action == "wait") {
        return <DreamWait />
    }
    else if (action == "complete") {
        return <DreamComplete />
    }
    else if (action == "cancel") {
        return <DreamCancel />
    }
    else if (action != null) {
        return <DefaultContent action={action} />
    }
    else {
        // console.log("Locatino: ", location, "artifcat", action)
        if (!action && (location.pathname.includes("/dream/login"))) {
            const sparams = getSearchParams({ search: location.search })
            const [action, obj_id] = sparams["state"].split("__")
            const auth = getAuthData()
            console.log("Auth  data:", auth)
            // immediately relocation using the state variable
            // let { searchParams } = new URL(window.location)
            console.log("Search Prams:", sparams, "redirect to", action, " obj ", obj_id)
            window.location.href = `/${action}/${obj_id}?remix=true`
            return
        }

        // by default attempt embedding of whatever we look at 
        return <DefaultContent action={action} />
    }
}

const MainEntry = ({ }) => {
    let { action, uuid } = useParams();
    let loc = useLocation();
    const titleHeight = "64px"

    // return <Box sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100%",
    //     // overflow: "hidden",
    // }}>
    //     <TitleBar />
    //     <Box sx={{
    //         flex: 1,
    //         display: 'flex',
    //         width: "100%",
    //         justifyContent: "center",
    //         maxHeight: 'calc(100% - ' + titleHeight + ')',
    //     }}>
    //         <SelectContent action={action} uuid={uuid} location={loc} />
    //     </Box>
    // </Box>

    return <FullContainer>
        <TitleBar />
        <InnerContainer>
            <SelectContent action={action} uuid={uuid} location={loc} />
        </InnerContainer>
    </FullContainer>;
}

export default MainEntry;