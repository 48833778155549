import React from 'react'
import { proxy, useSnapshot } from 'valtio'
import { createClient } from '@supabase/supabase-js'
import _ from 'lodash'
import axios from 'axios'
// import { isMobile } from 'react-device-detect'
// import LegendToggleOutlined from '@mui/icons-material/LegendToggleOutlined'

const supabaseUrl = process.env.REACT_APP_SB_URL
const supabaseAnonKey = process.env.REACT_APP_SB_AKEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
    schema: 'public',
    // headers: { 'x-my-custom-header': 'my-app-name' },
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
})


const _sb_auth_session = { global: null, login: null, admin: null }


const _getAdminData = (access_token, asession) => {
    // do admin check now that session exists
    axios.post(`${getBaseUrl()}/api/user/admin`, { access_token })
        .then(({ data }) => {
            const is_super_admin = _.filter(data.privileges, (p) => p === "super_admin").length > 0
            console.log(`Admin check:`, is_super_admin, data.privileges)
            asession.session = {
                admin_checked: true,
                is_admin: true,
                is_super_admin,
                ...data
            }
            // asession.ui_state.admin_tab = false
            asession.ui_state = proxy({ ...asession.ui_state })
        }).catch((err) => {
            asession.session = {
                admin_checked: true,
                is_admin: false,
                is_super_admin: false,
            }
            console.log("Admin check error:", err)
        })
}

const _getSubscriptions = async (psession) => {
    // subscribe to cheddar changes 
    if (psession.session.subscription != null) {
        if (psession.session.subscription.state == "joined")
            return
        console.log("Subscriptioncurrent state:", psession.session.subscription)
        try {
            psession.session.subscription.unsubscribe()
        }
        catch (err) {
            console.log("Unsubscribe error:", err)
        }
    }

    const subscription = await supabase
        .channel('public:transactioncheddar:id=eq.' + psession.session.user_id)// token_type=eq.cheddar')
        .on('postgres_changes',
            {
                // event: 'UPDATE',
                event: 'INSERT',
                schema: 'public',
                table: 'transactioncheddar',
                filter: 'id=eq.' + psession.session.user_id,
                // filter: 'token_type=eq.cheddar'
            }, async (payload) => {
                // console.log("User cheddar update:", payload)
                // fetch user cheddar and update
                // const { new: new_row, old: old_row } = payload

                if (payload && payload.new) {
                    // check if we have a remix earn
                    const transaction_reason = payload.new.transaction_reason
                    if (transaction_reason == 'remix_earn') {
                        // let the users know! 
                        psession.realtime.messages.push({
                            message: "Someone remixed your post! You earned 10 cheddar!",
                        })
                    }
                }

                const { data: cheddar, error } = await supabase
                    .from('usercheddar')
                    .select('*')
                    .eq('id', psession.session.user_id)
                    .eq('token_type', 'cheddar')
                    .single()
                console.log("transact Payload", payload, "error:", error, "cheddar", cheddar)
                psession.session.profile = { ...psession.session.profile, ...cheddar }
            }) // now we also listen to the userimages table for updates 
        .on('postgres_changes', {
            event: 'INSERT',
            schema: 'public',
            table: 'userimages',
            filter: 'id=eq.' + psession.session.user_id,
        }, async (payload) => {
            // on new images, update please
            console.log("User image update:", payload)
            // psession.realtime.images.push(payload.new)
            // downstream can subscribe and interact 
        })
        .subscribe()
    console.log("Subscribed to user cheddar changes:", subscription)
    psession.session = { ...psession.session, subscription }
}

const useRealtimeImages = () => {
    const { realtime } = useSnapshot(_sb_auth_session.global)
    return realtime.images
}
const useRealtimeMessages = () => {
    const { realtime } = useSnapshot(_sb_auth_session.global)
    return realtime.messages
}

const _getObserverData = async (profile) => {
    // check train for update time
    const user_id = profile.id
    const { data, error } = await axios.get(`${getBaseUrl()}/api/dream/empty/${user_id}/train`)
    console.log("OBSERVER DATA:", data, error)
    if (error) {
        console.log("Error checking train status:", error)
    }
    // if any are complete, 
    const any_open_sessions = data.length > 0 && _.filter(data, (d) => !d.is_complete).length > 0
    const any_complete = _.filter(data, (d) => d.is_complete).length > 0
    return [any_complete, any_open_sessions]
}
const useSingleGetAuthSession = () => {
    if (_sb_auth_session.global == null) {
        _sb_auth_session.global = proxy({
            session: { isInit: true }, realtime: {
                images: [],
                messages: [],
                comms: [],
            }
        })
    }
    if (_sb_auth_session.admin == null) {
        _sb_auth_session.admin = proxy({
            session: { isInit: true }, ui_state: {
                admin_tab: false,
                drawerOpen: false, //!isMobile,
                adminPage: "cheddar_actions"
            },

        })
    }

    const psession = _sb_auth_session.global
    const asession = _sb_auth_session.admin
    React.useEffect(() => {
        supabase.auth.getSession().then(async ({ data: { session } }) => {
            if (session) {
                const profile = await getUserProfile(session ? session.user.id : null)
                console.log("Found user profile:", profile)
                // store session with profile info
                let isObserving = (profile ? profile.current_state == "training_job_started" : false)
                let anyOpen = false
                if (isObserving) {
                    const [checkObserve, checkAyOpen] = await _getObserverData(profile)
                    anyOpen = checkAyOpen
                    if (checkObserve && !isObserving) {
                        // changed status, must refresh page! 
                        window.location.reload()
                    }
                }
                psession.session = {
                    ...(psession.session || {}),
                    isInit: false, isAuthenticated: session ? true : false,
                    auth: session, user_id: session ? session.user.id : null,
                    profile: psession.session.profile || profile,
                    isObserving: isObserving,
                    anyOpenSessions: anyOpen,
                }
                // psession.session = {
                //     ...(psession.session || {}),
                //     isInit: false, isAuthenticated: session ? true : false,
                //     auth: session, user_id: session ? session.user.id : null,
                //     profile
                // }
                if (session) {
                    _getAdminData(session.access_token, asession)
                    _getSubscriptions(psession)
                }
                console.log("Auth state changed", psession.session)
            }
            else
                psession.session = { isInit: false, isAuthenticated: false }

        })

        supabase.auth.onAuthStateChange(async (_event, session) => {
            if (session) {
                const profile = await getUserProfile(session ? session.user.id : null)
                // const subscription = psession.session.subscription || (await supabase
                //     .channel('public:usercheddar:token_type=eq.cheddar')
                //     .on('postgres_changes',
                //         {
                //             event: 'UPDATE',
                //             schema: 'public',
                //             table: 'usercheddar',
                //             filter: 'token_type=eq.cheddar'
                //         }, (payload) => {
                //             // console.log("User cheddar update:", payload)
                //             psession.session.profile = { ...psession.session.profile, ...payload.new }
                //         }).subscribe())
                let isObserving = (profile ? (profile.current_state == "training_job_started" || profile.train_model_id == null) : false)
                let anyOpen = false
                if (isObserving) {
                    const [checkObserve, checkAnyOpen] = await _getObserverData(profile)
                    anyOpen = checkAnyOpen
                    if (checkObserve && !isObserving) {
                        // changed status, must refresh page! 
                        window.location.reload()
                    }
                }
                psession.session = {
                    ...(psession.session || {}),
                    isInit: false,
                    isAuthenticated: session ? true : false,
                    auth: session, user_id: session ? session.user.id : null,
                    profile: psession.session.profile || profile,
                    anyOpenSessions: anyOpen,
                    isObserving: (profile ? (profile.current_state == "training_job_started" || profile.train_model_id == null) : false),
                }
                // try {
                //     const subscription = await supabase
                //         .channel('public:usercheddar:id=eq.' + session.user.id)
                //         .on('postgres_changes',
                //             {
                //                 event: 'UPDATE',
                //                 schema: 'public',
                //                 table: 'usercheddar',
                //                 filter: 'token_type=eq.cheddar'
                //             }, (payload) => {
                //                 console.log("User cheddar update:", payload)
                //             }).subscribe()
                // }
                // catch (err) {
                //     console.log("Error subscribing to user authcheddar:", err)
                // }
                console.log("Auth state changed", psession.session)
                // redo admin check
                if (session) {
                    _getAdminData(session.access_token, asession)
                    _getSubscriptions(psession)
                }
            } else {
                psession.session = { isInit: false, isAuthenticated: false, auth: null, user_id: null }
            }
        })
        return () => {
            // need to unsub from realtime updates prob
        }

    }, []);
    return psession
}

const updateAuthSession = () => {
    supabase.auth.refreshSession()
}
const getSessionProxy = () => {
    const psession = _sb_auth_session.global
    return psession.session
}
const useAuthSession = () => {

    const psession = _sb_auth_session.global
    const { session } = useSnapshot(psession)

    return session
}
const useSBAuthSession = useAuthSession

const useAdminAuthSession = () => {
    const { session } = useSnapshot(_sb_auth_session.admin)
    return session
}
const useAdminUIState = () => {
    const admin = _sb_auth_session.admin
    const { ui_state } = useSnapshot(admin)
    return ui_state
}

const getAdminUIStateStore = () => {
    return _sb_auth_session.admin.ui_state
}

const getIsObserveCheck = async ({ user_id }) => {
    // maybe check if my jobs finished
    return getUserImages(user_id, 0, 1).then(({ data }) => {
        if (data && data.length > 0) {
            // console.log("User has images, so observing")
            // change observation status 
            const psession = _sb_auth_session.global
            if (psession.session) {
                psession.session.isObserving = false
                return false
            }
        }
        return true
    })
    // axios.post(`${getBaseUrl()}/api/user/admin`, { access_token })
}

export const SB_REDIRECT_URL = process.env.REACT_APP_SB_REDIRECT

const getBaseUrl = () => {
    return window.location.protocol + '//' + window.location.host
}

const _userid_to_profile = {}
const _username_to_id = { username: {} }

const getIdToProfiles = async (user_ids, no_cache = false) => {
    const all_ids = _.uniq(user_ids)
    // console.log(`all_ids: ${all_ids}`)
    // missing

    const missing_ids = no_cache ? all_ids : _.filter(all_ids, (id) => !_userid_to_profile[id])

    // console.log(`missing_ids: ${missing_ids}`)
    if (missing_ids.length > 0) {
        // do a lookup 
        const { data: user_and_ids, error } = await supabase
            .from('userdata')
            .select('*')//, usercheddar(*)')
            .in('id', missing_ids)
        if (error) {
            console.error(`Error getting userprofiles: ${error}`)
        }
        if (user_and_ids) {
            _.forEach(user_and_ids, (uandid) => {
                _userid_to_profile[uandid.id] = uandid
                _username_to_id.username[uandid.username] = proxy({ user_id: uandid.id })
            })
        }
    }
    return _.pick(_userid_to_profile, all_ids)
}
const getUsernameToProfile = async (username) => {
    const { data: user_and_ids, error } = await supabase
        .from('userprofiles')
        .select('*')
        .eq('username', username)
    if (error) {
        console.error(`Error getting userprofiles: ${error}`)
    }
    if (user_and_ids) {
        _.forEach(user_and_ids, (uandid) => {
            _userid_to_profile[uandid.id] = uandid
            if (!_username_to_id.username[uandid.username]) {
                _username_to_id.username[uandid.username] = proxy({ user_id: uandid.id })
            }
            else
                _username_to_id.username[uandid.username].user_id = uandid.id
        })
    }
    return _username_to_id.username[username]

    //             .in('username', missing_users)
    // const all_usernames = _.uniq(usernames)
    // // console.log(`all_usernames: ${all_usernames}`)
    // // missing
    // const missing_users = _.filter(all_usernames, (id) => !_username_to_id.username[id] || !_username_to_id.username[id].user_id)

    // console.log(`missing_users: ${missing_users}`, missing_users.length)
    // if (missing_users.length > 0) {
    //     // do a lookup 
    //     try {
    //         const { data: user_and_ids, error } = await supabase
    //             .from('userprofiles')
    //             .select('*')
    //             .in('username', missing_users)

    //         if (error) {
    //             console.log("error getting:", error)
    //         }
    //         if (user_and_ids) {
    //             _.forEach(user_and_ids, (uandid) => {
    //                 _username_to_id.username[uandid.username] = { user_id: uandid.id }
    //                 _userid_to_profile[uandid.id] = uandid
    //             })
    //         }
    //     } catch (err) {
    //         console.log(`Error getting userprofiles`, err)
    //         return
    //     }
    // }
    // return _.pick(_username_to_id, all_usernames)
}

const getIdfromUsername = (username) => {
    if (!_username_to_id.username[username]) {
        _username_to_id.username[username] = proxy({ user_id: null })
    }
    return _username_to_id.username[username]
}
const useIdFromUsername = (username) => {
    const { user_id } = useSnapshot(getIdfromUsername(username))
    return user_id
}

const getUserProfile = async (user_id, no_cache = false) => {
    return getIdToProfiles([user_id], no_cache).then((id_to_usernames) => {
        return id_to_usernames[user_id]
    })
}
const getUserPrivate = async (user_id) => {
    return await supabase
        .from('privateprofiles')
        .select('*')
        .eq('id', user_id)
}

const getUserModels = async (user_id, min_range = 0, max_range = 3) => {
    return await supabase
        .from('queuejobs')
        .select('queue_id')
        .eq('id', user_id)
        .eq('queue_job_type', 'train_inference')
        .order('created_at', { ascending: false })
        .range(min_range, max_range - 1)
}

const getUserJobs = async (user_id, min_range = 0, max_range = 100) => {
    return await supabase
        .from('queuejobs')
        .select('*')
        .eq('id', user_id)
        .order('created_at', { ascending: false })
        .range(min_range, max_range - 1)
}
const _global_user_posts = { global: null, login: null, feed: null }
const _global_data_store = { store: null }


const getUserFeedStore = (user_id) => {
    if (_global_user_posts.feed == null) {
        _global_user_posts.feed = proxy({ posts: {} })
    }
    if (!_global_user_posts.feed.posts[user_id]) {
        _global_user_posts.feed.posts[user_id] = { images: [] }
    }
    return _global_user_posts.feed.posts[user_id]
}

const getKeyedStore = (key_id, default_vals) => {
    if (_global_data_store.store == null) {
        _global_data_store.store = proxy({ data: {} })
    }
    if (!_global_data_store.store.data[key_id]) {
        _global_data_store.store.data[key_id] = { ...default_vals }
    }
    return _global_data_store.store.data[key_id]
}

// const _global_user_posts = proxy({})

const getUserImages = async (user_id, min_range = 0, max_range = 100) => {
    if (_global_user_posts.global == null) {
        _global_user_posts.global = proxy({ userimages: {} })
    }
    // range is inclusive so we need to minus 1 from max_range
    const images = await supabase
        .from('userimages')
        .select('*')
        .eq('id', user_id)
        .order('created_at', { ascending: false })
        .range(min_range, max_range - 1)

    // console.log("UimageS:", images)
    let imdata = images
    // for all the image user_ids get the usernames
    if (images.data.length > 0) {
        // for all the image user_ids get the usernames
        const user_ids = _.map(images.data, 'id')
        const userprofiles = await getIdToProfiles(user_ids)
        imdata = {
            ...images,
            data: _.map(images.data, (image) => {
                return { ...image, ...userprofiles[image.id] }
            }),
        }
    }
    _global_user_posts.global.userimages[user_id] = imdata
    return imdata
}

const getUserFeed = async ({ user_id, min_range = 0, max_range = 100, like_id }) => {
    // range is inclusive so we need to minus 1 from max_range
    let supa_query = supabase
        .from('userfeed')
        .select('*')//, imagelikes(*)')
        .order('shared_at', { ascending: false })
        .range(min_range, max_range - 1)

    if (user_id) {
        supa_query = supa_query
            .eq('id', user_id)
    }
    // want to know if someone specific likes these images? use like_id
    if (like_id) {
        // supa_query = supa_query
        // .eq('imagelikes.id', like_id)
    }
    const images = await supa_query
    if (like_id) {
        console.log("Image and likes:", images, "matching like id", like_id, "user id", user_id)
    }
    // console.log("Image and Likes:", images)
    if (images.data.length > 0) {
        // for all the image user_ids get the usernames
        const user_ids = _.map(images.data, 'id')
        const userprofiles = await getIdToProfiles(user_ids)
        return {
            ...images,
            data: _.map(images.data, (image) => {
                return { ...image, ...userprofiles[image.id] }
            }),
        }
    }
    return images
}


const signinWithUser = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
    });
    if (error) {
        throw error;
    }

    //   want to check data.status and make sure it exists
    return data
}

const signupAsUser = async (username, email, password) => {
    if (!username || username < 3) { throw Error("Username must be at least 3 characters."); }
    if (!email) { throw Error("Email is required."); }
    if (!password) { throw Error("Password is required."); }

    // should check if username exists here (IF EXISTS, RETURN ERROR)
    const { data, error } = await supabase.auth.signUp({
        email,
        password,
        data: { username }
    });
    if (error) {
        throw error;
    }
    return data;
}

const signout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
        console.log(`Signout error: ${error}`);
    }
    return;
}

export {
    getKeyedStore,
    getIdToProfiles,
    getUsernameToProfile,
    useSingleGetAuthSession,
    useRealtimeImages,
    useRealtimeMessages,
    getAdminUIStateStore,
    useAdminAuthSession,
    useAdminUIState,
    getIsObserveCheck,
    getIdfromUsername,
    useIdFromUsername,
    getUserFeedStore,
    getUserPrivate,
    getUserProfile,
    getUserModels,
    getBaseUrl,
    updateAuthSession,
    useAuthSession,
    getSessionProxy,
    useSBAuthSession,
    getUserJobs,
    getUserFeed,
    getUserImages,
    signinWithUser,
    signout,
    signupAsUser
}