import React, { useState } from 'react';
import TitleBar from "../components/titleBar"
import BottomNav from "../components/bottomNav";
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTheme } from "@mui/material/styles";
import { SquishButton } from "../components/styled/motion"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfiniteImageList from "../components/styled/infiniteImageList"
import { getKeyedStore, useAuthSession, getBaseUrl, getIdToProfiles } from '../components/supastore';
import { FullContainer, InnerContainer } from "../components/styled/containers"
import axios from "axios"
import { useSnapshot } from "valtio"
import {
    Switch,
    Route,
    Link,
    useParams,
    useSearchParams,
    useLocation
    // useRouteMatch
} from "react-router-dom";
import _ from "lodash"
import moment from "moment"

const experiments = getKeyedStore("experiments", {
    jobs: [],
    index: {},
    images: {},
    image_counts: {}
})

const admin_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user/admin_actions`,
})

const processImages = (images) => {
    return _.map(images, (d) => {
        const { id: user_id, session_id, image_id, created_at, ...img_props } = d
        const img_format = 'image.jpg'
        // return `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/image`
        // return axios.get(`${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/image`)
        return {
            src: `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/${img_format}`,
            meta_src: `${getBaseUrl()}/api/inference/${user_id}/${session_id}/${image_id}/meta`,
            user_id,
            session_id,
            image_id,
            created_at: moment(created_at).unix(),
            ...img_props,
        }
    })
}

const processAddMeta = async (images) => {
    const profiles = await getIdToProfiles(_.map(images, (c) => c.user_id))
    const promises = _.map(images, async (img) => {
        const { meta_src } = img
        const meta = await axios.get(meta_src)

        return {
            ...img,
            profile: profiles[img.user_id],
            meta: meta.data,
            seed: meta.data.seed,
            prompt: meta.data.prompt,
        }
    })
    return await Promise.all(promises)
}

const sortingProcess = {
    user: (images) => {
        // group by user please in order
        const grouped_imgs = _.groupBy(images, "user_id")
        const sorted_imgs = _.map(grouped_imgs, (d) => {
            return _.sortBy(d, "created_at")
        })
        const sorted_imgs_flat = _.flatten(sorted_imgs)
        return sorted_imgs_flat
    },
    most_recent: (images) => {
        return _.sortBy(images, ["created_at"], ["desc"])
    },
    // sort by seed
    seed: (images) => {
        // group by seed
        const grouped_imgs = _.groupBy(images, "seed")

        // sort each seed by the users in order
        const sorted_imgs = _.map(grouped_imgs, (d) => {
            return _.sortBy(d, "user_id")
        })
        const sorted_imgs_flat = _.flatten(sorted_imgs)
        return sorted_imgs_flat
    },
    prompt: (images) => {
        // group by seed
        const grouped_imgs = _.groupBy(images, "prompt")

        // sort each seed by the users in order
        const sorted_imgs = _.map(grouped_imgs, (d) => {
            return _.sortBy(d, ["user_id", "seed"])
        })
        const sorted_imgs_flat = _.flatten(sorted_imgs)
        return sorted_imgs_flat
    },
    seed_prompt: (images) => {
        // group by seed + prompt and then sort
        const grouped_imgs = _.groupBy(images, (d) => {
            return `${d.seed}_${d.prompt}`
        })

        // sort each seed by the users in order
        const sorted_imgs = _.map(grouped_imgs, (d) => {
            return _.sortBy(d, ["user_id", "seed"])
        })
        const sorted_imgs_flat = _.flatten(sorted_imgs)
        return sorted_imgs_flat
    },
}

const ExperimentEntry = ({ job }) => {
    const { auth } = useAuthSession()
    const { access_token } = auth || {}
    const { pathname } = useLocation();

    const { image_counts } = useSnapshot(experiments)
    const count = image_counts[job.queue_id]
    const theme = useTheme()

    // console.log("Job:", job)
    return <TableRow>
        <TableCell
            sx={{
                // display cursor 
                cursor: "pointer",
            }}

        ><Link to={`/experiments/${job.queue_id}`}
            style={{
                color: theme.palette.secondary.main,
            }}
        >
                {job.queue_id}
            </Link></TableCell>
        {/* format create_at with am/pm */}
        <TableCell>{moment(job.created_at).format(
            "MMMM Do YY, h:mm a"
        )}</TableCell>
        <TableCell>{job.queue_job_type}</TableCell>
        <TableCell>{job.queue_progress}</TableCell>
        <TableCell
            sx={{
                cursor: "pointer",
            }}
            onClick={() => {
                getJobImages({ access_token, queue_id: job.queue_id })
            }}
        >{count == null ? "Click to find out" : count}</TableCell>
        <TableCell>{job.queue_status}</TableCell>
        <TableCell>{job.username}</TableCell>
    </TableRow>
}
const ExperimentHeader = ({ }) => {
    return <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    }}>
        <Typography variant="h2">Experiments</Typography>
    </Box>
}

const ExperimentList = ({ jobs, counts }) => {
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    }}>
        <TableContainer sx={{ m: 2, width: "100%" }} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>QueueID</TableCell>
                        <TableCell>Started</TableCell>
                        <TableCell >Job Type</TableCell>
                        <TableCell >Job Progress</TableCell>
                        <TableCell >Job Status</TableCell>
                        <TableCell >Image Count</TableCell>
                        <TableCell >Owner (username)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(jobs, (job, idx) => <ExperimentEntry key={idx} job={job} count={counts[job.queue_id]} />)}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
}


const getJobImages = async ({ access_token, queue_id, process, min_range = 0, max_range = 25 }) => {
    return admin_api.post("/", {
        access_token, action: 'list_job_images',
        action_args: { queue_ids: queue_id, min_range, max_range }
    }).then(({ data }) => {
        // console.log("List Job Images:", data)
        experiments.image_counts[queue_id] = data.count
        const img_no_meta = process ? process(data.images) : processImages(data.images)


        return processAddMeta(img_no_meta)
    }).then(async (nimages) => {
        console.log("Nimages:", nimages)
        let hasMore = true
        if (nimages.length < max_range - min_range) {
            hasMore = false
        }
        const imgStore = experiments.images[queue_id]
        const cimages = imgStore.images
        const append_imgs = [...cimages, ...nimages]

        const sorted_imgs_flat = sortingProcess[imgStore.sorting](append_imgs)
        // const sort_cimages = _.sortBy(cimages,
        // ["user_id", "created_at"], ["desc", "desc"])
        console.log("Sorted:", sorted_imgs_flat)
        experiments.images[queue_id].images = sorted_imgs_flat
        experiments.images[queue_id].hasMore = hasMore

        // experiments.images[queue_id] = {
        //     // ...imgStore,
        //     sorting: imgStore.sorting,
        //     isActive: true,
        //     images: [...sorted_imgs_flat],
        //     hasMore,
        // }

    }).catch((err) => {
        console.log("Error:", err)
    })
}

// this is a paper bar that just has sorting and buttons and 
// stuff like that
const ActionBar = ({ }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const sortParam = searchParams.get("sort")
    const { queue_id } = useParams()
    const { sorting } = useSnapshot(experiments.images[queue_id])

    if (sorting != sortParam) {
        setSearchParams({ sort: sorting })
    }

    const handleChange = (e) => {
        const { value } = e.target
        if (value != sorting) {
            const images = experiments.images[queue_id].images
            experiments.images[queue_id].sorting = value
            experiments.images[queue_id].images = [...sortingProcess[value](images)]
        }
    }

    React.useEffect(() => {
        if (sortParam && sortParam in sortingProcess) {
            experiments.images[queue_id].sorting = sortParam
        }
    }, [sortParam])

    return <Box sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    }}>
        {/* here we elevant via Paper */}
        <Paper sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            p: 2,
        }}>
            <Typography variant="h4">Experiments</Typography>
            {/* menu for how to sort */}
            {/* we have: user, seed, most_recent, prompt */}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
            }}>

                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    value={sorting}
                    label="Sort Results By"
                    onChange={handleChange}
                >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="seed">Seed</MenuItem>
                    <MenuItem value="seed_prompt">Seed & Prompt</MenuItem>
                    <MenuItem value="most_recent">Most Recent</MenuItem>
                    <MenuItem value="prompt">Prompt</MenuItem>
                </Select>
            </Box>

            {/* <Button variant="contained" color="primary">Create Experiment</Button> */}
        </Paper>

    </Box>
}

const QueueJobImages = ({ }) => {
    const { queue_id } = useParams()
    const { user_id, auth } = useAuthSession()
    const { access_token } = auth || {}
    const theme = useTheme()

    if (experiments.images[queue_id] == null) {
        experiments.images[queue_id] = { sorting: "user", images: [], hasMore: true, isActive: true }
    }

    const { images, hasMore, isActive } = useSnapshot(experiments.images[queue_id])
    console.log("Queue Job Images:", images, hasMore, isActive)
    // React.useEffect(() => {
    //     getJobImages({ access_token, queue_id })
    // }, [])


    const fetchImages = async ({ min_range, max_range }) => {
        await getJobImages({ access_token, queue_id, min_range, max_range })
        // experiments.image_counts[queue_id] = experiments.images[queue_id].images.length
    }

    const fetchMore = async () => {
        const images = experiments.images[queue_id].images
        const min_range = images.length
        const max_range = min_range + 75
        await fetchImages({ min_range, max_range })
    }


    const actionClick = ({ action_name, img_data, ...props }) => {
        console.log("Click!", action_name)
        if (action_name == "copy") {
            // let's copy!
            // onCopyClick({ prompt: img_data.data.prompt, setIsFetching, enqueueSnackbar })
        }
    }
    const getActions = (action, img_data) => {
        return [
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>s:{img_data.seed}{"  @"}{img_data.profile ? img_data.profile.username : ""}</Typography>,
            <SquishButton key="copy" size="small" color="white" startIcon={<ContentCopyIcon />}
                onClick={() => action({ action_name: "copy" })} >
                Get Metadata
            </SquishButton>
        ]
    }

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

        }}>
            <ActionBar />
            <Box sx={{
                pt: 5,
                borderTop: 1,
                borderColor: theme.palette.background.divider,
                backgroundColor: theme.palette.background.home,
            }}>
                <InfiniteImageList
                    sx={{
                    }}
                    images={images}
                    imageData={experiments.images[queue_id].images}
                    actionClick={actionClick}
                    getActions={getActions}
                    showCopy={false}
                    fetchMore={fetchMore}
                    hasMore={hasMore}
                    showActions={true}
                    showUsers={false}
                    isActive={isActive}
                />
            </Box>
        </Box>

    );
}

const MainEntry = ({ }) => {
    const { pathname } = useLocation();
    const { user_id, auth } = useAuthSession()
    const { access_token } = auth || {}
    const { queue_id } = useParams()
    // const experiments = getKeyedStore("experiments", {
    //     jobs: [],
    //     image_counts: {}
    // })
    const { jobs: snap_jobs, image_counts: snap_counts } = useSnapshot(experiments)

    React.useEffect(() => {
        if (auth && queue_id == null) {
            // list_admin_jobs
            // list_job_images
            admin_api.post("/", {
                access_token, action: 'list_admin_jobs', action_args: {
                    queue_job_type: "admin_inf",
                    max_range: 25
                }
            }).then(async ({ data }) => {
                const { queue_jobs } = data || {}
                console.log("Admin Jobs:", data)
                // experiments.jobs = queue_jobs
                const profiles = await getIdToProfiles(_.map(queue_jobs, (c) => c.id))
                experiments.jobs = _.sortBy(_.map(queue_jobs, (c) => {
                    return {
                        ...c,
                        create_ts: moment(c.created_at).unix(),
                        ...profiles[c.id]
                    }
                }), 'create_ts').reverse()

                console.log("Jobs:", experiments.jobs)
            })
        }

    }, [auth])

    console.log("Cur path:", pathname)
    return <FullContainer>
        <TitleBar />
        <InnerContainer>
            {queue_id ? <QueueJobImages /> : <>
                <ExperimentHeader />
                <ExperimentList jobs={snap_jobs} counts={snap_counts} />
            </>}
            {/* <Switch>
                <Route exact path="/experiments">
                    <>
                        <ExperimentList jobs={snap_jobs} />
                    </>
                </Route>
                <Route path="/experiments/:queue_id">
                    <QueueJobImages />
                </Route>
                <Route path="*">
                    <Typography variant="h2">404</Typography>
                </Route>
            </Switch> */}

        </InnerContainer>
        <BottomNav selectedIndex={1} />
    </FullContainer>
}

export default MainEntry