import React from 'react'
import { useAuthSession, getAdminUIStateStore } from "../supastore";
import Box from "@mui/material/Box";
import _ from "lodash"
import { useSnapshot } from "valtio"
import { useSnackbar } from 'notistack'
import InfiniteUserList from "./infiniteUserList"
import InfiniteWaitlist from './infiniteWaitlist'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import BetaInvitesComponent from "./actions/beta"
import CheddarActionsComponent from "./actions/cheddar"
import { admin_api, userStore, fetchMoreUsers } from "./actions/user"
import { waitlistStore, fetchMoreWaitlist } from "./actions/waitlist"

const ListUsersComponent = ({ isActive }) => {
    // get list of users and display them in infinite scroll
    // for each user display some info
    const { users: snap_users, hasMore: snap_has_more } = useSnapshot(userStore)
    // const snap_has_more = useSnapshot(userStore.hasMore)

    const { enqueueSnackbar } = useSnackbar()
    const { auth } = useAuthSession();
    const { access_token } = auth || {}

    // React.useEffect(() => {
    //     admin_api.post('/', { access_token, action: 'list_users', action_args: {min_range: 0, max_range: 10}}).then(({ data }) => {
    //         console.log("List Users:", data)
    //         enqueueSnackbar("List Users Success", { variant: 'success' })
    //     }).catch((err) => {
    //         console.log("Error getting users:", err)
    //         enqueueSnackbar("Failed List Users", { variant: 'error' })
    //     })

    // }, [])
    const onAction = () => {
    }

    const fetchMore = () => {
        return fetchMoreUsers({ snap_users, userStore, access_token, enqueueSnackbar })
    }

    return (
        <InfiniteUserList
            users={snap_users}
            userData={userStore.users}
            actionClick={onAction}
            fetchMore={fetchMore}
            hasMore={snap_has_more}
            showActions={true}
            showUsers={false}
            isActive={isActive}
        />
    );
}

const WaitlistComponent = ({ isActive }) => {
    // get list of users and display them in infinite scroll
    // for each user display some info
    const { users: snap_users, hasMore: snap_has_more } = useSnapshot(waitlistStore)

    const { enqueueSnackbar } = useSnackbar()
    const { auth } = useAuthSession();
    const { access_token } = auth || {}

    const onAction = () => {
    }

    const fetchMore = () => {
        return fetchMoreWaitlist({ snap_users, waitlistStore, access_token, enqueueSnackbar })
    }

    return (
        <InfiniteWaitlist
            users={snap_users}
            userData={waitlistStore.users}
            actionClick={onAction}
            fetchMore={fetchMore}
            hasMore={snap_has_more}
            showActions={true}
            showUsers={false}
            isActive={isActive}
        />
    );
}

const UserModelQualityComponent = ({ isActive }) => {
    // get list of users and display them in infinite scroll
    // for each user display some info
    const { users: snap_users, hasMore: snap_has_more } = useSnapshot(userStore)
    // const snap_has_more = useSnapshot(userStore.hasMore)
    const { enqueueSnackbar } = useSnackbar()
    const { auth } = useAuthSession();
    const { access_token } = auth || {}

    React.useEffect(() => {
        // get initial batch 
        // i should use autocomplete to fetch these in reality
        fetchMoreUsers({ snap_users, userStore, access_token, enqueueSnackbar })
    }, [])

    const userSelect = (e, val) => {
        console.log("Selected user:", val)
    }
    const real_users = _.filter(snap_users, (u) => u.username != null)
    console.log("Users: ", real_users)
    return <Box>
        <Autocomplete

            isOptionEqualToValue={(option, value) => option.username === value.username}
            getOptionLabel={(option) => option.username}
            onChange={userSelect}
            options={_.sortBy(real_users, (u) => u.username.toLowerCase())}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Username Selection" />}
        />
    </Box>
}

const UserExperienceComponent = ({ isActive }) => {
    // get list of users and display them in infinite scroll
    // for each user display some info
    return <div>User XP Component</div>
}


const admin_pages = [{
    group_name: "Cheddar",
    pages: [
        {
            name: "cheddar_actions",
            label: "Cheddar Actions",
            component: CheddarActionsComponent
        }
    ]
},
{

    group_name: "Users",
    pages: [
        {
            name: "users",
            label: "List Users",
            component: ListUsersComponent
        },
        // {
        //     name: "user_model",
        //     label: "User Models",
        //     component: UserModelQualityComponent
        // },
        // {
        //     name: "user_exps",
        //     label: "User Experiences",
        //     component: UserExperienceComponent
        // }
    ],
},
{
    group_name: "Beta",
    pages: [
        {
            name: "beta_invites",
            label: "Beta Invites",
            component: BetaInvitesComponent
        },
        {
            name: "waitlist",
            label: "Waitlist",
            component: WaitlistComponent
        }
    ]
}
]
const AdminFeed = ({ }) => {
    // const { isAuthenticated, auth, user_id } = useAuthSession();
    // const { is_admin, is_super_admin, privileges } = useAdminAuthSession()
    const ui_state = getAdminUIStateStore()
    const { adminPage } = useSnapshot(ui_state)
    const selections = _.flatten(admin_pages.map((g) => g.pages))
    const selected = _.filter(selections, (p) => p.name === adminPage)
    const AdminContent = selected.length > 0 ? selected[0].component : null
    // console.log("Admin page:", adminPage, selected, AdminContent, selections)
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',

    }}>
        {AdminContent && <AdminContent isActive={true} />}
    </Box>
}

export default AdminFeed
export {
    admin_pages
}