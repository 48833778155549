import { createClient } from '@supabase/supabase-js'

console.log("Process env keys:", Object.keys(process.env))
const supabaseUrl = process.env.REACT_APP_SB_URL
const supabaseAnonKey = process.env.REACT_APP_SB_AKEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
    schema: 'public',
    // headers: { 'x-my-custom-header': 'my-app-name' },
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
})

export const SB_REDIRECT_URL = process.env.REACT_APP_SB_REDIRECT