import { getBaseUrl, useAuthSession, getKeyedStore, supabase, getIdToProfiles } from "../../supastore";
import axios from 'axios';

const admin_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user/admin_actions`,
})
const waitlistStore = getKeyedStore('admin_waitlist', { users: [], hasMore: true, userCount: 0, })

const fetchMoreWaitlist = async ({ snap_users, waitlistStore, access_token, enqueueSnackbar }) => {
    // await new Promise(resolve => setTimeout(resolve, 500));
    // fetch jobs 
    if (!waitlistStore.hasMore) {
        return
    }
    const fetchSize = 1000
    const min_range = snap_users.length
    const max_range = min_range + fetchSize
    const { data, error, count } = await admin_api.post('/', { access_token, action: 'list_waitlist', action_args: { min_range, max_range } })
    if (error) {
        console.log("fetch waitlist error", error)
        enqueueSnackbar("Failed Waitlist", { variant: 'error' })
        return
    }
    console.log("Users:", data, " user count: ", count)
    if (data.length == 0 || data.length % fetchSize !== 0) {
        // ignore for now
        // setHasMore(false)
        waitlistStore.hasMore = false
    }
    // console.log("Queried User Images: ", data)
    const { invites } = data
    console.log("Queried Waitlist Users: ", data)
    // get all img data
    waitlistStore.users = [...waitlistStore.users, ...invites]
};



export {
    admin_api,
    waitlistStore,
    fetchMoreWaitlist
}