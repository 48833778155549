import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Waitlist from "../components/login/waitlist";
import { CenterBox, FullContainer } from "../components/styled/containers"
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { getBaseUrl } from "../components/supastore"
import { Container } from '@mui/material';

const beta_api = axios.create({
    baseURL: `${getBaseUrl()}/api/user`,
    headers: {
        'Content-Type': 'application/json',
    },
});

const MainEntry = ({ }) => {
    const theme = useTheme();
    const { token } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isValidating, setIsValidating] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code");
    console.log("Code and params: ", code, searchParams);

    useEffect(() => {
        if (code) {
            const validateInviteUrl = async () => {
                // checks the code existsins
                return await beta_api.get(`/beta?code=${code}`)
            }
            setIsValidating(true);
            validateInviteUrl().then((v) => {
                if (v) {
                    // if validated, route to /dream/create
                    navigate({
                        pathname: '/dream/create',
                        search: `?code=${code}`
                    });
                }
                setIsValidating(false);
            })
                .catch((err) => {
                    setIsValidating(false);
                    enqueueSnackbar("Invalid Signup URL: " + err.response.data.error, { variant: "error" });
                });
        }
    }, []);

    return <Container sx={{ background: theme.palette.background.accent, alignContent: "center", display: "flex", flexDirection: "column", minHeight: "100vh", minWidth: "100vw" }}>
        {isValidating ? <CircularProgress color="white" /> : <Waitlist />}
    </Container>
}

export default MainEntry