import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { MainImageCard } from "./cards"
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CardActions from '@mui/material/CardActions';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import PublicOffOutlinedIcon from '@mui/icons-material/PublicOffOutlined';
import { useAuthSession, getIsObserveCheck } from '../supastore';
import { useSnapshot } from 'valtio';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import { copyCleanPromptForUI } from '../../helpers/inference';
import { SquishButton, SquishAvatar, MotionHeartIcon, SquishIconButton } from "./motion"
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReportDrawer from './reportDrawer';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';

// import Blur from 'react-blur'
// import LazyImage from 'react-lazy-blur-image';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const PublicPrivateSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8796A5',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#7f56d9',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M 11 8.17 L 6.49 3.66 C 8.07 2.61 9.96 2 12 2 c 5.52 0 10 4.48 10 10 c 0 2.04 -0.61 3.93 -1.66 5.51 l -1.46 -1.46 C 19.59 14.87 20 13.48 20 12 c 0 -3.35 -2.07 -6.22 -5 -7.41 V 5 c 0 1.1 -0.9 2 -2 2 h -2 V 8.17 Z M 21.19 21.19 l -1.41 1.41 l -2.27 -2.27 C 15.93 21.39 14.04 22 12 22 C 6.48 22 2 17.52 2 12 c 0 -2.04 0.61 -3.93 1.66 -5.51 L 1.39 4.22 l 1.41 -1.41 L 21.19 21.19 Z M 11 18 c -1.1 0 -2 -0.9 -2 -2 v -1 l -4.79 -4.79 C 4.08 10.79 4 11.38 4 12 c 0 4.08 3.05 7.44 7 7.93 V 18 Z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#8796A5',
    borderRadius: 20 / 2,
  },
}));

const BlurImage = ({ ...props }) => {
  const { style, ...all_props } = props
  return <img {...all_props} style={{ filter: "blur(33px)", ...style }} onLoad={() => {
    console.log("Blur image loaded")
  }} />
  // console.log("Blur props: ", props)
  // // return <img {...props} onLoad={() => {
  // //   console.log("Image load")
  // // }} />
  // const src = props.style.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
  // // const { backgroundImage, ...style } = props.style
  // console.log("Blur src: ", src)
  // return <LazyImage initialBlur={13} blur={13} uri={src} render={(src, style) => <img src={src} style={style} />} />
  // return <Blur blurRadius={12} img={src} style={style} onLoad={props.onLoad} />
}

const ImageCard = ({ imageData,
  actionClick, onImageClick,
  getActions,
  isSelected = false,
  showNSFWBlur = false,
  showCopy = true,
  showUser = true,
  showActions = true }) => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user_id, isObserving } = useAuthSession();
  const [prompt, setPrompt] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [reportEl, setReportEl] = useState(null);

  // TODO: 
  // set default according to user preference
  const [nsfwIgnore, setNsfwIgnore] = useState(true);
  const [showReportDrawer, setShowReportDrawer] = useState(false);

  const closeReportMenu = () => {
    setReportEl(null);
  }

  const handleReportDrawerClose = () => {
    setShowReportDrawer(false);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const { image_privacy, liked } = useSnapshot(imageData.data)

  const is_private = image_privacy == "private"

  useEffect(() => {

    const m = JSON.parse(imageData.data.metadata);
    const p = copyCleanPromptForUI(m)
    setPrompt(p)

  }, [imageData])

  const takeAction = isObserving ? (...props) => {
    getIsObserveCheck({ user_id }).then((isObserving) => {
      if (isObserving)
        enqueueSnackbar("Please wait while we finish teaching your cheese! 🧀⏰🏃‍♂️", { variant: "info" })
      else
        actionClick(...props)
    })
  } : actionClick;

  const defaultGetActions = () => {
    let actions = null
    if (getActions) {
      actions = getActions(takeAction, imageData.data)
    }
    else {
      if (imageData.creator.user_id === user_id) {
        actions = [
          <SquishIconButton
            key="public"
            size="small"
            color="white"
            onClick={() => takeAction({ action_name: "publish", action_label: `Making ${is_private ? "public" : "private"}`, is_private })}>
            {is_private ? <PublicOffOutlinedIcon /> : <PublicOutlinedIcon />}
          </SquishIconButton>
        ]
        // actions = [<PublicPrivateSwitch key="public_private_switch" checked={!is_private} onChange={(val) => {
        //   console.log("Change activated:", val.target.checked)
        //   if (val.target.checked === is_private) {
        //     takeAction({ action_name: "publish", action_label: `Making ${is_private ? "public" : "private"}`, is_private })
        //   }

        // }} />,
        // ]
      }
      else {
        actions = [
          <SquishButton withExplosion={true} sx={{ marginRight: "10px" }} key="remix" size="small" color="white" startIcon={<AutoFixHighOutlinedIcon />}
            onClick={() => takeAction({ action_name: "remix", action_label: "Do Me" })} >
            Do Me
          </SquishButton>,
          <MotionHeartIcon liked={liked}
            key="like" size="small" color="white"
            onClick={() => {
              console.log("Like clicked")
              takeAction({ action_name: "like", action_label: "Like" })
            }}>
            <FavoriteBorderIcon />
          </MotionHeartIcon >,
        ]
      }
    }
    return showActions ?
      <CardActions disableSpacing={true}>
        {actions.map((a) => a)}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      : <></>
  }

  return (
    <MainImageCard sx={{ border: isSelected ? 5 : 0, borderColor: "primary.main", width: "100%", overflow: showActions ? "visible" : "hidden" }}>
      {showUser ? <CardHeader
        sx={{ cursor: "pointer", p: "5px", alignItems: "center" }}
        title={
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => { navigate(`/@${imageData.creator.username}`) }} style={{ cursor: "pointer" }}>
            <SquishAvatar
              sx={{ marginRight: "10px" }}
              aria-label="user" src={imageData.creator.avatar_url ?? ""}>
              {imageData.creator.username[0]}
            </SquishAvatar>
            <Typography variant="body" align="left" fontWeight={600}>{imageData.creator.username ?? ""}</Typography>
          </Box>
        }
        titleTypographyProps={{ variant: 'body', align: 'left', fontWeight: 500 }}
        action={
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", p: "5px", mr: "5px" }}>
            <IconButton aria-label="showMore" onClick={(e) => { setReportEl(e.currentTarget) }}>
              <MoreHorizOutlined />
            </IconButton>
          </Box>
        }
      /> : <></>}
      <Menu
        id="menu-post"
        anchorEl={reportEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(reportEl)}
        onClose={() => { closeReportMenu() }}
        sx={{ display: 'block' }}
      >
        <MenuItem
          onClick={() => {
            setShowReportDrawer(true);
            closeReportMenu();
          }}>
          <ListItemIcon>
            <FlagOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Report</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          href={`mailto:support@cheese.baby?subject=Feedback on my Cheese image&body=Image ID: ${imageData.data.image_id}`}
          target="_blank"
          onClick={() => {
            closeReportMenu();
          }}>
          <ListItemIcon>
            <CampaignOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Feedback</ListItemText>
        </MenuItem>
      </Menu>
      <Box sx={{
        display: "flex", flexDirection: "column",
        // necessary for blur beyond boundary to get clipped
        overflow: "hidden"
      }}>
        {!imageLoaded && <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", width: "100%", cursor: onImageClick ? "pointer" : "default" }} >
            <Skeleton style={{ "--aspect-ratio": 1 }}
              animation="wave"
              variant="rectangular"
              width="100%" height={"auto"} />
          </Box>
          <Box sx={{
            position: "absolute",
            transform: "translate(50%, 50%)",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            ml: "-20px",
            mt: "-20px",
          }}
            style={{ "--aspect-ratio": 1 }} >
            <CircularProgress />
          </Box>

        </Box>
        }
        <CardMedia
          style={{
            height: !imageLoaded ? 0 : null,
            // paddingTop for 1:1 aspect ratio
            paddingTop: !imageLoaded ? '100%' : null,
            // paddingTop: '56.25%', 
            cursor: onImageClick ? "pointer" : "default",
            minHeight: 10,
            maxHeight: "80vh",
            objectFit: "cover",
            filter: imageData.data.nsfw_flag && nsfwIgnore && showNSFWBlur ? "blur(32px)" : null,
            // "--aspect-ratio": 1
          }}
          onTouchTap={() => {
            // console.log("Touch tap")
            setNsfwIgnore(!nsfwIgnore)
          }}
          //   onTouchStart={() => {
          //   setNsfwIgnore(!nsfwIgnore)
          // }}
          // onTouchEnd={() => {
          //   // setNsfwIgnore(!nsfwIgnore)
          // }}
          // onTouchCancel={() => {
          //   // setNsfwIgnore(true)
          // }}
          onMouseDown={() => {
            // setNsfwIgnore(false)
          }}
          onMouseUp={() => {
            setNsfwIgnore(!nsfwIgnore)
          }}
          onMouseLeave={() => {
            // setNsfwIgnore(false)
          }}

          onClick={onImageClick ? () => { onImageClick(imageData) } : null}
          // component={imageData.data.nsfw_flag ? <Blur blurRadius={12} img={imageData.url} /> : "img"}
          // component={imageData.data.nsfw_flag ? <LazyImage uri={imageData.url} render={(src, style) => <img src={src} style={style} />} /> : "img"}
          // component={imageData.data.nsfw_flag ? BlurImage : "img"}
          component={"img"}
          onLoad={(e) => {
            // console.log("loaded image: ", e.target)
            setImageLoaded(true)
            // e.target.style.opacity = 1
          }}
          image={imageData.url}
        />
      </Box>
      {defaultGetActions()}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography paragraph>Prompt:</Typography>
            </Grid>
            <Grid item xs={4}>
              {showCopy && <SquishButton key="copy-prompt" size="small" color="white" startIcon={<ContentCopyOutlinedIcon />}
                onClick={() => takeAction({ action_name: "copy-prompt", action_label: "Copy Prompt" })} >
                Copy
              </SquishButton>}
            </Grid>
          </Grid>
          <Typography paragraph variant="body2">
            {prompt}
          </Typography>
        </CardContent>
      </Collapse>
      <ReportDrawer post={imageData} open={showReportDrawer} onClose={handleReportDrawerClose} anchor="bottom" />
    </MainImageCard>
  );
}

export default ImageCard;