import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { SquishIconButton, SquishImg } from '../styled/motion';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { Link, Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { signout, useAuthSession, useAdminUIState, useAdminAuthSession, getAdminUIStateStore, useRealtimeMessages } from '../supastore';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnapshot } from "valtio";
import { cheddarSvgOutlineString } from "./svgIcon"
// import SearchBar from '../lexicaSearch/views/searchBar';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CircularProgress from '@mui/material/CircularProgress';
import PurchaseDrawer from './purchaseDrawer';
import HideOnScroll from '../styled/hideOnScroll';

const LinkOrTop = ({ children, to }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const is_to = location.pathname == to
  const handleClick = () => {
    if (location.pathname == to) {
      // scroll to top, with animation 
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate({ pathname: to });
    }
  }

  return (is_to ? <div onClick={handleClick}>{children}</div> : <Link to={to}>{children}</Link>)
}

function shortenLargeNumber(num, digits) {
  var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
      decimal;

  for(var i=units.length-1; i>=0; i--) {
      decimal = Math.pow(1000, i+1);

      if(num <= -decimal || num >= decimal) {
          const n = Math.trunc(num/decimal*Math.pow(10, digits))/Math.pow(10, digits);
          return +(n) + units[i];
      }
  }

  return num;
}

const CheddarDisplayOutlined = ({ profile, onClick }) => {
  const { token_count, token_type } = profile || { token_count: 0 }
  console.log("Cheddar display info:", token_count, token_type)
  const theme = useTheme()

  // console.log("Tehem:", theme)
  // if (theme.palette.cheddar == null)
  //   return null

  return <Box 
    onClick={onClick}
    sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    py: .4,
    px: 1,
    border: "1px solid", borderRadius: "8px", borderColor: theme.palette.cheddar.main
  }}>
    {profile == null && <CircularProgress
      sx={{ color: theme.palette.cheddar.main }}
      // color={theme.palette.cheddar}
      size={18} />}
    {profile && <Typography variant="body2" sx={{ color: theme.palette.cheddar.main, fontWeight: 500, fontSize: "0.8rem", padding: "0.2rem" }}>
      {shortenLargeNumber(token_count,1)}
    </Typography>}
    {/* encode svg as data */}
    <img src={`data:image/svg+xml;base64,${btoa(cheddarSvgOutlineString)}`} alt="cheddar" />
  </Box>
}
const CheddarDisplayFilled = () => {

  return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path shape-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" d="M5.21375 13.6453C5.225 15.2157 5.24075 16.947 5.25425 17.6213C5.27225 18.473 5.37575 19.1077 5.85275 19.7447C6.449 20.538 7.556 21.5133 9.53375 22.0943C12.2405 22.8877 14.1935 22.7547 15.1587 22.5283C15.449 22.4607 15.7212 22.3883 15.8855 22.176C15.9822 22.05 16.0272 21.8773 16.0317 21.6393C16.0497 20.8273 16.0295 18.123 16.0137 16.5153C15.9665 16.5247 15.9192 16.5293 15.8697 16.5317C15.4985 16.5573 15.1182 16.5853 14.7267 16.5993C14.4455 16.6087 14.1642 16.611 13.8852 16.611C11.3202 16.6017 8.94875 16.1513 7.205 15.2273C6.40175 14.7957 5.7245 14.2683 5.21375 13.6453Z" fill="#6941C6" />
    <path shape-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" d="M22.7773 10.794C22.7818 10.7427 22.7795 10.6867 22.7705 10.626C22.6445 9.72302 22.1563 8.87835 21.2743 8.04068C19.895 6.73402 17.7778 5.40402 13.4848 5.30135C10.868 5.24068 8.339 6.10168 6.755 7.49935C5.77625 8.36035 5.162 9.42668 5.1935 10.6027C5.23625 12.2547 6.2105 13.4353 7.7 14.2263C9.3155 15.0803 11.516 15.477 13.892 15.4863C14.1575 15.4863 14.4253 15.484 14.6953 15.4747C15.0755 15.4607 15.4445 15.4327 15.8045 15.4093C15.8945 15.4023 15.971 15.3417 16.0003 15.253C16.0025 15.2413 16.0048 15.2297 16.007 15.2203V15.2157C16.016 15.141 15.9868 15.057 15.9328 14.966C15.4153 14.084 13.7255 10.6867 13.7255 10.6867C13.6648 10.5653 13.649 10.423 13.6828 10.29C13.7165 10.157 13.7953 10.0403 13.9055 9.96335C14.0158 9.88635 14.1508 9.85602 14.2813 9.87468C14.2813 9.87468 16.8845 10.255 19.2065 10.5863C19.7375 10.661 20.2528 10.7357 20.7185 10.7987C20.9458 10.8313 21.1618 10.8593 21.362 10.8873C21.8188 10.9527 22.1945 11.0017 22.4375 11.0297C22.55 11.046 22.667 11.0273 22.7323 10.934C22.7503 10.906 22.7638 10.8757 22.7705 10.8383C22.7705 10.8267 22.7728 10.8103 22.7773 10.794Z" fill="#6941C6" />
    <path shape-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" d="M18.6327 17.7193C18.6327 17.7193 20.7702 18.053 21.8187 18.2257C22.1517 18.2793 22.4577 18.298 22.6422 18.109C22.757 17.9947 22.7975 17.8057 22.802 17.5467C22.8155 16.7113 22.7975 14.2847 22.7817 12.6093C22.766 12.3667 22.604 12.18 22.3092 12.1473H22.307C22.0595 12.117 21.6815 12.0657 21.218 12.0027C21.0177 11.9747 20.7995 11.9443 20.5722 11.914C20.1042 11.8463 19.589 11.774 19.058 11.6993C17.6495 11.4987 16.1397 11.2793 15.1655 11.1393C15.6965 12.194 16.52 13.818 16.853 14.385C17.0442 14.7117 17.105 15.0243 17.0825 15.3043C17.0847 15.4817 17.0937 16.4313 17.1027 17.472L18.6327 17.7193Z" fill="#6941C6" />
  </svg>

}

const TitleBar = ({ initialQuery = "", titleHeight }) => {
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate();

  const [showPurchaseDrawer, setShowPurchaseDrawer] = useState(false);

  const { user_id, profile } = useAuthSession();
  const { is_admin } = useAdminAuthSession()
  const admin_ui = getAdminUIStateStore()
  // const { admin_tab } = useSnapshot(admin_ui)
  const location = useLocation();
  const admin_tab = location.pathname.split("/")[1] == "admin"
  // console.log("location", location)
  // console.log("Admin tab:", admin_tab)
  // console.log("admin_ui tab:", admin_ui)
  const admin_opts = (is_admin ? [{ title: 'Admin', action: () => { navigate("/admin") } }] : [])
  const pages = user_id ? [
    { title: 'Help', action: () => { navigate("/support") } },
    { title: 'Settings', action: () => { navigate("/settings") } },
    ...admin_opts,
    { title: 'Contact', action: () => {window.location.href =`mailto:support@cheese.baby`}},
    { title: 'Logout', action: async () => { await signout(); navigate({ pathname: "/", replace: true }); } },
  ] : [
    { title: 'Login', action: () => { navigate("/login") } },
    { title: 'Signup', action: () => { navigate("/signup") } }];

  const { enqueueSnackbar } = useSnackbar()
  const messages = useRealtimeMessages()

  React.useEffect(() => {
    // display the last message to come in!
    if (messages.length > 0) {
      console.log("Messages:", messages)
      enqueueSnackbar(messages[messages.length - 1].message, { variant: "info" })
    }
  }, [messages])



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const list_anim = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }
  const item_anim = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -100 },
  }

  return (
    <HideOnScroll>
    <AppBar sx={{ height: titleHeight }} elevation={1} onClick={()=> window.scrollTo(0,0)}>
      <Toolbar title="cheese" sx={{px:0.75}}>
        {(is_admin && admin_tab) && <>
          <SquishIconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => { admin_ui.drawerOpen = admin_ui.drawerOpen ? false : true }}
          >
            <MenuOpenIcon />
          </SquishIconButton>
          admin
        </>
        }
        {!(is_admin && admin_tab) && <>
          <Link to="/">
            <SquishImg style={{ height: '57px', padding: "0px", marginRight: "3px", paddingTop: "10px", marginTop: 'auto' }} src="/cheese_log_shadow_clip.png" alt="logo" className="logo" />
          </Link>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontSize: '1.25em',
              fontWeight: "bold",
              display: 'flex',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            cheese
          </Typography>
        </>
        }
        <Box flexGrow={1} />
        {user_id && <Box sx={{ display: 'flex' }}>
          <CheddarDisplayOutlined profile={profile} onClick={()=>{setShowPurchaseDrawer(true)}} />
        </Box>}
        <Box sx={{ display: 'flex' }}>
          <SquishIconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </SquishIconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            // component={motion.ul}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: 'block' }}
          >
            {/* <motion.ul variant={list_anim}> */}

            {pages.map((page) => (
              <MenuItem key={page.title}
                // variant={item_anim}
                // component={motion.li}
                onClick={() => {
                  page.action();
                  handleCloseNavMenu();
                }}>
                <Typography textAlign="center">{page.title}</Typography>
              </MenuItem>
            ))}
            {/* </motion.ul> */}
          </Menu>
        </Box>
      </Toolbar>
      <PurchaseDrawer open={showPurchaseDrawer} anchor="bottom" onClose={()=>{setShowPurchaseDrawer(false)}} />
    </AppBar >
    </HideOnScroll>
  )
}

export default TitleBar